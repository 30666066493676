import { EntityId } from 'ant/types/models/blogs';
import { PostTypes } from 'ant/types/models/post';
import { PostAttachmentTypes } from 'ant/types/models/post-attachment';
import { ReactionNewsContentType, ReactionPostContentType } from 'ant/types/models/reactions';

const optional = (id?: EntityId) => (id ? `${id}/` : '');

/** @deprecated: Используй ant/endpoints/**
 * TODO: перенести эти ручки в ant/endpoints/**.ts */
export class Endpoints {
  static participantsAdd = (id: number, roleName: string) =>
    `/blogs/api/v2/blog/${id}/participants/${roleName}/add/`;

  static newsBlogEntries = (id: EntityId) => `/blogs/api/news/${id}/entry/`;

  static newsBlogsList = () => `/blogs/api/news/`;

  static newsBlogs = (id: EntityId) => `/blogs/api/news/${id}/`;

  static newsBlogSubscriptions = (id: EntityId) => `/blogs/api/subscriptions/blog/${id}/`;

  /** @deprecated */
  static timelineEntries = () => `/blogs/api/timeline/`; // TODO Если @myteam не использует эту ручку, то можно удалить. DI переехал на BlogsEndpoints.timelineUserId

  static micropost = (id?: EntityId) => `/blogs/api/micropost/${optional(id)}`;

  static gamificationEntry = (id?: EntityId) => `/blogs/api/blog/gamification_entry/${optional(id)}`;

  static blogEntryAttachments = (contentType: PostTypes, id: EntityId) =>
    `/blogs/api/v2/attachments/${contentType}/${id}/`;

  static blogEntryAttachment = (
    contentType: PostTypes,
    objectId: EntityId,
    id: EntityId,
    attachmentType: PostAttachmentTypes,
  ) => `/blogs/api/v2/attachments/${contentType}/${objectId}/${id}/${attachmentType}/`;

  static removeFromBlogMembers = (blogId: EntityId, roleName: string) =>
    `/blogs/api/v2/blog/${blogId}/participants/${roleName}/remove/`;

  /** @deprecated */
  static attachments = (id?: EntityId) => `/blogs/api/attachments/${optional(id)}`;

  static fileStorageAttachments = (id?: EntityId) => `/filestorage/api/attachments/${optional(id)}`;

  static blogAlbumsListEndpoint = (blogId: EntityId) => `/blogs/api/blog/${blogId}/albums/`;

  static blogAlbumEndpoint = (blogId: EntityId, albumId: EntityId) =>
    `/blogs/api/blog/${blogId}/albums/${albumId}/`;

  static blogAlbumImagesUploadEndpoint = (blogId: EntityId, albumId: EntityId) =>
    `/blogs/api/blog/${blogId}/albums/${albumId}/upload/`;

  static blogAlbumSingleImageEndpoint = (blogId: EntityId, albumId: EntityId, imageId: EntityId) =>
    `/blogs/api/blog/${blogId}/albums/${albumId}/images/${imageId}/`;

  // TODO перенести enum CommentContentTypes в ант, когда будем переносить компоненты для комментариев
  //  и изменить тип string на CommentContentTypes
  static comments = (contentType: string, objectId: EntityId, commentId?: EntityId) =>
    `/blogs/api/comments/${contentType}/${objectId}/${optional(commentId)}`;

  static blogInvite = (id: EntityId) => `/blogs/api/blog/${id}/invite/`;

  static blogSubscriptionsMe = (id: EntityId) => `/blogs/api/subscriptions/me/blog/${id}/`;

  static blogsListSearch = () => `/blogs/api/blog/search/`;

  static blogRevokeInvite = (id: EntityId, userId: EntityId) =>
    `/blogs/api/blog/${id}/revoke_invite/user/${optional(userId)}`;

  static groupsDetailed = (id: EntityId) => `/api/groups/${id}/detailed/`;

  static groupsRoot = () => `/api/groups/root/`;

  static reactionEndpoint = (
    contentType: ReactionPostContentType,
    entityId: EntityId,
    reactionId: EntityId,
  ) => `/blogs/api/reaction/${contentType}/${entityId}/${reactionId}/`;

  static newsReactionSave = () => '/api/news/reaction/save/';

  static newsReaction = (contentType: ReactionNewsContentType, objectId: EntityId) =>
    `/api/news/reaction/${contentType}/${objectId}/`;

  // static reactionsInfoEndpoint = (contentType: ReactionPostContentType, entityId: EntityId) =>
  //   `/blogs/api/reactions/${contentType}/${entityId}/`;

  static blogFavoriteEntries = () => `/blogs/api/entry/favorite/`;

  static blogUserFollowings = (userId: string) => `/blogs/users/${userId}/followings/`;

  static blogUserFollow = (userId: string) => `/blogs/users/${userId}/follow/`;

  static blogUserUnfollow = (userId: string) => `/blogs/users/${userId}/unfollow/`;

  static blogsChangeFavoriteStatus = (contentType: string, id: EntityId) =>
    `/blogs/api/${contentType}/${id}/change_favorite_status/`;

  static profileMain = (userId: string) => `/profile/${userId}/main/`;

  static profilePermissions = () => `/one-api/permissions/all/`;

  /** @deprecated */
  static blogFiles = (blogId: EntityId, directoryId?: EntityId) =>
    `/blogs/api/blog/${blogId}/files/${optional(directoryId)}`;

  static dicts = (name?: EntityId) => `/dicts/${optional(name)}`;

  static dictsRecords = (name: EntityId) => `/dicts/${name}/records/`;

  /** @deprecated: new -> ant/store/dictionaries/endpoints */
  static dictsRecordsNew = (name: EntityId) => `/dicts/${name}/records/new/`;

  static notificationsList = () => '/notifications/feed/';

  static notificationsCategories = () => '/notifications/v2/categories/';

  static notificationConfirm = (id: EntityId) => `/notifications/feed/${id}/mark_confirm/`;

  static notificationRead = (id: EntityId) => `/notifications/feed/${id}/mark_read/`;

  static notificationsMarkReadAll = () => '/notifications/feed/mark_read_all/';

  static polls = () => '/api/polls/polls/';

  static pollById = (id: EntityId) => `/api/polls/polls/${id}/`;

  static vote = (id: EntityId) => `/api/polls/polls/${id}/vote/`;

  static exportPollToXLSX = (id: EntityId) => `/api/polls/polls/${id}/export/xlsx/`;

  static exportPollAnswerToXLSX = (questionId: EntityId, optionId: EntityId) =>
    `/api/polls/polls/question/${questionId}/option/${optionId}/export/xlsx/`;

  static newsByBlogId = (blogId: EntityId) => `/blogs/api/news/${blogId}/entry/`;

  static newsItem = (newsId: EntityId) => `/blogs/api/news/entry/${newsId}/`;

  static news = () => `/blogs/api/news/entry/`;

  // todo принять решение как быть с этой ручкой, т.к. её сейчас нет в продукте
  static config = () => `/krakend/api/config/fsk`;

  static search = () => `/search/search/`;

  static searchBlog = () => `/blogs/api/v2/blog/search/`;

  static searchComment = () => `/blogs/api/v2/blog/comments/search/`;

  static searchPost = () => `/blogs/api/v2/blog/entries/search/`;

  static searchNews = () => `api/news/list/`;

  static eventNew = () => '/api/events/event/new/';

  static event = (id: EntityId) => `/api/events/event/${id}/`;

  static scheduleSlotNew = () => '/api/events/activity/new/';

  static scheduleSlot = (id: EntityId) => `/api/events/activity/${id}/`;

  static eventsList = () => '/api/events/list/';

  static eventConfirmParticipation = (id: EntityId) => `/api/events/event/${id}/confirm_participation/`;

  static eventDeclineParticipation = (id: EntityId) => `/api/events/event/${id}/decline_participation/`;

  static activityConfirmParticipation = (id: EntityId) => `/api/events/activity/${id}/confirm_participation/`;

  static activityDeclineParticipation = (id: EntityId) => `/api/events/activity/${id}/decline_participation/`;

  static scheduleSlotsList = (eventId: EntityId) => `/api/events/event/${eventId}/activity/list/`;

  static fileUpload = () => `/api/filestorage/v2/file/upload/`;
}
