import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { UiTypography } from 'ant/components/ui/typography';
import { AppLink } from 'ant/components/widgets/AppLink';
import authService from 'ant/plugins/authService';
import { getRoutePath, RouteNames } from 'ant/plugins/routes';
import { ProfileInfo } from 'profile-frontend/typings/profile-info';

import styles from './InfoBiography.scss';

const NBSP = '\u00A0';

type Props = {
  userData: ProfileInfo;
};

const InfoBiography: React.FC<Props> = (props) => {
  const { id: userId } = useParams<{ id: string }>();

  const isOwner = userId === authService.getCurrentUserId();

  const { userData } = props;
  const { biography, main, additionalInfo, jobHistory } = userData;

  const { cars, devices } = additionalInfo;
  const { metroStation, townLive, birthDayMonth, birthYear, isBirthYearHidden } = main;
  const { about, hobbies, languages } = biography;

  const aboutContent = about ? (
    <UiTypography.Text>{about}</UiTypography.Text>
  ) : (
    isOwner && (
      <span className={styles.infoBiography__edit}>
        <AppLink
          className={styles.infoBiography__editLink}
          to={getRoutePath(RouteNames.ProfileEdit, { id: userId })}
        >
          <UiTypography.Text strong className={styles.infoBiography__editLinkText}>
            Расскажите о себе
          </UiTypography.Text>
        </AppLink>
        <UiTypography.Text type="secondary">
          &nbsp;&mdash; помогите коллегам узнать вас получше
        </UiTypography.Text>
      </span>
    )
  );

  const birthDayContent = birthDayMonth && (
    <UiTypography.Text className={styles.infoBiography__contentValue}>
      {birthDayMonth}
      {!isBirthYearHidden && Boolean(birthYear) && (
        <>
          &nbsp;
          {birthYear}
        </>
      )}
    </UiTypography.Text>
  );

  const townContent = townLive && (
    <span className={styles.infoBiography__сontentWrapper}>
      <UiTypography.Text className={styles.infoBiography__contentValue}>
        г.&nbsp;
        {townLive}
      </UiTypography.Text>
      {metroStation && (
        <UiTypography.Text className={styles.infoBiography__contentValue}>
          &nbsp;•&nbsp;м.&nbsp;
          {metroStation}
        </UiTypography.Text>
      )}
    </span>
  );

  const jobHistoryContent = useMemo(
    () =>
      jobHistory?.length &&
      jobHistory.map(({ beginDate, endDate, organization, position: jobHistoryPosition }) => (
        <span key={`${beginDate}${organization}${endDate}`}>
          <UiTypography.Text>
            {beginDate}
            &nbsp;—&nbsp;
            {endDate}
            &nbsp;•&#32;
          </UiTypography.Text>
          <UiTypography.Text className={styles.infoBiography__contentValue}>{organization}</UiTypography.Text>
          <UiTypography.Text>
            &nbsp;—&#32;
            {jobHistoryPosition}
          </UiTypography.Text>
        </span>
      )),
    [jobHistory],
  );

  const languagesContent = useMemo(
    () =>
      languages?.length && (
        <UiTypography.Text className={styles.infoBiography__contentValue}>
          {languages
            .map(({ languageName, levelName }) =>
              levelName ? `${languageName}${NBSP}—${NBSP}${levelName}` : `${languageName}`,
            )
            .join(` • `)}
        </UiTypography.Text>
      ),
    [languages],
  );

  const hobbiesContent = useMemo(
    () =>
      hobbies?.length && (
        <UiTypography.Text className={styles.infoBiography__contentValue}>
          {hobbies.map(({ name }) => name).join(`${NBSP}• `)}
        </UiTypography.Text>
      ),
    [hobbies],
  );

  const devicesContent = useMemo(
    () =>
      devices?.length && (
        <UiTypography.Text className={styles.infoBiography__contentValue}>
          {devices.map(({ device }) => device).join(`${NBSP}• `)}
        </UiTypography.Text>
      ),
    [devices],
  );

  const carsContent = useMemo(
    () =>
      cars?.length &&
      cars.map(({ car, carNumber, id }) => (
        <span key={id}>
          {car && <UiTypography.Text className={styles.infoBiography__contentValue}>{car}</UiTypography.Text>}
          {car && carNumber && `${NBSP}• `}
          {carNumber && <UiTypography.Text>{carNumber}</UiTypography.Text>}
        </span>
      )),
    [cars],
  );

  const bioItems = [
    {
      title: 'День рождения',
      content: birthDayContent,
    },
    {
      title: 'Проживаю в',
      content: townContent,
    },
    {
      title: 'Прежние места работы',
      content: jobHistoryContent,
    },
    {
      title: 'Языки',
      content: languagesContent,
    },
    {
      title: 'Хобби',
      content: hobbiesContent,
    },
    {
      title: 'Устройства',
      content: devicesContent,
    },
    {
      title: 'Транспортные средства',
      content: carsContent,
    },
  ];

  const bioContent = bioItems.map(
    ({ content, title }) =>
      Boolean(content) && (
        <div className={styles.infoBiography__wrapper} key={title}>
          <UiTypography.Text type="secondary">{title}</UiTypography.Text>
          {content}
        </div>
      ),
  );

  return (
    <div className={styles.infoBiography}>
      <UiTypography.Title level={3}>Обо мне</UiTypography.Title>

      <div className={styles.infoBiography__content}>
        {aboutContent}
        {bioContent}
      </div>
    </div>
  );
};

export default InfoBiography;
