// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._-_-_-ant-src-theme-picker-color-picker--color-picker{width:220px}._-_-_-ant-src-theme-picker-color-picker--color-picker__palette{font-weight:600;font-size:16px;line-height:20px;display:flex;align-items:flex-end;width:100%;height:120px;padding:12px 16px;box-shadow:0px 4px 8px rgba(46,48,51,0.08);border-radius:0px;margin-bottom:16px}._-_-_-ant-src-theme-picker-color-picker--color-picker__palette:focus{box-shadow:none}._-_-_-ant-src-theme-picker-color-picker--color-picker__description{font-weight:400;font-size:15px;line-height:20px}._-_-_-ant-src-theme-picker-color-picker--color-picker__picker{z-index:1000;position:absolute}\n", ""]);
// Exports
exports.locals = {
	"color-picker": "_-_-_-ant-src-theme-picker-color-picker--color-picker",
	"colorPicker": "_-_-_-ant-src-theme-picker-color-picker--color-picker",
	"color-picker__palette": "_-_-_-ant-src-theme-picker-color-picker--color-picker__palette",
	"colorPicker__palette": "_-_-_-ant-src-theme-picker-color-picker--color-picker__palette",
	"color-picker__description": "_-_-_-ant-src-theme-picker-color-picker--color-picker__description",
	"colorPicker__description": "_-_-_-ant-src-theme-picker-color-picker--color-picker__description",
	"color-picker__picker": "_-_-_-ant-src-theme-picker-color-picker--color-picker__picker",
	"colorPicker__picker": "_-_-_-ant-src-theme-picker-color-picker--color-picker__picker"
};
module.exports = exports;
