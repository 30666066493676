import classNames from 'classnames';
import React, { useMemo } from 'react';

import { UiButton } from 'ant/components/ui/button';
import { UiTooltip } from 'ant/components/ui/tooltip';
import { UiTruncateMarkup } from 'ant/components/ui/truncate-markup';
import { UiTypography } from 'ant/components/ui/typography';
import { AvatarsTooltip } from 'ant/components/widgets/AvatarsTooltip/AvatarsTooltip';
import { declension, colleagueDeclension } from 'ant/plugins/declension';
import { getTruncateStringEnding } from 'ant/plugins/utils/get-truncate-string-ending';
import { SkillsStorage } from 'profile-frontend/store/skills';
import { UserSkill } from 'profile-frontend/typings/skills';

import styles from './SkillsButton.scss';

const ccn = classNames.bind(styles);

type Props = {
  isOwner: boolean;
  currentUserId?: string;
  userSkill: UserSkill;
  skillStorage: SkillsStorage;
  onSkillDelete: (skillId: string) => void;
  buttonClassName?: string;
};

const SkillsButton: React.FC<Props> = (props) => {
  const { userSkill, isOwner, currentUserId, skillStorage, onSkillDelete, buttonClassName } = props;
  const { skill, count, proveThreshold, approvers } = userSkill;
  const { approveSkillEffect } = skillStorage;

  const isSkillApprovedByMe = useMemo(
    () => approvers.some((approver) => approver.userFrom.id === currentUserId),
    [approvers, currentUserId],
  );

  const isProSkill = count >= proveThreshold;

  const getTooltipTitle = () => {
    if (isOwner) {
      return 'Удалить навык';
    }

    return isSkillApprovedByMe ? 'Отменить навык' : 'Подтвердить навык';
  };

  const onSkillHandler = (e: React.SyntheticEvent) => {
    e.stopPropagation();

    if (!isOwner) {
      approveSkillEffect({ skillId: skill.pk, isAccept: !isSkillApprovedByMe });
    } else {
      onSkillDelete(skill.pk);
    }
  };

  const onSkillButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (!isOwner && !isSkillApprovedByMe) {
      onSkillHandler(e);
    }
  };

  const title = (
    <UiTypography.Text className={styles.skillsButton__approversTitle}>
      Подтвердили:&nbsp;
      <strong>{approvers.length}</strong>
      &nbsp;
      {declension(approvers.length, colleagueDeclension)}
    </UiTypography.Text>
  );

  const nameTruncatedEnding = getTruncateStringEnding(skill.name);

  return (
    <AvatarsTooltip
      users={approvers.map((approver) => approver.userFrom)}
      title={title}
      // onShowMore={() => {}} // TODO: Раздел в разработке
    >
      <UiButton className={classNames(styles.skillsButton, buttonClassName)} onClick={onSkillButtonClick}>
        <UiTooltip placement="top" title={getTooltipTitle()}>
          <span
            role="button"
            onClick={onSkillHandler}
            onKeyPress={onSkillHandler}
            className={ccn(styles.skillsButton__icon, {
              [styles.skillsButton__iconPro]: isProSkill,
              [styles.skillsButton__iconClear]: isSkillApprovedByMe || isOwner,
              [styles.skillsButton__iconConfirm]: !isSkillApprovedByMe,
            })}
            tabIndex={0}
          />
        </UiTooltip>
        <UiTruncateMarkup truncate lines={1} ellipsis={`...${nameTruncatedEnding}`}>
          {skill.name}
        </UiTruncateMarkup>
        <UiTypography.Text type="secondary" className={styles.skillsButton__count}>
          {count}
        </UiTypography.Text>
      </UiButton>
    </AvatarsTooltip>
  );
};

export default SkillsButton;
