import { matchPath } from 'react-router-dom';

/**
 * Соглашение по неймингу
 *
 * [PluralEntityName]List - список (например, BlogsList)
 * [EntityName]Create - форма создания (например, BlogCreate)
 * [EntityName]Edit - форма редактирования (например, BlogEdit)
 * [EntityName]View - страница просмотра (например, BlogView)
 * [EntityName][ActionOrEntityName] - страницы для другого действия
 *                                    или подстраница (например, PollVote или BlogSubscribers)
 */
export enum RouteNames {
  Main,
  Profile,
  ProfileEdit,

  AdminNews,
  AdminNewsPinned,
  AdminCategories,
  AdminEvents,
  AdminPanelAdministration,
  AdminPanelManageBids,
  AdminPanelManageBidsView,
  AdminPanelAccount,
  AdminPanelAssistantActionsHistory,

  MainNews,
  BlogNews,
  GroupsList,
  GroupView,
  NewsBlogsList,
  NewsBlogView,

  OrgStructureRoot,
  OrgStructure,
  Services,

  Calendar,

  LMSMain,

  Adaptation,
  AdaptationGroupView,

  MeetingRooms,

  Search,

  AdvancedSearch,

  Events,

  Tasks,
}

export type RouteConfig = Record<RouteNames, string>;

export const routes: RouteConfig = {
  [RouteNames.Main]: '/',
  [RouteNames.Profile]: '/user/:id',
  [RouteNames.ProfileEdit]: '/user/:id/edit',

  [RouteNames.AdminNews]: '/admin/news',
  [RouteNames.AdminNewsPinned]: '/admin/news-pinned',
  [RouteNames.AdminCategories]: '/admin/categories',
  [RouteNames.AdminEvents]: '/admin/events',
  [RouteNames.AdminPanelAdministration]: '/admin/panel/administration',
  [RouteNames.AdminPanelManageBids]: '/admin/panel/manage-bids',
  [RouteNames.AdminPanelManageBidsView]: '/admin/panel/manage-bids/:id/:type?',
  [RouteNames.AdminPanelAccount]: '/admin/panel/account/:id',
  [RouteNames.AdminPanelAssistantActionsHistory]: '/admin/panel/assistant-actions-history/:id',

  [RouteNames.MainNews]: '/news',
  [RouteNames.BlogNews]: '/news/:id',
  [RouteNames.GroupsList]: '/groups',
  [RouteNames.GroupView]: '/groups/:id',
  [RouteNames.NewsBlogsList]: '/channels',
  [RouteNames.NewsBlogView]: '/channels/:id',

  [RouteNames.OrgStructureRoot]: '/structure',
  [RouteNames.OrgStructure]: '/structure/:id',
  [RouteNames.Services]: '/services',

  [RouteNames.Calendar]: '/calendar',

  [RouteNames.LMSMain]: '/lms',

  [RouteNames.Adaptation]: '/adaptation',
  [RouteNames.AdaptationGroupView]: '/adaptation/adaptation-group/:id',

  [RouteNames.MeetingRooms]: '/meeting-rooms',

  [RouteNames.Search]: '/search/:origin',

  [RouteNames.AdvancedSearch]: '/advanced-search',

  [RouteNames.Events]: '/events',

  [RouteNames.Tasks]: '/tasks',
};

export const getRoutePath = (routeName: RouteNames, params?: Record<string, string | number>) => {
  let path = routes[routeName];

  if (params) {
    Object.keys(params).forEach((param) => {
      path = path.replace(new RegExp(`:${param}[?]{0,1}`), encodeURIComponent(params[param]));
    });
  }

  // Заменяем опциональные параметры (/:param?) вместе со слэшем на пустую строку, если параметр не передан
  path = path.replace(/\/:\w+\??/g, '');

  return path;
};

const isPathWithoutParamsMatchRoute = (pathnameWithoutParams: string, routeName: RouteNames) =>
  Boolean(
    matchPath(pathnameWithoutParams, {
      path: (routes as Record<string, string>)[routeName],
      exact: true,
    }),
  );

export const getPathnameWithoutParams = (pathname: string | null) =>
  (pathname || '').split('?')[0].split('#')[0];

export const isPathMatchRoute = (pathname: string | null, routeName: RouteNames) =>
  isPathWithoutParamsMatchRoute(getPathnameWithoutParams(pathname), routeName);

export const checkInnerPath = (pathname: string | null) => {
  const pathnameWithoutParams = getPathnameWithoutParams(pathname);

  return Object.values(RouteNames).some((routeName) =>
    isPathWithoutParamsMatchRoute(pathnameWithoutParams, routeName as RouteNames),
  );
};

export const normalizeToInnerUrl = (url: string) => {
  const remote = process.env.REMOTE_URL || window.location.origin;
  const isRemoteUrl = url.toLocaleLowerCase().startsWith(remote);

  if (isRemoteUrl) {
    const innerRoute = url.replace(remote, '');

    return checkInnerPath(innerRoute) ? innerRoute : url;
  }

  return url;
};

export const normalizeToExternalUrl = (url: string) => (url.startsWith('http') ? url : `https://${url}`);
