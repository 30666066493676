// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._-_-_-components-frontend-src-components-main-breadcrumb--breadcrumb__suffix{margin-left:auto}\n", ""]);
// Exports
exports.locals = {
	"breadcrumb__suffix": "_-_-_-components-frontend-src-components-main-breadcrumb--breadcrumb__suffix"
};
module.exports = exports;
