import React from 'react';
import { Link } from 'react-router-dom';

import MeatballSvg from 'ant/components/svg/meatball.svg';
import { UiAvatar } from 'ant/components/ui/avatar';
import { UiButton } from 'ant/components/ui/button';
import { UiIcon } from 'ant/components/ui/icon/UiIcon';
import { UiTooltip } from 'ant/components/ui/tooltip';
import { getRoutePath, RouteNames } from 'ant/plugins/routes';
import { UserModel, UserProfile } from 'ant/types/models/user';

import styles from './AvatarsTooltip.scss';

type Props = {
  children: React.ReactNode;
  users: (UserModel | UserProfile)[];
  title: React.ReactNode;
  maxCount?: number;
  onShowMore?: () => void;
  isVisible?: boolean;
  mouseLeaveDelay?: number;
};

const AvatarsTooltip: React.FC<Props> = ({
  children,
  users,
  title,
  maxCount = 5,
  onShowMore,
  isVisible = true,
  ...props
}: Props) => {
  if (!users || users.length === 0 || !isVisible) {
    return <>{children}</>;
  }

  const getUserId = (user: UserModel | UserProfile) => {
    return (user as UserModel).keycloakUser?.keycloakId || user.id;
  };

  const tooltipContent = (
    <div className={styles.avatarsTooltip__avatars}>
      <span className={styles.avatarsTooltip__title}>{title}</span>
      <div className={styles.avatarsTooltip__content}>
        {users.slice(0, maxCount).map((user) => (
          <Link to={getRoutePath(RouteNames.Profile, { id: getUserId(user) })} key={user.id}>
            <UiAvatar className={styles.avatarsTooltip__avatar} size={32} src={user.avatar} />
          </Link>
        ))}
        {users.length > maxCount && onShowMore && (
          <UiButton className={styles.avatarsTooltip__icon} onClick={onShowMore}>
            <UiIcon width={20} height={20} component={MeatballSvg} />
          </UiButton>
        )}
      </div>
    </div>
  );

  return (
    <UiTooltip title={tooltipContent} {...props}>
      {children}
    </UiTooltip>
  );
};

export { AvatarsTooltip };
