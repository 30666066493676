import classNames from 'classnames';
import React from 'react';

import ProfileSidebarMain from 'profile-frontend/components/profile/sidebar/ProfileSidebarMain@myteam';
import { Devision } from 'profile-frontend/typings/devision@myteam';

import styles from './ProfileSidebar@myteam.scss';

type Props = {
  userId: string;
  className?: string;
  devisionData?: Devision;
};

const ProfileSidebar: React.FC<Props> = ({ className, userId, devisionData }) => (
  <div className={classNames(styles.profileSidebar, className)}>
    <ProfileSidebarMain userId={userId} devisionData={devisionData} />
  </div>
);

export default ProfileSidebar;
