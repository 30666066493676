const getProfileUrl = (url: string) => `/profile/${url}`;
const getProfileV2Url = (url: string) => `/profile/v2/${url}`;

class ProfileEndpoints {
  static fullInfo = (userId: string) => `/one-api/profile/${userId}/full/`;

  static userBiography = (userId: string) => getProfileUrl(`${userId}/biography/`);

  static userMain = (userId: string) => getProfileUrl(`${userId}/main/`);

  static userAvatarCreate = (userId: string) => getProfileV2Url(`${userId}/avatar/create`);

  static userContacts = (userId: string) => getProfileUrl(`${userId}/contacts/`);

  static userJobHistory = (userId: string) => getProfileUrl(`${userId}/job/history/`);

  static userProjects = (userId: string) => getProfileUrl(`user/${userId}/projects/`);

  static userAdditionalInfo = (userId: string) => getProfileUrl(`${userId}/additional_info/`);

  static projects = () => getProfileUrl('projects/');

  static searchProjects = () => getProfileUrl('projects/search/');

  static searchHobby = () => getProfileUrl('/hobby/search/');
}

export { ProfileEndpoints };
