const themeDefault = require('./theme@default');
const themeRosseti = require('./theme@rosseti');
const themeMyteam = require('./theme@myteam');

let theme;

// todo перенести в отдельный файл сборки по готовности
switch (process.env.CUSTOMER) {
  case 'rosseti':
    theme = themeRosseti;
    break;
  case 'myteam':
    theme = themeMyteam;
    break;
  case 'default':
  default:
    theme = themeDefault;
}

module.exports = { ...theme };
