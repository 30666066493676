// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._-_-_-profile-frontend-src-components-gamification-list-item-header--item-header{display:flex;align-items:center;padding:24px 24px 20px}._-_-_-profile-frontend-src-components-gamification-list-item-header--item-header__link{margin-left:4px}._-_-_-profile-frontend-src-components-gamification-list-item-header--item-header__info{font-weight:600;font-size:15px;line-height:20px;letter-spacing:0.1px;display:flex;margin-left:12px;flex-direction:column}\n", ""]);
// Exports
exports.locals = {
	"item-header": "_-_-_-profile-frontend-src-components-gamification-list-item-header--item-header",
	"itemHeader": "_-_-_-profile-frontend-src-components-gamification-list-item-header--item-header",
	"item-header__link": "_-_-_-profile-frontend-src-components-gamification-list-item-header--item-header__link",
	"itemHeader__link": "_-_-_-profile-frontend-src-components-gamification-list-item-header--item-header__link",
	"item-header__info": "_-_-_-profile-frontend-src-components-gamification-list-item-header--item-header__info",
	"itemHeader__info": "_-_-_-profile-frontend-src-components-gamification-list-item-header--item-header__info"
};
module.exports = exports;
