import { KeycloakUser } from 'ant/types/models/keycloak-user';
import { MeetingScales } from 'ant/types/models/meetings';

import { Pagination } from './Pagination';

export enum CalendarUserStatus {
  NeedAction = 'need-action',
  Accepted = 'accepted',
  Rejected = 'rejected',
  Maybe = 'maybe',
}

// TODO: На бекенде нужно убрать айдишники на человекочитаемое название.
export enum CalendarEventType {
  Meeting = 'e8ad7e19-8e91-48a7-9eb9-402be8920c1f',
  Task = '41582cac-9eed-4777-b291-9b734b19eea0',
  Learn = '1895d255-69be-45fc-9aff-9b3251c85daa',
  Activity = 'a4068e5e-f1aa-4a4d-a20e-97532a884a42',
}

export enum CalendarViewType {
  Week = 'week',
  Month = 'month',
  Day = 'day',
}

export enum CalendarFrequency {
  Day = 'day',
  Week = 'week',
  Month = 'month',
  Year = 'year',
}

export enum CalendarSourceType {
  Exchange = 'exchange',
  Sps = 'sps',
  Iasup = 'iasup',
}

export enum CalendarRequiredMembers {
  All = 'all',
  RequiredOnly = 'required_only',
}

export type CalendarRepeatRule = {
  frequency: CalendarFrequency;
  interval: number;
  since: string;
  till: string;
};

export type CalendarAttendee = {
  userId: string;
  status: CalendarUserStatus;
  fullName: string;
  image: string;
  mailto: string;
  keycloakUser?: KeycloakUser;
  position?: string;
};

export type CalendarAttendeeToAdd = {
  userId: string;
  status: CalendarUserStatus;
  fullName?: string;
  image?: string;
  mailto: string;
  keycloakUser?: KeycloakUser;
  position?: string;
};

export type CalendarAttendeeList = CalendarAttendee[];
export type CalendarAttendeeToAddList = CalendarAttendeeToAdd[];

export interface CalendarEventModel {
  id: string;
  calendarId: string;
  organizer: CalendarAttendee;
  summary: string;
  since: string;
  till: string;
  isFullDay?: boolean;
  repeatRule?: CalendarRepeatRule;
  firstAttendees?: CalendarAttendee[];
  eventType: CalendarEventType;
  sourceType: CalendarSourceType;
  description?: string;
  files?: string[];
  place?: string;
  myStatus: CalendarUserStatus;
  link?: string;
  groupName?: string;
  slug?: string;
  // TODO: Решить с бекендом, нужен ли формат string, если используется только number ('1')
  meetingRoomId?: string;
}

export type CalendarReducedEvent = Record<string, CalendarEventModel[]>;

export type CalendarFreeSlot = {
  since: string;
  till: string;
  meetingRoomsCount?: number;
};

export type CalendarBusyInterval = {
  since: string;
  till: string;
  status: CalendarUserStatus;
};

export type CalendarBusySlots = {
  userId: string;
  intervals: CalendarBusyInterval[];
};

export type CalendarEventDates = {
  since: string;
  till: string;
};

export type CalendarEventEditFormValuesType = {
  id?: string;
  summary?: string;
  sinceDate?: Date;
  tillDate?: Date;
  isFullDay?: boolean;
  repeatRule?: CalendarRepeatRule;
  attendees?: CalendarAttendeeToAdd[];
  description?: string;
  files?: string[];
  link?: string;
  place?: string;
  allowInvitation?: string;
  meetingScale?: MeetingScales;
  meetingRoomId?: string;
  isEdit?: boolean;
};

export type CalendarListItem = {
  id: string;
  name: string;
  sourceType: CalendarSourceType;
};
export interface CalendarList {
  meta: Pagination;
  items: CalendarListItem[];
}

export interface CalendarSubscriptions {
  meta: Pagination;
  items: CalendarListItem[];
}

export interface CalendarEventsSummary {
  events: Event;
  eventTypeName: number;
}

export interface CalendarEventCreateParams {
  summary: string;
  since: string;
  till: string;
  isFullDay?: boolean;
  repeatRule?: CalendarRepeatRule;
  attendees?: CalendarAttendeeToAdd[];
  eventType: CalendarEventType;
  sourceType: CalendarSourceType;
  description?: string;
  files?: string[];
  place?: string;
  link?: string;
  meetingRoomId?: string;
}

export interface CalendarEventUpdateParams {
  id: string;
  summary?: string;
  since?: string;
  till?: string;
  isFullDay?: boolean;
  repeatRule?: CalendarRepeatRule;
  attendeesToAdd?: CalendarAttendeeToAdd[];
  attendeesToRemove?: string[];
  eventType?: CalendarEventType;
  description?: string;
  place?: string;
  link?: string;
}

export interface CalendarStatusUpdate {
  accepted?: string[];
  rejected?: string[];
}

export interface CalendarPotentialAttendee {
  id: string;
  fullName: string;
  email: string;
  avatar: string;
}
