// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._-_-_-profile-frontend-src-components-skills-modal--skills-modal__header{margin:0}._-_-_-profile-frontend-src-components-skills-modal--skills-modal__header-title{min-height:44px;padding:24px 24px 0}._-_-_-profile-frontend-src-components-skills-modal--skills-modal__content{display:flex;flex-direction:column;padding:24px}._-_-_-profile-frontend-src-components-skills-modal--skills-modal__content-wrapper{display:flex;margin-top:24px}\n", ""]);
// Exports
exports.locals = {
	"skills-modal__header": "_-_-_-profile-frontend-src-components-skills-modal--skills-modal__header",
	"skillsModal__header": "_-_-_-profile-frontend-src-components-skills-modal--skills-modal__header",
	"skills-modal__header-title": "_-_-_-profile-frontend-src-components-skills-modal--skills-modal__header-title",
	"skillsModal__headerTitle": "_-_-_-profile-frontend-src-components-skills-modal--skills-modal__header-title",
	"skills-modal__content": "_-_-_-profile-frontend-src-components-skills-modal--skills-modal__content",
	"skillsModal__content": "_-_-_-profile-frontend-src-components-skills-modal--skills-modal__content",
	"skills-modal__content-wrapper": "_-_-_-profile-frontend-src-components-skills-modal--skills-modal__content-wrapper",
	"skillsModal__contentWrapper": "_-_-_-profile-frontend-src-components-skills-modal--skills-modal__content-wrapper"
};
module.exports = exports;
