import { updateCSS } from 'rc-util/lib/Dom/dynamicCSS';
import React, { useCallback, useEffect, useRef } from 'react';

import { ThemeState, ThemeOptions, ThemeProviderProps } from './types';

type ThemeAction = (state: ThemeOptions) => void;

const ThemeContext = React.createContext<[ThemeState, ThemeAction] | undefined>(undefined);

const ThemeProvider: React.FC<ThemeProviderProps> = ({ children, theme, onChange }) => {
  const dynamicStyleMark = `${theme.varsPrefix}`;

  const updateCSSVariablesRoot = ({ variables }: ThemeState) => {
    const cssVariablesList = Object.keys(variables).map(
      (key) => `${theme.varsPrefix}-${key}: ${variables[key]};`,
    );

    updateCSS(`:root {${cssVariablesList.join('\n')}}`, dynamicStyleMark);
  };

  const onChangeRef = useRef(onChange);

  onChangeRef.current = onChange;

  const setTheme = useCallback((value: ThemeOptions) => {
    updateCSSVariablesRoot(value);

    if (onChangeRef.current) {
      onChangeRef.current(value);
    }
  }, []);

  useEffect(() => {
    updateCSSVariablesRoot(theme);
  }, [theme]);

  return <ThemeContext.Provider value={[{ ...theme }, setTheme]}>{children}</ThemeContext.Provider>;
};

const useTheme = (): [ThemeOptions, ThemeAction] => {
  const context = React.useContext(ThemeContext);

  if (!context) {
    throw new Error('Can not resolve ThemeContext into useTheme hook');
  }

  return context;
};

export { ThemeProvider, useTheme };
