import { Dictionaries } from './dictionaries';

class DictionariesEndpoints {
  static dictsList = () => `/dicts/`;

  static dictsListByIds = () => `/dicts/records/list/`;

  static dictsByName = (dictionaryName: Dictionaries.Name) => `/dicts/${dictionaryName}/`;

  static dictsSearchRecords = (dictionaryName: Dictionaries.Name) => `/dicts/${dictionaryName}/records/`;

  static dictsRecordsNew = (dictionaryName: Dictionaries.Name) => `/dicts/${dictionaryName}/records/new/`;
}

export { DictionariesEndpoints };
