import { createEvent, createStore } from 'effector';

import { GlobalModalNames, GlobalModalsStorePayloads } from './modal-types';

export type GlobalModalsStoreStoredValue = Partial<GlobalModalsStorePayloads>;

const openUpdateModalEvent = createEvent<{
  modalName: GlobalModalNames;
  payload: GlobalModalsStorePayloads[GlobalModalNames]['payload'];
}>();
const hideModalEvent = createEvent<{ modalName: GlobalModalNames }>();
const closeModalEvent = createEvent<{ modalName: GlobalModalNames }>();
const resetEvent = createEvent();

export const globalModalsStore = createStore<GlobalModalsStoreStoredValue>({})
  .on(openUpdateModalEvent, (state, { modalName, payload }) => ({
    ...state,
    [modalName]: {
      isVisible: true,
      isHiding: false,
      payload,
    },
  }))
  .on(hideModalEvent, (state, { modalName }) => {
    const currentState = state[modalName];

    if (currentState) {
      return {
        ...state,
        [modalName]: {
          isVisible: false,
          isHiding: true,
          keyboard: false,
          payload: currentState.payload,
        },
      };
    }

    return state;
  })
  .on(closeModalEvent, (state, { modalName }) => {
    const newState = { ...state };

    delete newState[modalName];

    return newState;
  })
  .reset(resetEvent);

export const globalModalsVisibleCountStore = globalModalsStore.map((globalModals) => {
  return Object.values(GlobalModalNames).reduce(
    (count, name) => (globalModals[name]?.isVisible && !globalModals[name]?.isHiding ? count + 1 : count),
    0,
  );
});

export const openGlobalModal = <T extends GlobalModalNames>(
  modalName: T,
  payload: GlobalModalsStorePayloads[T]['payload'] = {},
) => {
  openUpdateModalEvent({ modalName, payload });
};

export const updateGlobalModalPayload = <T extends GlobalModalNames>(
  modalName: T,
  payloadDiff: Partial<GlobalModalsStorePayloads[T]['payload']> = {},
) => {
  const currentState = globalModalsStore.getState()[modalName];

  if (currentState?.isVisible && !currentState?.isHiding) {
    const currentPayload = currentState.payload;

    openUpdateModalEvent({
      modalName,
      payload: {
        ...currentPayload,
        ...payloadDiff,
      },
    });
  }
};

export const closeGlobalModal = <T extends GlobalModalNames>(modalName: T) => {
  hideModalEvent({ modalName });

  window.setTimeout(() => {
    closeModalEvent({ modalName });
  }, 500);
};

export const closeAllGlobalModals = () => {
  resetEvent();
};
