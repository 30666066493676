import classNames from 'classnames';
import React, { ReactNode, useContext } from 'react';

import { UiScrollbarsMyteam, ScrollbarContextMyteam } from 'ant/components/ui/scrollbars/UiScrollbars@myteam';
import { useInitialScrollTop } from 'ant/helpers/hooks/use-initial-scroll-top';
import ProfileTimeslots from 'components-frontend/components/profile-timeslots/ProfileTimeslots';
import { ProfilePageProps } from 'profile-frontend/components/ProfilePage';
import Contacts from 'profile-frontend/components/contacts/Contacts';
import Gamification from 'profile-frontend/components/gamification/Gamification';
import ProfileMenuLogout from 'profile-frontend/components/profile-menu/logout/ProfileMenuLogout'; // TODO: заменили './components/profile-menu/ProfileMenu' по задаче B2BCORE-941
import Profile from 'profile-frontend/components/profile/Profile';
import InfoReferencesProjects from 'profile-frontend/components/profile/info/references/InfoReferencesProjects@myteam';
import Skills from 'profile-frontend/components/skills/Skills';

import styles from './ProfilePage.scss';
import additionalStyles from './ProfilePage@myteam.scss';

type props = {
  className?: string;
  children?: ReactNode;
};

const EventDetailCreatingSection: React.FC<props> = ({ className, ...props }) => (
  <UiScrollbarsMyteam
    className={className}
    viewClassName={classNames(className, additionalStyles.eventDetailCreating__sectionScrollbars__view)}
    {...props}
  />
);

const ProfilePage: React.FC<ProfilePageProps> = ({ ProfileTimeline }) => {
  const { current } = useContext(ScrollbarContextMyteam);

  useInitialScrollTop(current);

  return (
    <>
      <div className={styles.pageMain__horizontalContainer}>
        <div
          className={classNames(styles.pageMain__sectionContainer, styles.pageMain__sectionContainer_movedUp)}
        >
          <div className={styles.pageMain__bigSection}>
            <div className={styles.pageMain__timeSlots}>
              <ProfileTimeslots
                slotsTableClassName={additionalStyles.slotsTable}
                customModalSection={EventDetailCreatingSection}
                formClassName={additionalStyles.eventDetailCreating}
                showCurrentTime
              />
            </div>
          </div>
          <div className={styles.pageMain__row}>
            <div className={styles.pageMain__infinityScroll}>
              <Profile
                InfoReferencesProjects={InfoReferencesProjects}
                avatarUploadBtnClassName={additionalStyles.profileAvatar__upload}
              />
              <ProfileTimeline />
            </div>
            <div className={styles.pageMain__rowWrapper}>
              <div className={styles.pageMain__column}>
                <Contacts QRCodeBgColor="#ffffff" />
              </div>
              <div className={styles.pageMain__column}>
                <Skills
                  skillBtnClassName={additionalStyles.skillsButton}
                  moreBtnClassName={additionalStyles.skillsButton__more}
                />
                <Gamification />
                <div className={styles.pageMain__columnItem}>
                  <ProfileMenuLogout />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfilePage;
