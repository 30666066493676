import React from 'react';
import { Link } from 'react-router-dom';

import Appreaction3Svg from 'ant/components/svg/appreaction-3.svg';
import { UiAvatar } from 'ant/components/ui/avatar';
import { UiIcon } from 'ant/components/ui/icon';
import { UiTypography } from 'ant/components/ui/typography';
import { getRoutePath, RouteNames } from 'ant/plugins/routes';
import { Gamification } from 'profile-frontend/typings/gamification';

import ItemCoins from '../list/item/coins/ItemCoins';
import styles from './GamificationThankPost.scss';

type Props = {
  thank: Gamification.AddedThank;
  onClose?: () => void;
};

const GamificationThankPost: React.FC<Props> = (props) => {
  const { thank, onClose } = props;
  const { attributes, fromUser, coins, msg, toUser } = thank;

  return (
    <>
      <span className={styles.gamificationThankPost__avatars}>
        <Link to={getRoutePath(RouteNames.Profile, { id: fromUser.id })} onClick={onClose}>
          <UiAvatar src={fromUser?.avatar} size={80} />
        </Link>
        <UiAvatar
          src={attributes?.image}
          size={120}
          className={styles.gamificationThankPost__avatarsAchievement}
          icon={<UiIcon component={Appreaction3Svg} height={60} width={60} />}
        />
        <Link to={getRoutePath(RouteNames.Profile, { id: toUser.id })} onClick={onClose}>
          <UiAvatar src={toUser?.avatar} size={80} />
        </Link>
      </span>

      {coins > 0 && <ItemCoins coins={coins} className={styles.gamificationThankPost__coins} />}

      <UiTypography.Title level={2} style={{ margin: '16px 0 8px' }}>
        {attributes?.name}
      </UiTypography.Title>

      <UiTypography.Text className={styles.gamificationThankPost__msg}>{msg}</UiTypography.Text>
    </>
  );
};

export default GamificationThankPost;
