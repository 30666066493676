// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._-_-_-components-frontend-src-components-slots-table-selection--slots-table-selection{position:absolute;display:flex;top:0;height:100%;z-index:2}._-_-_-components-frontend-src-components-slots-table-selection--slots-table-selection__inner{flex:auto;background-color:rgba(62, 172, 235, 1);opacity:0.32;z-index:2;cursor:move}._-_-_-components-frontend-src-components-slots-table-selection--slots-table-selection__border{width:2px;background:rgba(62, 172, 235, 1);z-index:2;cursor:ew-resize}\n", ""]);
// Exports
exports.locals = {
	"slots-table-selection": "_-_-_-components-frontend-src-components-slots-table-selection--slots-table-selection",
	"slotsTableSelection": "_-_-_-components-frontend-src-components-slots-table-selection--slots-table-selection",
	"slots-table-selection__inner": "_-_-_-components-frontend-src-components-slots-table-selection--slots-table-selection__inner",
	"slotsTableSelection__inner": "_-_-_-components-frontend-src-components-slots-table-selection--slots-table-selection__inner",
	"slots-table-selection__border": "_-_-_-components-frontend-src-components-slots-table-selection--slots-table-selection__border",
	"slotsTableSelection__border": "_-_-_-components-frontend-src-components-slots-table-selection--slots-table-selection__border"
};
module.exports = exports;
