import { ModalFuncProps } from 'antd/es/modal';

import { ConfirmModalFuncProps } from './types';

export const getAntdModalFuncProps = ({
  disabled,
  ...modalFuncProps
}: ConfirmModalFuncProps): ModalFuncProps => ({
  ...modalFuncProps,
  icon: null,
  width: 460,
  autoFocusButton: null,
  okButtonProps: {
    size: 'large',
    disabled: Boolean(disabled),
  },
  cancelButtonProps: {
    size: 'large',
    disabled: Boolean(disabled),
  },
});
