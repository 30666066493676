import { FileUploadAccepts } from 'ant/types/models/file-upload-accepts';

type FileMimeTypeChecker = (fileType: string) => boolean;

const fileMimeTypeCheckersMap: Record<FileUploadAccepts, FileMimeTypeChecker> = {
  [FileUploadAccepts.All]: () => true,
  [FileUploadAccepts.VideoAll]: (fileType) => fileType.startsWith('video/'),
  [FileUploadAccepts.ImageAll]: (fileType) => fileType.startsWith('image/'),
  [FileUploadAccepts.ImagePngJpg]: (fileType) =>
    fileType === 'image/png' || fileType === 'image/jpg' || fileType === 'image/jpeg',
  [FileUploadAccepts.ImagePngJpgGif]: (fileType) =>
    fileType === 'image/png' ||
    fileType === 'image/jpg' ||
    fileType === 'image/jpeg' ||
    fileType === 'image/gif',
  [FileUploadAccepts.PackageZip]: (fileType) =>
    fileType === 'application/zip' ||
    fileType === 'application/octet-stream' ||
    fileType === 'application/x-zip-compressed' ||
    fileType === 'multipart/x-zip',
  [FileUploadAccepts.ExcelXlsx]: (fileType) =>
    fileType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  [FileUploadAccepts.Html]: (fileType) => fileType === 'text/html',
};

export const checkFileMimeType = (expectedMimeType: FileUploadAccepts, fileType: string) =>
  fileMimeTypeCheckersMap[expectedMimeType](fileType);
