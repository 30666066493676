// Dynamic palette
/** @note: используется только для const [{ variables: themeVariables }] = useTheme(); */
const themeVariables = {
  // Brand
  colorBrand: '#f9254d',
  colorBrand04: 'rgba(249, 37, 77, 0.04)',
  colorBrand08: 'rgba(249, 37, 77, 0.08)',
  colorBrand16: 'rgba(249, 37, 77, 0.16)',
  colorBrand32: 'rgba(249, 37, 77, 0.32)',
  colorBrandFaded: '#f995a8',
  colorHoverOnBrand: '#e31c46',
  colorInputOnBrand: '#f7575d',
  colorLinkBrand: '#e60b35',
  colorTextOnBrand: '#fff',
};

const themePalette = {
  // Background
  colorBgPrimary: '#fff',
  colorBgSecondary: '#f2f4f7',
  colorFade: 'rgba(31, 32, 34, 0.64)',

  // Text
  colorTextPrimary: '#252629',
  colorTextSecondary: '#656970',
  colorTextTertiary: '#9ca3ad',
  colorTextInvert: '#fff',

  // Additional Shades/Tones
  colorIcon: '#a5acb8',
  colorStroke: '#e9ecf2',
  colorInput: '#f9fafb',

  // Statuses
  colorSuccess: '#17bc85',
  colorNegative: '#f05a4f',
  colorWarning: '#ffc633',

  // Accidents
  colorAccidentRed: '#FA7A7A',
  colorAccidentCoral: '#FF8C62',
  colorAccidentOrange: '#FFB35B',
  colorAccidentYellow: '#D8D35D',
  colorAccidentLime: '#77D986',
  colorAccidentSea: '#5AD1A6',
  colorAccidentTurquoise: '#5AD1CA',
  colorAccidentCyan: '#52CAE4',
  colorAccidentBlue: '#92B2EF',
  colorAccidentLavender: '#C380ED',
  colorAccidentPink: '#E290DA',
  colorAccidentMagenta: '#F095A7',
  colorAccidentTagSilver: '#B7BFC6',
  colorAccidentGold: '#CCBE9B',

  // Calendar
  colorTaskGreen: 'rgba(213, 245, 225, 0.8)',
  colorTaskBlue: 'rgba(208, 237, 255, 0.8)',
  colorTaskYellow: 'rgba(250, 238, 216, 0.8)',
  colorTaskRed: 'rgba(245, 220, 218, 0.8)',
  colorTaskUnconfirmed: 'rgba(233, 236, 242, 0.8)',
  colorTaskPast: 'rgba(233, 236, 242, 0.4)',
  colorTimeSelect: 'rgba(62, 172, 235, 1)',
  colorBrand08Untransperent: 'rgba(255, 239, 241, 1)',

  // Base Colors
  white: '#fff',
  black: '#000',

  // Other
  colorGrayDark: '#333333',
  colorGrayIcon: '#beced6',
  colorStrokeDark: '#d5d8de',
  colorStrokeLight: '#e4e7ed',
  colorShadowAvatar: 'rgba(46, 48, 51, 0.08)',
  colorSkeleton: '#e4e7ed',
  colorSkeletonHighlight: '#f5f5f5',
  colorFormItemRequired: '#ff4d4f',
};

const themeWeights = {
  btnFontWeight: '600',
};

const themeSizes = {
  borderRadiusBase: '4px',

  heading2FontSize: '24px',
  heading3FontSize: '17px',
  heading4FontSize: '15px',
  heading5FontSize: '13px',

  btnHeightBase: '40px',
  btnHeightLg: '48px',
  btnHeightSm: '32px',
  btnBorderWidth: '2px',
  btnLineHeight: '20px',

  btnFontSize: '15px',
  btnPaddingHorizontalBase: '16px',

  selectSingleItemHeightLg: '48px',
  selectDropdownHeight: '40px',
  selectDropdownFontSize: '15px',
  selectDropdownLineHeight: '20px',

  modalHeaderBorderWidth: '0',
};

const themeInput = {
  heightBase: '40px',
  heightLg: '48px',
  heightSm: '32px',
  paddingHorizontalBase: '16px',
  paddingHorizontalLg: '16px',
  paddingHorizontalSm: '12px',
  paddingVerticalBase: '10px',
  paddingVerticalLg: '13px',
  paddingVerticalSm: '6px',
};

const themeWidths = {
  largeModalWidth: '860px',
};

const themeOffsets = {
  modalOffset: '72px',
  widgetMargin: '20px',
};

const themeLongRead = {
  /** DEFAULT PROPS */
  defaultTextColor: themePalette.colorTextPrimary,
  defaultFontSize: '18px',
  defaultLineHeight: '28px',

  /** P */
  paragraphFontSize: '18px',
  paragraphLineHeight: '28px',
  paragraphMargin: '16px 0',

  /** H1 */
  heading1FontSize: '40px',
  heading1LineHeight: '44px',
  heading1FontWeight: 'bold',
  heading1Margin: '24px 0',

  /** H2 */
  heading2FontSize: '32px',
  heading2LineHeight: '40px',
  heading2FontWeight: 'bold',
  // eslint-disable-next-line sonarjs/no-duplicate-string
  heading2Margin: '24px 0 8px',

  /** H3 */
  heading3FontSize: '28px',
  heading3LineHeight: '36px',
  heading3FontWeight: 'bold',
  heading3Margin: '24px 0 8px',

  /** H4, H5, H6 */
  heading4FontSize: '24px',
  heading4LineHeight: '32px',
  heading4FontWeight: 'bold',
  heading4Margin: '24px 0 8px',

  /** OL, UL, LI */
  listMargin: '10px 0',
  listLeftPadding: '32px',
  listItemFontSize: '19px',
  listItemLineHeight: '28px',
  listItemMargin: '6px 0',
  listBulletColor: themePalette.colorTextInvert,
  listBulletBackgroundColor: themeVariables.colorBrand, // TODO пока что цвет не меняется динамически, надо обдумать
  listBulletWidth: '18px',
  listBulletGap: '-12px',

  /** UL LI */
  unorderedListBulletHeight: '28px',
  unorderedListItemBulletBackground: `url('data:image/svg+xml;utf8,<svg width="20" height="28" viewBox="0 0 20 28" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.17157 9.82843C8.50491 8.49509 9.17157 7.82843 10 7.82843C10.8284 7.82843 11.4951 8.49509 12.8284 9.82843L14.1716 11.1716C15.5049 12.5049 16.1716 13.1716 16.1716 14C16.1716 14.8284 15.5049 15.4951 14.1716 16.8284L12.8284 18.1716C11.4951 19.5049 10.8284 20.1716 10 20.1716C9.17157 20.1716 8.50491 19.5049 7.17157 18.1716L5.82843 16.8284C4.49509 15.4951 3.82843 14.8284 3.82843 14C3.82843 13.1716 4.49509 12.5049 5.82843 11.1716L7.17157 9.82843Z" fill="${encodeURIComponent(
    themeVariables.colorBrand,
  )}"/></svg>')`,

  /** OL LI */
  orderedListItemBulletTop: '4px',
  orderedListItemBulletHeight: '18px',
  orderedListItemBulletFontSize: '13px',
  orderedListItemBulletLineHeight: '14px',
  orderedListItemBulletFontWeight: '600',

  /** BLOCKQUOTE */
  blockquoteFontSize: '19px',
  blockquoteLineHeight: '28px',
  blockquoteMargin: '24px 0',
  blockquotePadding: '20px 24px',
  blockquoteFontWeight: '600',
  blockquoteBorderRadius: '4px',
  blockquoteBackgroundColor: themeVariables.colorBrand,
  blockquoteTextColor: themePalette.colorTextInvert,

  /** A */
  anchorColor: themeVariables.colorLinkBrand,
  anchorTextDecoration: 'none',
  anchorHoverColor: themeVariables.colorHoverOnBrand,
  anchorHoverTextDecoration: 'underline',

  // TODO Image
  // TODO Image Gallery
};

const themeFonts = {
  fontFamily: "'Proxima Nova', arial, sans-serif",
};

module.exports = {
  themePalette,
  themeVariables,
  themeWeights,
  themeWidths,
  themeOffsets,
  themeSizes,
  themeLongRead,
  themeInput,
  themeFonts,
};
