import { Select as AntSelect, SelectProps } from 'antd';
import { SelectValue } from 'antd/es/select';
import classNames from 'classnames';
import { OptionData, OptionGroupData } from 'rc-select/es/interface';
import React from 'react';

import DropDownSvg from 'ant/components/svg/drop-down.svg';

import styles from './UiSelect.scss';

const { Option: UiOption, OptGroup: UiOptGroup } = AntSelect;

export { UiOption, UiOptGroup };

export type UiOptionItem = { value: string; label: string };
export type UiOptionData = OptionData | OptionGroupData;

interface UiSelectProps<VT> extends SelectProps<VT> {
  type?: 'text';
}

export const UiSelect = <VT extends SelectValue = SelectValue>(props: UiSelectProps<VT>) => {
  const { type, suffixIcon, className, dropdownClassName, ...rest } = props;
  const SuffixIcon = suffixIcon !== undefined ? suffixIcon : <DropDownSvg />;
  const isTextType = type === 'text';

  const classNamesStyles = classNames(className, { [styles.uiSelect__text]: isTextType });
  const dropdownClassNameStyles = classNames(dropdownClassName, {
    [styles.uiSelect__textOptions]: isTextType,
  });

  return (
    <AntSelect<VT>
      {...rest}
      suffixIcon={SuffixIcon}
      getPopupContainer={(trigger) => trigger.parentElement}
      className={classNamesStyles}
      dropdownClassName={dropdownClassNameStyles}
    />
  );
};
