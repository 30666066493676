import React from 'react';

import { UiButton } from 'ant/components/ui/button';
import { UiModal } from 'ant/components/ui/modals';
import { getModalStepsForSingleTitle } from 'ant/plugins/utils/get-modal-steps-for-single-title';

import styles from './SkillsModal.scss';

type Props = {
  title: string;
  onCancel: () => void;
  children: React.ReactNode;
  submitChildren: React.ReactNode;
};

const SkillsModal: React.FC<Props> = (props) => {
  const { title, onCancel, children, submitChildren } = props;

  return (
    <>
      <UiModal.Header className={styles.skillsModal__header}>
        <UiModal.Header.Title
          wrapperClassName={styles.skillsModal__headerTitle}
          steps={getModalStepsForSingleTitle(title)}
        />
      </UiModal.Header>

      <UiModal.Content className={styles.skillsModal__content}>
        {children}

        <span className={styles.skillsModal__contentWrapper}>
          {submitChildren}
          <UiButton label="Отмена" size="large" style={{ marginLeft: 16 }} onClick={onCancel} block />
        </span>
      </UiModal.Content>
    </>
  );
};

export default SkillsModal;
