import { Populate, PopulateEditChange } from '@di/core-components';

export enum LayoutTypes {
  Header = 'header',
  Navbar = 'navbar',
  Breadcrumb = 'breadcrumb',
  Central = 'central',
  Content = 'content',
  Column = 'column',
  Footer = 'footer',
}

export type EditPopulatedLayoutState = {
  isEdit: boolean;
  isSuperAdminPopulateWidgets: boolean;
  resetItems: number[];
  backupItems: Populate[];
  editChanges: PopulateEditChange[];
};
