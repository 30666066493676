import { PopulateEditChange } from '@di/core-components';
import { api } from 'ant/plugins/api';

import { PopulatedLayoutEndpoints } from './endpoints';

export type PostResetLayoutItemsParams = {
  layoutItemIds: number[];
};

export type PostUsersPopulate = {
  editChanges: PopulateEditChange[];
};

export const postResetLayoutItems = ({ layoutItemIds }: PostResetLayoutItemsParams) => {
  return api
    .post({
      url: PopulatedLayoutEndpoints.resetLayoutItems(),
      data: { layoutItemIds },
    })
    .then(({ data }) => data);
};

export const postUsersPopulate = ({ editChanges }: PostUsersPopulate) => {
  return api
    .post<PopulateEditChange>({
      url: PopulatedLayoutEndpoints.populate(),
      data: editChanges,
    })
    .then(({ data }) => data);
};

export const postResetDefaultLayoutItems = ({ layoutItemIds }: PostResetLayoutItemsParams) => {
  return api
    .post({
      url: PopulatedLayoutEndpoints.resetDefaultLayoutItems(),
      data: { layoutItemIds },
    })
    .then(({ data }) => data);
};

export const postDefaultPopulate = ({ editChanges }: PostUsersPopulate) => {
  return api
    .post<PopulateEditChange>({
      url: PopulatedLayoutEndpoints.populateDefault(),
      data: editChanges,
    })
    .then(({ data }) => data);
};
