import { useStore } from 'effector-react';
import { useEffect, useState } from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';

import { AbstractStorage, AbstractStorageStoredData } from '../storage/abstract-storage-factory';

type AutoFetchAndRefetchCallbackPayload<S, D> = AbstractStorageStoredData<S, D> & {
  fetchedAtLeastOnce: boolean;
};

type AutoFetchAndRefetchParameter<S, D> =
  | boolean
  | ((storedData: AutoFetchAndRefetchCallbackPayload<S, D>) => boolean);

export type UseAbstractStorageParams<S, D, B> = {
  resetStoreOnUnmount?: boolean;
  cancelPendingRequestOnUnmount?: boolean;
  autoFetchAndRefetch?: AutoFetchAndRefetchParameter<S, D>;
  autoFetchParams?: B;
};

/**
 * Дока лежит в корне проекта по пути
 * /docs/abstract-storage.md
 * #Использование-в-React-компонентах
 */
const useAbstractStorage = <R, S, D, B extends Record<string, unknown> | void>(
  storage: AbstractStorage<R, S, D, B>,
  {
    resetStoreOnUnmount,
    cancelPendingRequestOnUnmount,
    autoFetchAndRefetch,
    autoFetchParams,
  }: UseAbstractStorageParams<S, D, B> = {},
) => {
  const { fetchEffect, store, resetStoreEvent, cancelPendingRequest, refetchWithLastParams } = storage;
  const storedData = useStore(store);
  const { data, pagination, error, fetchRequestCount } = storedData;
  const fetchedAtLeastOnce = fetchRequestCount !== 0;
  const [hasMounted, setHasMounted] = useState(false);

  const isFetching = useStore(fetchEffect.pending);
  const loading = !hasMounted || isFetching;
  const autoFetchValue = (() => {
    if (!autoFetchAndRefetch) {
      return false;
    }

    if (typeof autoFetchAndRefetch === 'boolean') {
      return autoFetchAndRefetch;
    }

    return autoFetchAndRefetch({
      ...storedData,
      fetchedAtLeastOnce,
    });
  })();

  useDeepCompareEffect(() => {
    if (autoFetchValue) {
      if (autoFetchParams) {
        fetchEffect(autoFetchParams);
      } else {
        fetchEffect(undefined as B);
      }
    }
  }, [fetchEffect, autoFetchValue, autoFetchParams]);

  useEffect(() => {
    setHasMounted(true);

    return () => {
      setHasMounted(false);
    };
  }, []);

  useEffect(
    () => () => {
      if (resetStoreOnUnmount) {
        resetStoreEvent();
      }
    },
    [resetStoreOnUnmount, resetStoreEvent],
  );

  useEffect(
    () => () => {
      if (cancelPendingRequestOnUnmount) {
        cancelPendingRequest();
      }
    },
    [cancelPendingRequestOnUnmount, cancelPendingRequest],
  );

  return {
    store,
    fetchFx: fetchEffect,
    data,
    pagination,
    loading,
    resetStoreEvent,
    cancel: cancelPendingRequest,
    error,
    refetchWithLastParams,
    fetchedAtLeastOnce,
    fetchRequestCount,
  };
};

export { useAbstractStorage };
