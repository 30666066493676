import { Badge } from 'antd';
import { BadgeProps } from 'antd/es/badge';
import classNames from 'classnames';
import React from 'react';

import { themePalette } from 'ant/theme';

import styles from './UiBadge.scss';

export enum BadgeColors {
  Primary,
  Grey,
  Negative,
  Warning,
}

const themeColorsMap: Record<BadgeColors, BadgeProps['color']> = {
  [BadgeColors.Primary]: themePalette.colorTextPrimary,
  [BadgeColors.Grey]: themePalette.colorIcon,
  [BadgeColors.Negative]: themePalette.colorNegative,
  [BadgeColors.Warning]: themePalette.colorWarning,
};

type UiBadgeProps = Omit<BadgeProps, 'color'> & {
  color?: BadgeColors;
};

export const UiBadge: React.FC<UiBadgeProps> = ({ color = BadgeColors.Grey, className, ...props }) => {
  return (
    <Badge
      {...props}
      style={{ backgroundColor: themeColorsMap[color] }}
      className={classNames(styles.uiBadge, className)}
    />
  );
};
