import React from 'react';

import { LayoutItem } from '../../../types/layoutItem';
import { Widget } from '../../../types/widget';
import { getSize } from '../helpers';

export interface WidgetsIframeProps {
  className?: string;
  style?: Record<string, string>;
  layoutItem: LayoutItem;
  widget?: Widget;
  moduleEnvironment?: Record<string, string>;
}

export const WidgetsIframe: React.FC<WidgetsIframeProps> = ({ layoutItem, widget }) => {
  const size = getSize(layoutItem);
  const id = `layoutItemId-${widget?.id}`;

  return (
    <iframe
      id={id}
      title="Widget"
      src={widget?.url || ''}
      scrolling="no"
      frameBorder="no"
      width={size?.width}
      height={size?.height}
    />
  );
};

export default WidgetsIframe;
