import { AxiosError } from 'axios';
import { createEffect } from 'effector';

import { abstractStorageFactory } from 'ant/helpers/storage/abstract-storage-factory';
import { DictPaginated } from 'ant/types/api';
import { Gamification } from 'profile-frontend/typings/gamification';

import { createThanks, CreateThanksParams } from './api';
import { GamificationEndpoints } from './endpoints';

import TransactionHistory = Gamification.TransactionHistory;

export type GetGamificationStorageParams = { userId: string };

export const addThanksEffect = createEffect<CreateThanksParams, Gamification.AddedThank, AxiosError>({
  handler: (params) =>
    createThanks<Gamification.AddedThank>(params).then((response) => {
      return response.data;
    }),
});

export const getGamificationUserThanksSummaryStorage = ({ userId }: GetGamificationStorageParams) => {
  const storage = abstractStorageFactory<
    Gamification.ThanksSummary[],
    Gamification.ThanksSummary[],
    Gamification.ThanksSummary[]
  >({
    endpointBuilder: () => GamificationEndpoints.userThanksSummary(userId),
    defaultValue: [],
    cancelPendingRequestOnFetch: true,
  });

  const { refetchWithLastParams } = storage;

  const createThanksEffect = createEffect<CreateThanksParams, Gamification.AddedThank, AxiosError>({
    handler: (params) =>
      createThanks<Gamification.AddedThank>(params).then((response) => {
        refetchWithLastParams();
        return response.data;
      }),
  });

  return { storage, createThanksEffect };
};

export const getGamificationUserBadgesSummaryStorage = ({ userId }: GetGamificationStorageParams) => {
  const storage = abstractStorageFactory<
    Gamification.BadgesSummary[],
    Gamification.BadgesSummary[],
    Gamification.BadgesSummary[]
  >({
    endpointBuilder: () => GamificationEndpoints.userBadgesSummary(userId),
    defaultValue: [],
    cancelPendingRequestOnFetch: true,
  });

  return { storage };
};

export const getGamificationUserBadgesStorage = ({ userId }: GetGamificationStorageParams) => {
  const storage = abstractStorageFactory<
    DictPaginated<Gamification.Badges>,
    DictPaginated<Gamification.Badges>,
    null
  >({
    endpointBuilder: () => GamificationEndpoints.userBadges(userId),
    defaultValue: null,
    cancelPendingRequestOnFetch: true,
  });

  return { storage };
};

export const getGamificationUserThanksStorage = ({ userId }: GetGamificationStorageParams) => {
  const storage = abstractStorageFactory<
    DictPaginated<Gamification.Thanks>,
    DictPaginated<Gamification.Thanks>,
    null
  >({
    endpointBuilder: () => GamificationEndpoints.userThanks(userId),
    defaultValue: null,
    cancelPendingRequestOnFetch: true,
  });

  return { storage };
};

export const getGamificationThanksStorage = () => {
  const storage = abstractStorageFactory<
    Gamification.ThanksBase[],
    Gamification.ThanksBase[],
    Gamification.ThanksBase[]
  >({
    endpointBuilder: () => GamificationEndpoints.thanks(),
    defaultValue: [],
    cancelPendingRequestOnFetch: true,
  });

  return { storage };
};

export const getGamificationWalletStorage = ({ userId }: GetGamificationStorageParams) => {
  const storage = abstractStorageFactory<Gamification.Wallet, Gamification.Wallet, null>({
    endpointBuilder: () => GamificationEndpoints.userWallet(userId),
    defaultValue: null,
    cancelPendingRequestOnFetch: true,
  });

  return { storage };
};

export const getGamificationTransactionHistory = () => {
  const storage = abstractStorageFactory<TransactionHistory[], TransactionHistory[], TransactionHistory[]>({
    endpointBuilder: () => GamificationEndpoints.transactionHistory(),
    defaultValue: [],
    cancelPendingRequestOnFetch: true,
  });

  return { storage };
};

type SingleThanksStorageParams = {
  entityId: string | number;
};

export const getSingleThankStorage = () => {
  const storage = abstractStorageFactory<
    Gamification.AddedThank,
    Gamification.AddedThank,
    null,
    SingleThanksStorageParams
  >({
    endpointBuilder: ({ entityId }) => GamificationEndpoints.gamificationThanks(entityId),
    defaultValue: null,
    cancelPendingRequestOnFetch: true,
  });

  return { storage };
};

export type GamificationUserThanksSummaryStorage = ReturnType<typeof getGamificationUserThanksSummaryStorage>;
export type GamificationUserBadgesSummaryStorage = ReturnType<typeof getGamificationUserBadgesSummaryStorage>;
export type GamificationUserBadgesStorage = ReturnType<typeof getGamificationUserBadgesStorage>;
export type GamificationUserThanksStorage = ReturnType<typeof getGamificationUserThanksStorage>;
export type GamificationThanksStorage = ReturnType<typeof getGamificationThanksStorage>;
export type GamificationWalletStorage = ReturnType<typeof getGamificationWalletStorage>;
export type GamificationTransactionHistory = ReturnType<typeof getGamificationTransactionHistory>;
