import { useEffect } from 'react';

type UiScrollbars = {
  scrollTop: (top: number) => void;
  scrollLeft: (left: number) => void;
};

export function useInitialScrollTop(custom?: UiScrollbars | null, deps: any[] = []) {
  useEffect(() => {
    if (custom) {
      custom.scrollTop(0);
      custom.scrollLeft(0);
    } else {
      window.scrollTo(0, 0);
    }
  }, deps);
}
