import classNames from 'classnames';
import noop from 'lodash/noop';
import React from 'react';

import UploadSvg from 'ant/components/svg/upload.svg';
import { UiIcon } from 'ant/components/ui/icon/UiIcon';
import { UiTypography } from 'ant/components/ui/typography';

import styles from './UiButtonOverlay.scss';

export type UiButtonOverlayProps = {
  label?: string;
  children: React.ReactNode;
  onClick?: () => void;
  isVisible?: boolean;
  visibleOnHover?: boolean;
  wrapperClassName?: string;
};

const UiButtonOverlay: React.FC<UiButtonOverlayProps> = (props) => {
  const { label, children, visibleOnHover, onClick = noop, isVisible, wrapperClassName } = props;

  return (
    <button
      type="button"
      onClick={onClick}
      className={classNames(styles.uiButtonOverlay, {
        [styles.uiButtonOverlay__visible_hover]: Boolean(visibleOnHover),
      })}
    >
      {children}

      {isVisible && (
        <span
          className={classNames(styles.uiButtonOverlay__wrapper, wrapperClassName, {
            [styles.uiButtonOverlay__wrapperVisible_hover]: Boolean(visibleOnHover),
          })}
        >
          <UiIcon component={UploadSvg} width={20} height={20} />
          {label && (
            <UiTypography.Text className={styles.uiButtonOverlay__wrapperLabel}>{label}</UiTypography.Text>
          )}
        </span>
      )}
    </button>
  );
};

export { UiButtonOverlay };
