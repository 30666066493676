import React from 'react';

import CheckSvg from 'ant/components/svg/check.svg';
import { UiIcon } from 'ant/components/ui/icon/UiIcon';
import { UiTypography } from 'ant/components/ui/typography';
import { Gamification } from 'profile-frontend/typings/gamification';

import ItemCoins from '../list/item/coins/ItemCoins';
import styles from './GamificationBadgePost.scss';

type Props = {
  badges: Gamification.Badges;
};

const GamificationBadgePost: React.FC<Props> = ({ badges }) => {
  const { badge, completedActions } = badges;

  return (
    <>
      <img src={badge.image} alt="" className={styles.gamificationBadgePost__img} />
      <span className={styles.gamificationBadgePost__wrapper}>
        <UiTypography.Title level={2} style={{ marginBottom: 8 }}>
          {badge.name}
        </UiTypography.Title>

        {completedActions.map(
          (action) =>
            action.description && (
              <span key={action.event} className={styles.gamificationBadgePost__action}>
                <UiIcon
                  width={20}
                  height={20}
                  component={CheckSvg}
                  className={styles.gamificationBadgePost__actionIcon}
                />
                <UiTypography.Text className={styles.gamificationBadgePost__actionName}>
                  {action.description}
                </UiTypography.Text>
              </span>
            ),
        )}

        {badge.description && (
          <UiTypography.Text className={styles.gamificationBadgePost__desc}>
            {badge.description}
          </UiTypography.Text>
        )}

        {badge.coins > 0 && <ItemCoins coins={badge.coins} />}
      </span>
    </>
  );
};

export default GamificationBadgePost;
