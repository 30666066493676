import React from 'react';
import classNames from 'classnames';
import { UiIcon } from 'ant/components/ui/icon/UiIcon';
import { UiTypography } from 'ant/components/ui/typography';
import CoinSvg from 'profile-frontend/components/svg/coin.svg';

import styles from './ItemCoins.scss';

type Props = {
  coins: number;
  className?: string;
};

const ItemCoins: React.FC<Props> = ({ coins, className }) => (
  <span className={classNames(styles.itemCoins, className)}>
    <UiIcon width={20} height={20} component={CoinSvg} />
    <UiTypography.Text type="secondary" className={styles.itemCoins__count}>
      {coins}
    </UiTypography.Text>
  </span>
);

export default ItemCoins;
