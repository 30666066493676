import { Table as AntTable } from 'antd';
import {
  ColumnsType as AntTableColumnsType,
  TableProps as AntTableProps,
  TablePaginationConfig as AntTablePaginationConfig,
} from 'antd/es/table';

export const UiTable = AntTable;
export type ColumnsType<T> = AntTableColumnsType<T>;
export type TableProps<T extends object = any> = AntTableProps<T>;

export type TableDataCells = Record<string, React.ReactNode>;

export type TableData = {
  key: string | number;
} & TableDataCells;

export type TablePaginationConfig = AntTablePaginationConfig;
