import classNames from 'classnames';
import React from 'react';

import { UiSkeleton } from 'ant/components/ui/skeleton';
import { UiTypography } from 'ant/components/ui/typography';
import { useAbstractStorage } from 'ant/helpers/hooks/use-abstract-storage';
import { Gender } from 'ant/types/models/user';
import { profileFullInfoStorage } from 'profile-frontend/storage';
import { Gamification } from 'profile-frontend/typings/gamification';
import { ProfileInfo } from 'profile-frontend/typings/profile-info';

import styles from './GamificationEmpty.scss';

const SHOW_OWNER_BADGES = false; // TODO: Раздел в разработке

type GamificationEmptyProps = {
  isOwner?: boolean;
  className?: string;
  type: Gamification.ItemTypes;
  toggleAddThanksOpen?: () => void;
};

const GamificationEmpty: React.FC<GamificationEmptyProps> = (props) => {
  const { isOwner, type, className, toggleAddThanksOpen } = props;
  const showOwnerBadges = isOwner && type === Gamification.ItemTypes.Badges;
  const showOwnerThanks = isOwner && type === Gamification.ItemTypes.Thanks;
  const showVisitorThanks = !isOwner && type === Gamification.ItemTypes.Thanks;

  const { data: profileFullInfoData, loading } = useAbstractStorage(profileFullInfoStorage.storage);

  const { main } = profileFullInfoData || ({} as ProfileInfo);
  const { fullName, gender } = main || ({} as ProfileInfo);

  const gettingWithGender = gender === Gender.Female ? 'получала' : 'получал';
  const visitorThanksDescription = `${fullName?.firstName} ещё не ${gettingWithGender} благодарностей.`;

  return (
    <UiTypography.Text type="secondary" className={classNames(styles.gamificationEmpty, className)}>
      {showOwnerThanks && 'У вас ещё нет благодарностей'}

      {showOwnerBadges && (
        <>
          Здесь ещё ничего нет
          {SHOW_OWNER_BADGES && (
            <>
              , но это легко поправить:&nbsp;
              {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
              <UiTypography.Link strong>посмотрите</UiTypography.Link>, за что можно получить достижения
            </>
          )}
        </>
      )}

      {showVisitorThanks && (
        <UiSkeleton loading={loading} count={2} width="100%">
          {visitorThanksDescription}
          <br />
          <UiTypography.Link strong onClick={toggleAddThanksOpen}>
            Поблагодарить
          </UiTypography.Link>
        </UiSkeleton>
      )}
    </UiTypography.Text>
  );
};

export default GamificationEmpty;
