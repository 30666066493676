import { Drawer } from 'antd';
import { DrawerProps as AntDrawerProps } from 'antd/es/drawer';
import React from 'react';

import CloseSVG from 'ant/components/svg/close.svg';
import { UiIcon } from 'ant/components/ui/icon/UiIcon';

import styles from './UiDrawer.scss';

export interface UiDrawerProps extends Omit<AntDrawerProps, 'closeIcon'> {
  withCustomCloseIcon?: boolean;
}

export const UiDrawer: React.FC<UiDrawerProps> = ({ withCustomCloseIcon, ...restProps }) => (
  <Drawer
    className={styles.uiDrawer}
    closeIcon={
      withCustomCloseIcon ? (
        <UiIcon className={styles.uiDrawer__closeIcon} width={20} height={20} component={CloseSVG} />
      ) : null
    }
    {...restProps}
  />
);
