import { InputProps } from 'antd/es/input';
import classNames from 'classnames';
import noop from 'lodash/noop';
import React, { useCallback, useMemo } from 'react';

import AddSvg from 'ant/components/svg/add.svg';
import RemoveSvg from 'ant/components/svg/remove.svg';
import { UiButton } from 'ant/components/ui/button/UiButton';
import { UiInput } from 'ant/components/ui/input/UiInput';

import styles from './UiInputNumber.scss';

export interface UiInputNumberProps extends Omit<InputProps, 'onChange'> {
  value?: number;
  step?: number;
  min?: number;
  onChange?: (value: number) => void;
}

const getNumberCountsAfterPoint = (number: number) => {
  const partWithNumbersAfterPoint = String(number).replace(/(^[0-9]*(.))/, '');

  return partWithNumbersAfterPoint.length;
};

const PATTERN = {
  positive: '(^0$)|(^[+]?[0-9]*([.][0-9]*)?$)',
  negative: '(^[-][0-9]*([.][0-9]*)?$)',
  common: '(^0$)|(^[-+]?[0-9]*([.][0-9]*)?$)',
  negativeWithZero: '(^0$)|([-][0-9]*([.][0-9]*)?$)',
};

export const UiInputNumber: React.FC<UiInputNumberProps> = (props) => {
  const {
    className,
    value = 0,
    step = 1,
    min = 0,
    max,
    disabled,
    onChange = noop,
    pattern,
    ...otherProps
  } = props;

  let inputPattern;

  if (!pattern) {
    inputPattern = PATTERN.common;

    if (min >= 0) {
      inputPattern = PATTERN.positive;
    }

    if (Number(max) <= 0) {
      inputPattern = PATTERN.negativeWithZero;
    }

    if (Number(max) < 0) {
      inputPattern = PATTERN.negative;
    }
  } else {
    inputPattern = pattern;
  }

  const disabledAddonAfter = (max !== undefined && value >= max) || disabled;
  const numberCountsAfterPoint = useMemo(() => getNumberCountsAfterPoint(step), [step]);

  const onDecrement = useCallback(() => {
    onChange(Number((value - step).toFixed(numberCountsAfterPoint)));
  }, [value, step, numberCountsAfterPoint]);
  const onIncrement = useCallback(() => {
    onChange(Number((value + step).toFixed(numberCountsAfterPoint)));
  }, [value, step, numberCountsAfterPoint]);

  const onChangeHandler = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const valueFromEvent = e.target.value;
      const isValid = e.target.validity.valid && valueFromEvent !== '-.';
      let newValue: string | number = min;

      if (isValid) {
        newValue = valueFromEvent;

        if (Number(valueFromEvent) < min) {
          newValue = min;
        }

        if (max !== undefined && Number(valueFromEvent) > max) {
          newValue = max;
        }
      }

      onChange(newValue);
    },
    [min, max, onChange],
  );
  const onBlurHandler = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = Number(e.target.value);
      const isValid = e.target.validity.valid && !Number.isNaN(newValue);

      onChange(isValid ? newValue : min);
    },
    [min, onChange],
  );

  const AddonBefore = (
    <UiButton
      type="link-secondary"
      disabled={disabled || value === min}
      onClick={onDecrement}
      icon={<RemoveSvg />}
    />
  );

  const AddonAfter = (
    <UiButton type="link-secondary" disabled={disabledAddonAfter} onClick={onIncrement} icon={<AddSvg />} />
  );

  return (
    <UiInput
      {...otherProps}
      min={min}
      max={max}
      type="text"
      value={value}
      pattern={inputPattern}
      disabled={disabled}
      onChange={onChangeHandler}
      onBlur={onBlurHandler}
      addonBefore={AddonBefore}
      addonAfter={AddonAfter}
      className={classNames(styles.uiInputNumber, className)}
    />
  );
};
