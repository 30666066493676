// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._-_-_-profile-frontend-src-components-gamification-thank-post--gamification-thank-post__avatars{width:248px;height:120px;display:flex;align-items:center;justify-content:space-between}._-_-_-profile-frontend-src-components-gamification-thank-post--gamification-thank-post__avatars-achievement{z-index:2;position:absolute;margin-left:64px;display:flex;justify-content:center;align-items:center}._-_-_-profile-frontend-src-components-gamification-thank-post--gamification-thank-post__msg{font-weight:400;text-align:center}._-_-_-profile-frontend-src-components-gamification-thank-post--gamification-thank-post__coins{margin-top:16px}\n", ""]);
// Exports
exports.locals = {
	"gamification-thank-post__avatars": "_-_-_-profile-frontend-src-components-gamification-thank-post--gamification-thank-post__avatars",
	"gamificationThankPost__avatars": "_-_-_-profile-frontend-src-components-gamification-thank-post--gamification-thank-post__avatars",
	"gamification-thank-post__avatars-achievement": "_-_-_-profile-frontend-src-components-gamification-thank-post--gamification-thank-post__avatars-achievement",
	"gamificationThankPost__avatarsAchievement": "_-_-_-profile-frontend-src-components-gamification-thank-post--gamification-thank-post__avatars-achievement",
	"gamification-thank-post__msg": "_-_-_-profile-frontend-src-components-gamification-thank-post--gamification-thank-post__msg",
	"gamificationThankPost__msg": "_-_-_-profile-frontend-src-components-gamification-thank-post--gamification-thank-post__msg",
	"gamification-thank-post__coins": "_-_-_-profile-frontend-src-components-gamification-thank-post--gamification-thank-post__coins",
	"gamificationThankPost__coins": "_-_-_-profile-frontend-src-components-gamification-thank-post--gamification-thank-post__coins"
};
module.exports = exports;
