import { AxiosError } from 'axios';
import { createEffect } from 'effector';

import { abstractStorageFactory } from 'ant/helpers/storage/abstract-storage-factory';
import { buildEndpointWithQueryParams } from 'ant/plugins/utils/endpoint-builder';
import { ProfileEndpoints } from 'profile-frontend/store/skills/endpoints';
import { UserSkill } from 'profile-frontend/typings/skills';

import {
  approveSkill,
  ApproveSkillParams,
  saveSkill,
  SaveSkillParams,
  deleteSkill,
  DeleteSkillParams,
  GetSkillsStorageParams,
} from './api';

export const getSkillsStorage = ({ userId }: GetSkillsStorageParams) => {
  const storage = abstractStorageFactory<UserSkill[], UserSkill[], UserSkill[]>({
    endpointBuilder: () =>
      buildEndpointWithQueryParams(ProfileEndpoints.skills(userId), { ordering: '-count' }),
    defaultValue: [],
    cancelPendingRequestOnFetch: true,
    dataMapper: (skills) => skills,
  });

  const { refetchWithLastParams } = storage;

  const approveSkillEffect = createEffect<ApproveSkillParams, unknown, AxiosError>((params) =>
    approveSkill({ userId, ...params }).then(refetchWithLastParams),
  );

  const saveSkillEffect = createEffect<SaveSkillParams, unknown, AxiosError>((params) =>
    saveSkill({ userId, ...params }).then(refetchWithLastParams),
  );

  const deleteSkillEffect = createEffect<DeleteSkillParams, unknown, AxiosError>(({ skillId }) =>
    deleteSkill({ userId, skillId }).then(refetchWithLastParams),
  );

  return {
    storage,
    saveSkillEffect,
    deleteSkillEffect,
    approveSkillEffect,
  };
};

export type SkillsStorage = ReturnType<typeof getSkillsStorage>;
