import get from 'lodash/get';

import { UiOptionData, UiOptionItem } from 'ant/components/ui/select';
import { createArrayMock } from 'ant/plugins/createMocks';
import { OptionModel } from 'ant/types/models/option';

/**
 * Схема
 *
 * @param valuePath - путь до значения через точку
 * @param labelPath - путь до label через точку
 */
export type OptionsScheme = { valuePath: string; labelPath: string };

// TODO выпилить и передавать явно всегда
export const DEFAULT_OPTION_SCHEME = {
  valuePath: 'id',
  labelPath: 'attributes.name',
};

/**
 * Функция генерации Options для UiSelect инпута
 * @param arr - Массив элеметов
 * @param scheme - Схема
 */
export const generateSelectOptions = <T extends object>(
  arr: T[],
  scheme: OptionsScheme = DEFAULT_OPTION_SCHEME,
): UiOptionItem[] =>
  arr.map((item) => ({ value: get(item, scheme.valuePath), label: get(item, scheme.labelPath) }));

/**
 * Функция генерации Options для UiAutoComplete инпута
 * @param arr - Массив элеметов
 * @param scheme - Схема
 */
export const generateAutoCompleteOptions = <T extends object>(
  arr: T[],
  scheme: OptionsScheme = DEFAULT_OPTION_SCHEME,
): UiOptionData[] =>
  arr.map((item) => ({
    value: get(item, scheme.labelPath),
    label: get(item, scheme.labelPath),
    key: get(item, scheme.valuePath),
  }));

/**
 * Функция генерации одного Option для UiMultiSelect инпута
 * @param item - Элемент
 * @param scheme - Схема
 */
export const generateMultiSelectSingleOption = <T extends object>(
  item: T,
  scheme: OptionsScheme = DEFAULT_OPTION_SCHEME,
): OptionModel => ({
  value: get(item, scheme.valuePath),
  data: { label: get(item, scheme.labelPath), selectedLabel: get(item, scheme.labelPath) },
});

/**
 * Функция генерации Options для UiMultiSelect инпута
 * @param arr - Массив элеметов
 * @param scheme - Схема
 */
export const generateMultiSelectOptions = <T extends object>(
  arr: T[],
  scheme: OptionsScheme = DEFAULT_OPTION_SCHEME,
): OptionModel[] => arr.map((item) => generateMultiSelectSingleOption(item, scheme));

type GenerateDateOptionsParams = {
  start: number;
  end: number;
};

/**
 * Функция генерации Options с годами для UiSelect
 * @param start - начальный год
 * @param end - последний год
 */
export const generateYearsOptions = ({ start, end }: GenerateDateOptionsParams): UiOptionItem[] =>
  generateSelectOptions(
    createArrayMock(end - start, (_, index) => {
      const yearValue = index + start;

      return {
        id: yearValue.toString(),
      };
    }),
    { valuePath: 'id', labelPath: 'id' },
  );
