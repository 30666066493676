import React, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import classNames from 'classnames';

import styles from './UiModalContent.scss';

const ccn = classNames.bind(styles);

export interface UiModalContentProps {
  className?: string;
  onHeightChange?: (height: number) => void;
  minHeight?: number;
}

export type UiModalContentComponent = React.FC<UiModalContentProps>;

export const UiModalContent: UiModalContentComponent = (props) => {
  const { children, minHeight, onHeightChange, className } = props;

  const contentWrapperRef = useRef<HTMLDivElement>(null);

  const [height, setHeight] = useState<number | undefined>(minHeight || 0);

  useEffect(() => {
    if (onHeightChange && height) {
      onHeightChange(height);
    }
  }, [height]);

  useLayoutEffect(() => {
    const updateHeight = () => {
      setHeight(contentWrapperRef.current?.clientHeight);
    };

    if (contentWrapperRef.current && onHeightChange) {
      window.addEventListener('resize', updateHeight);

      updateHeight();
    }

    return () => window.removeEventListener('resize', updateHeight);
  }, [contentWrapperRef.current]);

  const modalContentClassName = ccn(styles.uiModalContent, className);

  return (
    <div ref={contentWrapperRef} className={modalContentClassName}>
      {children}
    </div>
  );
};
