// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._-_-_-ant-src-components-ui-modals-modal-content--ui-modal-content{overflow-y:auto;flex:1}\n", ""]);
// Exports
exports.locals = {
	"ui-modal-content": "_-_-_-ant-src-components-ui-modals-modal-content--ui-modal-content",
	"uiModalContent": "_-_-_-ant-src-components-ui-modals-modal-content--ui-modal-content"
};
module.exports = exports;
