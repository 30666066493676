// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._-_-_-profile-frontend-src-components-profile-sidebar-info-name--info-full-name{display:flex;flex-direction:column;margin:12px 0 20px;text-align:center}._-_-_-profile-frontend-src-components-profile-sidebar-info-name--info-full-name__title{display:block}._-_-_-profile-frontend-src-components-profile-sidebar-info-name--info-full-name__sub-title{display:block}\n", ""]);
// Exports
exports.locals = {
	"info-full-name": "_-_-_-profile-frontend-src-components-profile-sidebar-info-name--info-full-name",
	"infoFullName": "_-_-_-profile-frontend-src-components-profile-sidebar-info-name--info-full-name",
	"info-full-name__title": "_-_-_-profile-frontend-src-components-profile-sidebar-info-name--info-full-name__title",
	"infoFullName__title": "_-_-_-profile-frontend-src-components-profile-sidebar-info-name--info-full-name__title",
	"info-full-name__sub-title": "_-_-_-profile-frontend-src-components-profile-sidebar-info-name--info-full-name__sub-title",
	"infoFullName__subTitle": "_-_-_-profile-frontend-src-components-profile-sidebar-info-name--info-full-name__sub-title"
};
module.exports = exports;
