import { createBrowserHistory } from 'history';
import React, { useEffect, useMemo } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Redirect, Route, Router as BrowserRouter, Switch } from 'react-router-dom';

import { UiSpinner } from 'ant/components/ui/spinner';
import { useAbstractStorage } from 'ant/helpers/hooks/use-abstract-storage';
import { api } from 'ant/plugins/api';
import authService from 'ant/plugins/authService';
import currentProfileService from 'ant/plugins/currentProfileService';
import { sendNativeAppEvent } from 'ant/plugins/native-app-bridge@myteam';
import { getRoutePath, RouteNames } from 'ant/plugins/routes';
import { buildRoutesFromConfig } from 'ant/plugins/routes-builder';

import styles from './Router.scss';
import { pagesMap } from './pages';

import { useQueryConfig } from '~/components/routes/router@myteam/query-config';
import { getMyteamUserIdByAimsidStorage } from '~/store/user-id@myteam';

const history = createBrowserHistory();

export const Router: React.FC = () => {
  const { aimsid } = useQueryConfig();

  const userIdByAimsidStorage = useMemo(() => getMyteamUserIdByAimsidStorage({ aimsid }), [aimsid]);

  const { data: currentUserId, loading, error } = useAbstractStorage(userIdByAimsidStorage.storage, {
    autoFetchAndRefetch: true,
  });

  useEffect(() => {
    aimsid && api.setAdditionalParams({ aimsid });

    if (currentUserId) {
      authService.setCurrentUserId(String(currentUserId));
      currentProfileService.currentProfileStorage.fetchEffect({ userId: String(currentUserId) });
    }
  }, [currentUserId]);

  useEffect(() => {
    if (error) {
      sendNativeAppEvent('initLoadingCompleted', { success: false });
    }
  }, [error]);

  if (loading || !currentUserId) {
    return <UiSpinner className={styles.appRouter} />;
  }

  return (
    <BrowserRouter history={history}>
      <Switch>
        <DndProvider backend={HTML5Backend}>{buildRoutesFromConfig(pagesMap)}</DndProvider>
        <Route>
          <Redirect to={getRoutePath(RouteNames.OrgStructureRoot)} />
        </Route>
      </Switch>
    </BrowserRouter>
  );
};
