// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._-_-_-profile-frontend-src-components-profile-sidebar-info-items-info-item--info-item{display:flex;flex-direction:column;align-items:flex-start}._-_-_-profile-frontend-src-components-profile-sidebar-info-items-info-item--info-item:not(:first-child){margin-top:12px}._-_-_-profile-frontend-src-components-profile-sidebar-info-items-info-item--info-item__content{margin-top:2px}\n", ""]);
// Exports
exports.locals = {
	"info-item": "_-_-_-profile-frontend-src-components-profile-sidebar-info-items-info-item--info-item",
	"infoItem": "_-_-_-profile-frontend-src-components-profile-sidebar-info-items-info-item--info-item",
	"info-item__content": "_-_-_-profile-frontend-src-components-profile-sidebar-info-items-info-item--info-item__content",
	"infoItem__content": "_-_-_-profile-frontend-src-components-profile-sidebar-info-items-info-item--info-item__content"
};
module.exports = exports;
