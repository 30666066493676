import React from 'react';
import { useParams } from 'react-router-dom';

import authService from 'ant/plugins/authService';
import { ProfileInfo as ProfileInfoType } from 'profile-frontend/typings/profile-info';

import styles from './ProfileInfo.scss';
import InfoBiography from './biography/InfoBiography';
import InfoConfidential from './confidential/InfoConfidential';
import InfoReferences from './references/InfoReferences';
import InfoReferencesProjectsDefault, { ProjectsProps } from './references/InfoReferencesProjects@default';

type ProfileInfoProps = {
  userData: ProfileInfoType;
  InfoReferencesProjects?: React.FC<ProjectsProps>;
};

const ProfileInfo: React.FC<ProfileInfoProps> = (props) => {
  const { id } = useParams<{ id: string }>();

  const isOwner = id === authService.getCurrentUserId();

  const { userData, InfoReferencesProjects = InfoReferencesProjectsDefault } = props;

  return (
    <div className={styles.profileInfo}>
      <InfoReferences userData={userData} InfoReferencesProjects={InfoReferencesProjects} />

      <div className={styles.profileInfo__separator} />

      <InfoBiography userData={userData} />

      {isOwner && (
        <>
          <div className={styles.profileInfo__separator} />
          <InfoConfidential userData={userData} />
        </>
      )}
    </div>
  );
};

export default ProfileInfo;
