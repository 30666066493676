import { Button as AntButton } from 'antd';
import { ButtonProps } from 'antd/es/button/button';
import classNames from 'classnames';
import React from 'react';

import styles from './UiButton.scss';

declare const ButtonTypes: [
  'primary',
  'secondary',
  'tertiary',
  'tertiary-transparent',
  'link',
  'link-secondary',
  'default',
];
export declare type ButtonType = typeof ButtonTypes[number];

enum BtnType {
  Primary = 'primary',
  Secondary = 'secondary',
  Tertiary = 'tertiary',
  TertiaryTransparent = 'tertiary-transparent',
  Link = 'link',
  LinkSecondary = 'link-secondary',
}

export interface UiButtonProps extends Omit<ButtonProps, 'type'> {
  label?: string;
  disabledFocus?: boolean;
  type?: ButtonType;
  round?: boolean;
}

export const UiButton: React.FC<UiButtonProps> = ({
  label,
  type,
  children,
  className,
  disabledFocus,
  onMouseDown,
  round,
  ...props
}) => {
  const isActive = (key: string) => type === key;

  const classNameStyles = classNames(
    styles.uiButton,
    {
      [styles.uiButton__primary]: isActive(BtnType.Primary),
      [styles.uiButton__secondary]: isActive(BtnType.Secondary),
      [styles.uiButton__tertiary]: isActive(BtnType.Tertiary) || isActive(BtnType.TertiaryTransparent),
      [styles.uiButton__tertiary_transparent]: isActive(BtnType.TertiaryTransparent),
      [styles.uiButton__link]: isActive(BtnType.Link) || isActive(BtnType.LinkSecondary),
      [styles.uiButton__linkSecondary]: isActive(BtnType.LinkSecondary),
      [styles.uiButton_round]: round,
    },
    className,
  );

  const onMouseDownHandler = (e: React.MouseEvent<unknown, MouseEvent>) => {
    if (disabledFocus && e?.preventDefault) e?.preventDefault();

    if (onMouseDown) onMouseDown(e);
  };

  return (
    <AntButton className={classNameStyles} {...props} onMouseDown={onMouseDownHandler}>
      {children || label}
    </AntButton>
  );
};
