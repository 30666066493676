import Icon from '@ant-design/icons';
import React, { useMemo } from 'react';

export type UiIconProps = React.ComponentProps<typeof Icon>;

export const UiIcon: React.FC<UiIconProps> = (props) => {
  const { width = '1em', height = '1em', component: Component } = props;
  const CustomComponent = useMemo(
    /** @ts-ignore */ // TODO разобраться какой надо установиться fill по умолчанию, fill="none" или "currentColor" ломает иконки
    () => (Component ? () => <Component width={width} height={height} /> : undefined),
    [Component, width, height],
  );

  return <Icon {...props} component={CustomComponent} />;
};
