// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._-_-_-ant-src-components-ui-truncate-markup--truncate-markup{word-break:break-word;white-space:normal}._-_-_-ant-src-components-ui-truncate-markup--truncate-markup>*{display:inline-block}\n", ""]);
// Exports
exports.locals = {
	"truncate-markup": "_-_-_-ant-src-components-ui-truncate-markup--truncate-markup",
	"truncateMarkup": "_-_-_-ant-src-components-ui-truncate-markup--truncate-markup"
};
module.exports = exports;
