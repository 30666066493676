import { abstractStorageFactory } from 'ant/helpers/storage/abstract-storage-factory';
import { buildEndpointWithQueryParams } from 'ant/plugins/utils/endpoint-builder';

export type GetAimsidStorageParams = { aimsid?: string };
interface GetUserIdResponse {
  result: {
    userId: string;
    keycloakId: string;
  };
}

export const userIdProfileEndpoint = '/profile/get_user_id/';

export const getMyteamUserIdByAimsidStorage = ({ aimsid }: GetAimsidStorageParams) => {
  const storage = abstractStorageFactory<GetUserIdResponse, string, null>({
    endpointBuilder: () => buildEndpointWithQueryParams(userIdProfileEndpoint, { aimsid }),
    defaultValue: null,
    dataMapper: ({ result }) => result.keycloakId,
  });

  return { storage };
};

export type GetUserIdByAimsidStorage = ReturnType<typeof getMyteamUserIdByAimsidStorage>;
