import React from 'react';

import { useAbstractStorage } from 'ant/helpers/hooks/use-abstract-storage';

import { LayoutProps } from '~/components/layouts/component/base-layout/BaseLayout';
import { PageAdaptation } from '~/components/layouts/mail/adaptation/PageAdaptation';
import { PageAdvancedSearch } from '~/components/layouts/mail/advanced-search/PageAdvancedSearch';
import { PageMain } from '~/components/layouts/mail/main/PageMain';
import { PageProfileEdit } from '~/components/layouts/mail/profile-edit/PageProfileEdit';
import { PageProfile } from '~/components/layouts/mail/profile/PageProfile';
import { PageTasks } from '~/components/layouts/mail/tasks/PageTasks';
import { layoutStorage, populatedLayoutStorage } from '~/storage';

export enum Pages {
  Main = 'main',
  Profile = 'profile',
  ProfileEdit = 'profile-edit',
  Adaptation = 'adaptation',
  AdaptationGroup = 'adaptation-group-card',
  AdvancedSearch = 'advanced-search',
  Tasks = 'tasks',
}

export const PageLayouts: Record<Pages, React.ComponentType<LayoutProps>> = {
  [Pages.Main]: PageMain,
  [Pages.Profile]: PageProfile,
  [Pages.ProfileEdit]: PageProfileEdit,
  [Pages.Adaptation]: PageAdaptation,
  [Pages.AdaptationGroup]: PageAdaptation,
  [Pages.AdvancedSearch]: PageAdvancedSearch,
  [Pages.Tasks]: PageTasks,
};

type Props = { name: Pages };

const Page: React.FC<Props> = (props) => {
  const { name } = props;
  const Layout = PageLayouts[name];

  const { data: layoutData, loading: layoutDataLoading } = useAbstractStorage(layoutStorage.storage, {
    autoFetchAndRefetch: Boolean(name),
    autoFetchParams: {
      name,
    },
  });

  const { data: populatedLayout } = useAbstractStorage(populatedLayoutStorage.storage, {
    autoFetchAndRefetch: Boolean(layoutData?.id) && !layoutDataLoading,
    autoFetchParams: {
      layoutId: Number(layoutData?.id),
    },
  });

  return (
    <>
      {layoutData && Array.isArray(layoutData.layoutItems) && populatedLayout.length > 0 && (
        <Layout layoutItems={layoutData.layoutItems} populatedLayout={populatedLayout} />
      )}
    </>
  );
};

export default Page;
