import React, { useState } from 'react';

import { UiAvatar } from 'ant/components/ui/avatar';
import { UiButtonOverlay } from 'ant/components/ui/button-overlay';
import { UiModal, UiModalTypes } from 'ant/components/ui/modals';
import authService from 'ant/plugins/authService';
import { UserProfile } from 'ant/types/models/user';
import AvatarUploadModal from 'profile-frontend/components/profile/avatar/upload-modal/AvatarUploadModal';

type Props = {
  profileMain: UserProfile | undefined;
  loading: boolean;
  onAvatarUpdate: (src: string) => void;
  uploadBtnClassName?: string;
};

const containerStyles: React.CSSProperties = { height: 300 };

const ProfileAvatar: React.FC<Props> = ({ profileMain, loading, onAvatarUpdate, uploadBtnClassName }) => {
  const [isAvatarUpdate, setIsAvatarUpdate] = useState(false);

  const { avatar, id } = profileMain || {};

  const src = !loading && avatar;
  const isOwner = Boolean(id && id === authService.getCurrentUserId());

  const onCloseUpdate = () => setIsAvatarUpdate(false);

  const onOpenUpdate = () => {
    if (isOwner) {
      setIsAvatarUpdate(true);
    }
  };

  return (
    <>
      <UiButtonOverlay
        wrapperClassName={uploadBtnClassName}
        label="Обновить фото"
        isVisible={isOwner}
        visibleOnHover
        onClick={onOpenUpdate}
      >
        <UiAvatar shape="square" size={200} src={src} style={containerStyles} />
      </UiButtonOverlay>

      {isOwner && (
        <UiModal
          isMaskClosable={false}
          isOpen={isAvatarUpdate}
          type={UiModalTypes.Large}
          onClose={onCloseUpdate}
        >
          <AvatarUploadModal onClose={onCloseUpdate} onAvatarUpdate={onAvatarUpdate} />
        </UiModal>
      )}
    </>
  );
};

export default ProfileAvatar;
