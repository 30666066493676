// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._-_-_-ant-src-components-ui-scrollbars--ui-scrollbars__horisontal-track{right:2px;bottom:2px;left:2px;border-radius:3px}._-_-_-ant-src-components-ui-scrollbars--ui-scrollbars__horisontal-thumb{cursor:pointer;border-radius:inherit;background-color:rgba(0,0,0,0.2)}._-_-_-ant-src-components-ui-scrollbars--ui-scrollbars__vertical-track{right:2px;bottom:2px;top:2px;border-radius:3px}._-_-_-ant-src-components-ui-scrollbars--ui-scrollbars__vertical-thumb{cursor:pointer;border-radius:inherit;background-color:rgba(0,0,0,0.2)}\n", ""]);
// Exports
exports.locals = {
	"ui-scrollbars__horisontal-track": "_-_-_-ant-src-components-ui-scrollbars--ui-scrollbars__horisontal-track",
	"uiScrollbars__horisontalTrack": "_-_-_-ant-src-components-ui-scrollbars--ui-scrollbars__horisontal-track",
	"ui-scrollbars__horisontal-thumb": "_-_-_-ant-src-components-ui-scrollbars--ui-scrollbars__horisontal-thumb",
	"uiScrollbars__horisontalThumb": "_-_-_-ant-src-components-ui-scrollbars--ui-scrollbars__horisontal-thumb",
	"ui-scrollbars__vertical-track": "_-_-_-ant-src-components-ui-scrollbars--ui-scrollbars__vertical-track",
	"uiScrollbars__verticalTrack": "_-_-_-ant-src-components-ui-scrollbars--ui-scrollbars__vertical-track",
	"ui-scrollbars__vertical-thumb": "_-_-_-ant-src-components-ui-scrollbars--ui-scrollbars__vertical-thumb",
	"uiScrollbars__verticalThumb": "_-_-_-ant-src-components-ui-scrollbars--ui-scrollbars__vertical-thumb"
};
module.exports = exports;
