// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".layouts-mail-common-padded-block--common-layout-padded-block{padding:0 16px 16px}\n", ""]);
// Exports
exports.locals = {
	"common-layout-padded-block": "layouts-mail-common-padded-block--common-layout-padded-block",
	"commonLayoutPaddedBlock": "layouts-mail-common-padded-block--common-layout-padded-block"
};
module.exports = exports;
