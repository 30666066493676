import React from 'react';
import { UiSkeleton } from 'ant/components/ui/skeleton';

import styles from './ItemLoading.scss';

const ItemLoading = () => {
  return (
    <span className={styles.itemLoading}>
      <span className={styles.itemLoading__header}>
        <UiSkeleton circle loading width={40} height={40} />

        <span className={styles.itemLoading__headerInfo}>
          <UiSkeleton loading width={120} height={16} />
          <UiSkeleton loading width={240} height={18} />
        </span>
      </span>
      <span className={styles.itemLoading__body}>
        <UiSkeleton loading width="100%" height={180} />
      </span>
    </span>
  );
};

export default ItemLoading;
