import React, { useMemo } from 'react';

import FaqSvg from 'ant/components/svg/faq.svg';
import { UiIcon } from 'ant/components/ui/icon/UiIcon';
import { UiTooltip } from 'ant/components/ui/tooltip';
import { UiTruncateMarkup } from 'ant/components/ui/truncate-markup';
import { UiTypography } from 'ant/components/ui/typography';
import { ProfileInfo } from 'profile-frontend/typings/profile-info';

import styles from './InfoConfidential.scss';

const titleStyle = {
  marginBottom: 0,
};

type ConfidentialItemProps = {
  title: string;
  children: React.ReactNode;
};

type Props = {
  userData: ProfileInfo;
};

const InfoConfidential: React.FC<Props> = (props) => {
  const { userData } = props;
  const { biography, main } = userData;

  const { actualAddress } = main;
  const { education } = biography;

  const educationContent = useMemo(
    () =>
      education?.additional?.length > 0 && (
        <UiTypography.Text className={styles.infoConfidential__contentValue}>
          {education.additional
            .map(({ dateEnd, institution, specialization, qualification }) =>
              [institution, specialization, qualification, dateEnd].filter((value) => value).join(', '),
            )
            .join('\n')}
        </UiTypography.Text>
      ),
    [education],
  );

  const showInfoConfidential = actualAddress || educationContent;

  const ConfidentialItem = ({ title, children }: ConfidentialItemProps) => (
    <div className={styles.infoConfidential__wrapper} key={title}>
      <UiTypography.Text className={styles.infoConfidential__itemTitle} type="secondary">
        {title}
      </UiTypography.Text>
      {children}
    </div>
  );

  const restrictedVisibilityTooltipMessage = (
    <UiTypography.Text className={styles.infoConfidential__tooltip}>
      Этот блок виден только вам и вашему HR
    </UiTypography.Text>
  );

  return showInfoConfidential ? (
    <div className={styles.infoConfidential}>
      <span className={styles.infoConfidential__title}>
        <UiTypography.Title level={3} style={titleStyle}>
          Скрытые данные
        </UiTypography.Title>
        <UiTooltip title={restrictedVisibilityTooltipMessage}>
          <UiIcon
            component={FaqSvg}
            width={20}
            height={20}
            className={styles.infoConfidential__tooltipIcon}
          />
        </UiTooltip>
      </span>

      <div className={styles.infoConfidential__content}>
        {actualAddress && (
          <ConfidentialItem title="Адрес проживания">
            <UiTypography.Text>
              <UiTruncateMarkup truncate>{actualAddress}</UiTruncateMarkup>
            </UiTypography.Text>
          </ConfidentialItem>
        )}
        {educationContent && <ConfidentialItem title="Образование">{educationContent}</ConfidentialItem>}
      </div>
    </div>
  ) : null;
};

export default InfoConfidential;
