import { camelizeKeys } from 'humps';
import qs, { ParsedQuery } from 'query-string';

interface QueryParams {
  avatarUrl?: string;
  aimsid?: string;
  topbar?: 'off';
}

const { search } = window.location;

const parsedSearch = camelizeKeys(qs.parse(search) as ParsedQuery<string>);

export function useQueryConfig(): QueryParams {
  return parsedSearch;
}
