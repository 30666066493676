// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".routes-router\\@myteam--app-router{font-size:20px;display:flex;justify-content:center;align-items:center;position:absolute;left:0;top:0;right:0;bottom:0}\n", ""]);
// Exports
exports.locals = {
	"app-router": "routes-router@myteam--app-router",
	"appRouter": "routes-router@myteam--app-router"
};
module.exports = exports;
