import Keycloak, {
  KeycloakConfig,
  KeycloakInitOptions,
  KeycloakInstance,
  KeycloakTokenParsed,
} from 'keycloak-js';

import { WindowExtended } from 'ant/types/window';

const keycloakConfig: KeycloakConfig = {
  realm: process.env.AUTH_REALM || '',
  url: process.env.REMOTE_URL ? `${process.env.REMOTE_URL}/auth/` : '/auth/',
  clientId: process.env.AUTH_CLIENT_ID || '',
};

export const keycloakInitConfig: KeycloakInitOptions = {
  onLoad: 'check-sso',
  silentCheckSsoRedirectUri: `${window.location.origin}/silent-check-sso.html`,
  pkceMethod: 'S256',
};

interface AuthKeycloakTokenParsed extends KeycloakTokenParsed {
  typ?: string;
}

interface AuthKeycloakInstance extends Omit<KeycloakInstance, 'tokenParsed'> {
  tokenParsed?: AuthKeycloakTokenParsed;
}

export const keycloak: AuthKeycloakInstance = Keycloak(keycloakConfig);

(window as WindowExtended).kc = keycloak;

const kc = keycloak;
const doLogin = kc.login;
const doLogout = kc.logout;
const getToken = (): string | undefined => kc.token;
const getCurrentUserId = (): string | undefined => kc.subject;
const getAuthType = (): string => kc?.tokenParsed?.typ || '';
const updateToken = (): Promise<boolean | void> => kc.updateToken(5).catch(doLogin);
const setCurrentUserId = (userId: string) => {
  kc.subject = userId;
};

export default {
  doLogin,
  doLogout,
  getToken,
  getAuthType,
  updateToken,
  getCurrentUserId,
  setCurrentUserId,
};
