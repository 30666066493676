import React, { useMemo } from 'react';

import { UiSkeleton } from 'ant/components/ui/skeleton';
import { useAbstractStorage } from 'ant/helpers/hooks/use-abstract-storage';
import { createArrayMock } from 'ant/plugins/createMocks';
import { formatPhoneNumber, PhoneMask, RegExpPattern } from 'ant/plugins/phone-formatter';
import ContactItem from 'profile-frontend/components/profile/sidebar/info/contact-item/ContactItem@myteam';
import { getProfileContactsStorage } from 'profile-frontend/store/profile';

import styles from './InfoContacts@myteam.scss';
import { InfoItem } from './items/info-item/InfoItem@myteam';

const MOCKS_COUNT = 2;
const ContactsMocks = createArrayMock(MOCKS_COUNT, (_, id) => (
  <span key={id} className={styles.infoContacts__loadingItem}>
    <UiSkeleton loading height={20} width={50} />
    <UiSkeleton loading height={20} width={200} />
  </span>
));

type Props = {
  userId?: string;
};

const InfoContacts: React.FC<Props> = (params) => {
  const { userId } = params;

  if (!userId) {
    return null;
  }

  const profileContactsStorage = useMemo(() => getProfileContactsStorage({ userId }), [userId]);
  const { data: contacts, loading } = useAbstractStorage(profileContactsStorage.storage, {
    autoFetchAndRefetch: userId.length > 0,
  });

  const mobilePhone = (contacts?.mobilePhone || '').replace(/^([^+])/, '+$1');
  const phoneContent = mobilePhone && (
    <ContactItem
      value={mobilePhone}
      formatedValue={formatPhoneNumber(mobilePhone, RegExpPattern.InternationalRu, PhoneMask.InternationalRu)}
    />
  );
  const emailContent = contacts?.workEmail && <ContactItem isEmail value={contacts?.workEmail} />;

  const contactItems = [
    {
      title: 'Мобильный',
      content: phoneContent,
    },
    {
      title: 'Email',
      content: emailContent,
    },
  ];

  return (
    <>
      {loading && (
        <>
          <div className={styles.infoContacts__separator} />
          {ContactsMocks}
        </>
      )}
      {!loading && (contacts?.mobilePhone || contacts?.workEmail) && (
        <>
          <div className={styles.infoContacts__separator} />
          {contactItems.map(
            ({ content, title }) =>
              Boolean(content) && (
                <InfoItem key={title} title={title}>
                  {content}
                </InfoItem>
              ),
          )}
        </>
      )}
    </>
  );
};

export default InfoContacts;
