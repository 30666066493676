import classNames from 'classnames';
import format from 'date-fns/format';
import React from 'react';

import { UiIcon } from 'ant/components/ui/icon/UiIcon';
import { UiSkeleton } from 'ant/components/ui/skeleton';
import { UiTypography } from 'ant/components/ui/typography';
import { AppLink } from 'ant/components/widgets/AppLink';
import { getFullNameWithoutPatronymic } from 'ant/plugins/name-formatters';
import { getRoutePath, RouteNames } from 'ant/plugins/routes';
import CoinSvg from 'profile-frontend/components/svg/coin.svg';
import { Gamification } from 'profile-frontend/typings/gamification';

import styles from './Transaction.scss';

enum TransactionTitle {
  BadgeCompletion = 'за достижение',
  TransferTo = 'перевод',
  TransferFrom = 'от',
}

type Props = {
  transaction: Gamification.TransactionHistory;
  onClose: () => void;
};

export const TransactionMock = () => (
  <div className={styles.transaction}>
    <UiSkeleton loading height={18} width={18} className={styles.transaction__icon} />
    <UiSkeleton loading height={18} width={300} />
  </div>
);

const Transaction: React.FC<Props> = ({ transaction, onClose }) => {
  const { amount, type, text: gamificationTitle, sender, recipient, createdAt } = transaction;
  const isBadgeCompletionType = type === Gamification.TransactionType.BadgeCompletion;
  const isTransferToType = type === Gamification.TransactionType.Transfer && amount < 0;

  const transactionTitle = isTransferToType ? TransactionTitle.TransferTo : TransactionTitle.TransferFrom;
  const user = isTransferToType ? recipient : sender;

  const iconStyles = classNames(styles.transaction__icon, {
    [styles.transaction__icon_secondary]: isTransferToType,
  });

  return (
    <div className={styles.transaction}>
      <UiIcon width={20} height={20} component={CoinSvg} className={iconStyles} />

      <span>
        <UiTypography.Text strong type={isTransferToType ? 'secondary' : undefined}>
          {isTransferToType ? amount : `+${amount}`}
        </UiTypography.Text>

        <UiTypography.Text className={styles.transaction__title}>
          {isBadgeCompletionType ? TransactionTitle.BadgeCompletion : transactionTitle}
        </UiTypography.Text>

        {isBadgeCompletionType ? (
          <UiTypography.Text strong>{gamificationTitle}</UiTypography.Text>
        ) : (
          <AppLink to={getRoutePath(RouteNames.Profile, { id: user.id })} onClick={onClose}>
            <UiTypography.Text strong>{getFullNameWithoutPatronymic(user.fullName)}</UiTypography.Text>
          </AppLink>
        )}
      </span>

      <UiTypography.Text type="secondary" className={styles.transaction__date}>
        {format(new Date(createdAt), 'dd.MM.yyyy')}
      </UiTypography.Text>
    </div>
  );
};

export default Transaction;
