import { NewsCategoryParams } from '@di/admin-frontend/src/store/news/api'; // TODO modal-types не должен знать про @di/admin-frontend вынести в ant или заменить тип на абстракцию Record
import { Gamification } from '@di/profle-frontend/src/typings/gamification';
import { AdaptationGroupModel } from 'ant/types/models/adaptation';
import { CalendarEventEditFormValuesType, CalendarEventModel } from 'ant/types/models/calendar';
import { EventModel } from 'ant/types/models/event';
import { PollModel } from 'ant/types/models/poll';
import { PostTypes } from 'ant/types/models/post';
import { Note } from 'ant/types/notes';

export enum GlobalModalAppearances {
  PageOverlay,
  PageOverlayNoClose,
  LargeModal,
  LargeAlternativeModal,
  MediumModal,
  FullscreenModal,
  Drawer,
}

export type GlobalModalState<P> = {
  isVisible: boolean;
  isHiding: boolean;
  payload: P | null;
};

export type GlobalModalSettings = {
  appearance: GlobalModalAppearances;
  moduleName: string;
};

/**
 * Новые модалки заводить здесь,
 * тип пейлоада при открытии задавать в GlobalModalsStorePayloads,
 * а настройки (внешний вид, название модуля) в globalModalsSettings
 */
export enum GlobalModalNames {
  Longread = 'Longread',
  EventsLongread = 'EventsLongread',
  EventsList = 'EventsList',
  CreateUpdateBlog = 'CreateUpdateBlog',
  BlogFiles = 'BlogFiles',
  CalendarCreateUpdateMeeting = 'CalendarCreateUpdateMeeting',
  CalendarCreateUpdateTask = 'CalendarCreateUpdateTask',
  CalendarEventDetail = 'CalendarEventDetail',
  CreateUpdatePoll = 'CreateUpdatePoll',
  CreateUpdateEvent = 'CreateUpdateEvent',
  MembershipApplications = 'MembershipApplications',
  LmsCreateCourse = 'LmsCreateCourse',
  LmsEditCourse = 'LmsEditCourse',
  LmsPassingCourse = 'LmsPassingCourse',
  LmsCourseView = 'LmsCourseView',
  LmsAddTest = 'LmsAddTest',
  LmsEditTest = 'LmsEditTest',
  CreateUpdateAdaptationGroup = 'CreateUpdateAdaptationGroup',
  NewsEditor = 'NewsEditor',
  NewsCreateAdmin = 'NewsCreateAdmin',
  NewsCreateCategory = 'NewsCreateCategory',
  ProfileAddThanksSuccess = 'ProfileAddThanksSuccess',
  NoteEditor = 'NoteEditor',
  AdminStructureCreateConnection = 'AdminStructureCreateConnection',
}

export type GlobalModalsStorePayloads = {
  [GlobalModalNames.Longread]: GlobalModalState<{ postId: number; postType: PostTypes }>;
  [GlobalModalNames.EventsLongread]: GlobalModalState<{ eventId: string }>;
  [GlobalModalNames.EventsList]: GlobalModalState<{ onSelect: (event: EventModel) => void }>;
  [GlobalModalNames.CreateUpdateBlog]: GlobalModalState<
    | {
        existingBlogId?: number;
        onSuccess?: () => void;
      }
    | {}
  >;
  [GlobalModalNames.CreateUpdateAdaptationGroup]: GlobalModalState<{
    group?: AdaptationGroupModel;
    groupId?: string;
    uploadGroupId?: string;
    onSuccess?: () => void;
  }>;
  [GlobalModalNames.CalendarCreateUpdateMeeting]: GlobalModalState<{
    initialEventFormValues?: CalendarEventEditFormValuesType;
    customSection?: React.FC<React.HTMLAttributes<HTMLDivElement>>;
    formClassName?: string;
    onSuccess?: () => void;
  }>;
  [GlobalModalNames.CalendarCreateUpdateTask]: GlobalModalState<{
    initialEventFormValues?: CalendarEventEditFormValuesType;
    onSuccess?: () => void;
  }>;
  [GlobalModalNames.CalendarEventDetail]: GlobalModalState<{
    event: CalendarEventModel;
    onSuccess?: () => void;
  }>;
  [GlobalModalNames.NewsEditor]: GlobalModalState<{
    newsId?: number;
    onClose: () => void;
  }>;
  [GlobalModalNames.NewsCreateAdmin]: GlobalModalState<{
    userId?: string;
    onClose: () => void;
    onRefreshAdminTable: () => void;
  }>;
  [GlobalModalNames.NewsCreateCategory]: GlobalModalState<{
    data?: NewsCategoryParams;
    onClose: () => void;
  }>;
  [GlobalModalNames.BlogFiles]: GlobalModalState<{ blogId: number }>;
  [GlobalModalNames.CreateUpdatePoll]: GlobalModalState<{
    poll?: PollModel;
    onSuccess?: (poll: PollModel) => void;
  }>;
  [GlobalModalNames.CreateUpdateEvent]: GlobalModalState<{
    event?: EventModel;
    onSuccess?: () => void;
  }>;
  [GlobalModalNames.MembershipApplications]: GlobalModalState<{
    blogId: string;
  }>;
  [GlobalModalNames.LmsCreateCourse]: GlobalModalState<{
    onCreateSuccess: () => void;
    onCancel: () => void;
  }>;
  [GlobalModalNames.LmsEditCourse]: GlobalModalState<{
    onEditSuccess: () => void;
    onDeleteSuccess: () => void;
    onCancel: () => void;
    courseId: number;
  }>;
  [GlobalModalNames.LmsCourseView]: GlobalModalState<{
    id: number;
    onMyTrackUpdate: (id: number, myTrack: boolean) => void;
  }>;
  [GlobalModalNames.LmsPassingCourse]: GlobalModalState<{
    courseId: number;
    onClose: () => void;
  }>;
  [GlobalModalNames.LmsAddTest]: GlobalModalState<{
    onSuccess: (testId: number) => void;
    onClose: () => void;
  }>;
  [GlobalModalNames.LmsEditTest]: GlobalModalState<{
    testId?: number;
    onSuccess: (testId: number) => void;
    onClose: () => void;
  }>;
  [GlobalModalNames.ProfileAddThanksSuccess]: GlobalModalState<{
    onClose: () => void;
    thank: Gamification.AddedThank;
  }>;
  [GlobalModalNames.NoteEditor]: GlobalModalState<{
    note?: Note;
    onClose: () => void;
    onSave: (note: Note) => void;
  }>;
  [GlobalModalNames.AdminStructureCreateConnection]: GlobalModalState<{
    onSuccess?: () => void;
    onClose: () => void;
  }>;
};

export const globalModalsSettings: Record<GlobalModalNames, GlobalModalSettings> = {
  [GlobalModalNames.Longread]: {
    appearance: GlobalModalAppearances.PageOverlay,
    moduleName: 'Longread',
  },
  [GlobalModalNames.EventsLongread]: {
    appearance: GlobalModalAppearances.PageOverlay,
    moduleName: 'EventsLongread',
  },
  [GlobalModalNames.EventsList]: {
    appearance: GlobalModalAppearances.LargeModal,
    moduleName: 'EventsList',
  },
  [GlobalModalNames.CalendarCreateUpdateMeeting]: {
    appearance: GlobalModalAppearances.FullscreenModal,
    moduleName: 'CalendarCreateUpdateMeeting',
  },
  [GlobalModalNames.CalendarCreateUpdateTask]: {
    appearance: GlobalModalAppearances.MediumModal,
    moduleName: 'CalendarCreateUpdateTask',
  },
  [GlobalModalNames.CalendarEventDetail]: {
    appearance: GlobalModalAppearances.MediumModal,
    moduleName: 'CalendarEventDetail',
  },
  [GlobalModalNames.CreateUpdateBlog]: {
    appearance: GlobalModalAppearances.LargeModal,
    moduleName: 'BlogCreateUpdate',
  },
  [GlobalModalNames.CreateUpdateAdaptationGroup]: {
    appearance: GlobalModalAppearances.PageOverlayNoClose,
    moduleName: 'CreateUpdateAdaptationGroup',
  },
  [GlobalModalNames.NewsEditor]: {
    appearance: GlobalModalAppearances.FullscreenModal,
    moduleName: 'NewsEditor',
  },
  [GlobalModalNames.NewsCreateAdmin]: {
    appearance: GlobalModalAppearances.MediumModal,
    moduleName: 'NewsCreateAdmin',
  },
  [GlobalModalNames.NewsCreateCategory]: {
    appearance: GlobalModalAppearances.MediumModal,
    moduleName: 'NewsCreateCategory',
  },
  [GlobalModalNames.BlogFiles]: {
    appearance: GlobalModalAppearances.LargeModal,
    moduleName: 'BlogFiles',
  },
  [GlobalModalNames.CreateUpdatePoll]: {
    appearance: GlobalModalAppearances.MediumModal,
    moduleName: 'Poll',
  },
  [GlobalModalNames.CreateUpdateEvent]: {
    appearance: GlobalModalAppearances.LargeModal,
    moduleName: 'CreateUpdateEvent',
  },
  [GlobalModalNames.MembershipApplications]: {
    appearance: GlobalModalAppearances.LargeModal,
    moduleName: 'MembershipApplications',
  },
  [GlobalModalNames.LmsCreateCourse]: {
    appearance: GlobalModalAppearances.FullscreenModal,
    moduleName: 'LmsAddCourse',
  },
  [GlobalModalNames.LmsEditCourse]: {
    appearance: GlobalModalAppearances.FullscreenModal,
    moduleName: 'LmsEditCourse',
  },
  [GlobalModalNames.LmsCourseView]: {
    appearance: GlobalModalAppearances.Drawer,
    moduleName: 'LmsCourseView',
  },
  [GlobalModalNames.LmsPassingCourse]: {
    appearance: GlobalModalAppearances.FullscreenModal,
    moduleName: 'LmsPassingCourse',
  },
  [GlobalModalNames.LmsAddTest]: {
    appearance: GlobalModalAppearances.LargeAlternativeModal,
    moduleName: 'LmsAddTest',
  },
  [GlobalModalNames.LmsEditTest]: {
    appearance: GlobalModalAppearances.LargeAlternativeModal,
    moduleName: 'LmsEditTest',
  },
  [GlobalModalNames.ProfileAddThanksSuccess]: {
    appearance: GlobalModalAppearances.MediumModal,
    moduleName: 'ProfileAddThanksSuccess',
  },

  [GlobalModalNames.NoteEditor]: {
    appearance: GlobalModalAppearances.FullscreenModal,
    moduleName: 'NoteEditor',
  },
  [GlobalModalNames.AdminStructureCreateConnection]: {
    appearance: GlobalModalAppearances.MediumModal,
    moduleName: 'AdminStructureCreateConnection',
  },
};
