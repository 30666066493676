import classNames from 'classnames';
import React, { useCallback, useMemo } from 'react';

import { LayoutItem, Populate, Widget, createWidgetComponent } from '@di/core-components';

import styles from './BaseLayout.scss';

import { DynamicRenderComponent } from '~/components/layouts/component/dynamic-render-component/DynamicRenderComponent';
import { LayoutTypes } from '~/typings/populated-layout';

export type BaseLayoutProps = {
  isLayoutsEdit: boolean;
  isSuperAdmin: boolean;
  onDropHandler: (widgetItem: Widget, layoutItem: LayoutItem) => void;
  onDeactivateWidgetClick: ({ layoutItemId }: { layoutItemId: number }) => void;
} & LayoutProps;

export type LayoutProps = {
  layoutItems: LayoutItem[];
  populatedLayout: Populate[];
};

const layoutItemsSingleTypes = [
  LayoutTypes.Header,
  LayoutTypes.Navbar,
  // LayoutTypes.Breadcrumb, // TODO не меняется через WidgetsGallery
  // LayoutTypes.Footer, // TODO не используется, пока что нет в дизайне
];

const LayoutsWidgets = createWidgetComponent({
  DynamicRenderComponent,
});

const BaseLayout: React.FC<BaseLayoutProps> = React.memo(
  ({
    isLayoutsEdit,
    isSuperAdmin,
    layoutItems: layoutItemsProps,
    populatedLayout: populatedLayoutProps,
    onDropHandler,
    onDeactivateWidgetClick,
  }) => {
    const populatedLayouts = useMemo(() => {
      return populatedLayoutProps.reduce((acc, item) => {
        acc[item.layoutItem] = item;
        return acc;
      }, {} as Record<number, Populate>);
    }, [populatedLayoutProps]);

    const layoutItems = useMemo(() => {
      return layoutItemsProps.reduce((acc, item) => {
        const type = item.types[0] as LayoutTypes; // TODO исправить на бэке, переделать в строку из LayoutTypes

        if (layoutItemsSingleTypes.includes(type)) {
          acc[type] = item;
        } else {
          acc[type] = [...((acc[type] || []) as LayoutItem[]), item];
        }

        return acc;
      }, {} as Record<LayoutTypes, LayoutItem | LayoutItem[]>);
    }, [layoutItemsProps, populatedLayouts]);

    const [firstColumnLayoutItems, secondColumnLayoutItems] = useMemo(() => {
      const columnLayoutItems = [...((layoutItems[LayoutTypes.Column] || []) as LayoutItem[])];
      const firstHalf = columnLayoutItems.splice(0, Math.ceil(columnLayoutItems.length / 2));

      return [firstHalf, columnLayoutItems];
    }, [layoutItems, populatedLayouts]);

    const layoutWidgetComponentMapper = useCallback(
      (layoutItem) => {
        const widget = populatedLayouts[layoutItem.id]?.widget;

        return (
          <LayoutsWidgets
            key={layoutItem.id}
            widget={widget}
            moduleProps={{ widget }}
            layoutItem={layoutItem}
            isLayoutsEdit={isLayoutsEdit}
            onDeactivateWidgetClick={() => onDeactivateWidgetClick({ layoutItemId: layoutItem.id })}
            isShowDeactivate={Boolean(isSuperAdmin || populatedLayouts[layoutItem.id]?.user)}
            isDefaultWidget={Boolean(widget && !populatedLayouts[layoutItem.id]?.user)}
            onDropHandler={onDropHandler}
          />
        );
      },
      [isLayoutsEdit, populatedLayouts, layoutItems],
    );

    const classNamesStyles = useMemo(
      () => classNames(styles.baseLayout, { [styles.baseLayout__widgetGallery_shift]: isLayoutsEdit }),
      [isLayoutsEdit],
    );

    return (
      <div className={classNamesStyles}>
        {layoutItems[LayoutTypes.Central] &&
          (layoutItems[LayoutTypes.Central] as LayoutItem[]).map(layoutWidgetComponentMapper)}

        <div className={styles.baseLayout__row}>
          <div className={styles.baseLayout__content}>
            {(layoutItems[LayoutTypes.Content] as LayoutItem[]).map(layoutWidgetComponentMapper)}
          </div>

          <div className={styles.baseLayout__columnWrapper}>
            <div className={styles.baseLayout__column}>
              {firstColumnLayoutItems.map(layoutWidgetComponentMapper)}
            </div>
            <div className={styles.baseLayout__column}>
              {secondColumnLayoutItems.map(layoutWidgetComponentMapper)}
            </div>
          </div>
        </div>
      </div>
    );
  },
);

export { BaseLayout };
