import React from 'react';
import SkeletonLoading, { SkeletonProps as ReactSkeletonProps } from 'react-loading-skeleton';

import './UiSkeleton.scss';

export type UiSkeletonProps = {
  loading?: boolean;
  block?: boolean;
  children?: React.ReactNode;
} & ReactSkeletonProps;

export type UiSkeletonWrapperProps = {
  block: boolean;
  children: React.ReactNode;
};

const SkeletonWrapper: React.FC<UiSkeletonWrapperProps> = ({ block, children }) =>
  block ? <div>{children}</div> : <>{children}</>;

export const UiSkeleton: React.FC<UiSkeletonProps> = (props) => {
  const { loading = false, block = false, children } = props;

  return loading ? (
    <SkeletonWrapper block={block}>
      <SkeletonLoading {...props}>{children}</SkeletonLoading>
    </SkeletonWrapper>
  ) : (
    <>{children}</>
  );
};
