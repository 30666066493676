import { format, isValid } from 'date-fns';
import ru from 'date-fns/locale/ru';
import React from 'react';
import { Link } from 'react-router-dom';

import MeatballSvg from 'ant/components/svg/meatball.svg';
import { UiAvatar } from 'ant/components/ui/avatar';
import { UiButton } from 'ant/components/ui/button';
import { UiSkeleton, UiSkeletonProps } from 'ant/components/ui/skeleton';
import { UiTypography } from 'ant/components/ui/typography';
import { getFullNameWithoutPatronymic } from 'ant/plugins/name-formatters';
import { getRoutePath, RouteNames } from 'ant/plugins/routes';
import { themePalette } from 'ant/theme';
import { Gender, UserProfile } from 'ant/types/models/user';
import { Gamification } from 'profile-frontend/typings/gamification';

import styles from './ItemHeader.scss';

const SHOW_ACTIONS_BUTTON = false; // TODO: Раздел в разработке

type Props = {
  headerUser: UserProfile;
  createdAt: string;
  type: Gamification.ItemTypes;
  toUser?: UserProfile;
  onClose: () => void;
};

const getGenderedActionByType = (type: Gamification.ItemTypes, gender: Gender) => {
  if (type === Gamification.ItemTypes.Thanks) {
    return gender === Gender.Female ? 'поблагодарила' : 'поблагодарил';
  }

  if (type === Gamification.ItemTypes.Badges) {
    return gender === Gender.Female ? 'получила достижение' : 'получил достижение';
  }

  return '';
};

const ItemHeader: React.FC<Props & UiSkeletonProps> = (props) => {
  const { type, toUser, headerUser, createdAt, loading, onClose } = props;

  const name = getFullNameWithoutPatronymic(headerUser.fullName);
  const fromUserName = toUser?.fullName && getFullNameWithoutPatronymic(toUser.fullName);

  return (
    <span className={styles.itemHeader}>
      <Link to={getRoutePath(RouteNames.Profile, { id: headerUser.id })} onClick={onClose}>
        <UiAvatar src={headerUser.avatar} size={40} />
      </Link>

      <span className={styles.itemHeader__info}>
        <UiSkeleton loading={!name} width={120} height={18}>
          <UiTypography.Text style={{ color: themePalette.colorTextPrimary }}>
            <Link
              to={getRoutePath(RouteNames.Profile, { id: headerUser.id })}
              onClick={onClose}
              // TODO Бага релоадит страницу: https://github.com/ReactTraining/react-router/issues/7727
              // component={UiTypography.Link}
            >
              {name}
            </Link>
          </UiTypography.Text>
        </UiSkeleton>

        <span>
          <UiTypography.Text type="secondary" style={{ fontWeight: 'normal' }}>
            {createdAt &&
              isValid(new Date(createdAt)) &&
              format(new Date(createdAt), 'dd MMMM yyyy', { locale: ru })}
          </UiTypography.Text>

          <UiTypography.Text type="secondary" strong style={{ marginLeft: 4 }}>
            {getGenderedActionByType(type, headerUser.gender)}
          </UiTypography.Text>

          {toUser && (
            <UiTypography.Text
              className={styles.itemHeader__link}
              style={{ color: themePalette.colorTextPrimary }}
            >
              <Link to={getRoutePath(RouteNames.Profile, { id: toUser.id })} onClick={onClose}>
                {/* TODO нет склонения имени */}
                {fromUserName}
              </Link>
            </UiTypography.Text>
          )}
        </span>
      </span>

      {SHOW_ACTIONS_BUTTON && (
        <UiButton type="link" disabled={loading} icon={<MeatballSvg />} style={{ marginLeft: 'auto' }} />
      )}
    </span>
  );
};

export default ItemHeader;
