import { Breadcrumb } from 'antd';
import { BreadcrumbProps, Route } from 'antd/es/breadcrumb/Breadcrumb';
import classNames from 'classnames';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import DropRightSvg from 'ant/components/svg/drop-right.svg';
import { UiTruncateMarkup } from 'ant/components/ui/truncate-markup';

import styles from './UiBreadcrumb.scss';

const ccn = classNames.bind(styles);

export type UiRoute = Route;

export type UiBreadcrumbProps = {
  breadcrumbRoutes?: Route[];
  suffixChildren?: React.ReactNode;
} & BreadcrumbProps;

export const UiBreadcrumb: React.FC<UiBreadcrumbProps> = (props) => {
  const { className, breadcrumbRoutes, suffixChildren, ...rest } = props;
  const { pathname } = useLocation();

  return (
    <span className={styles.uiBreadcrumb__wrapper}>
      <Breadcrumb {...rest} separator="" className={ccn(styles.uiBreadcrumb, className)}>
        {breadcrumbRoutes?.map((route: Route) => (
          <React.Fragment key={route.breadcrumbName}>
            <Breadcrumb.Item>
              {route.path && pathname !== route.path ? (
                <Link to={route.path}>{route.breadcrumbName}</Link>
              ) : (
                <UiTruncateMarkup truncate lines={1}>
                  {route.breadcrumbName}
                </UiTruncateMarkup>
              )}
            </Breadcrumb.Item>
            <Breadcrumb.Separator>
              <DropRightSvg />
            </Breadcrumb.Separator>
          </React.Fragment>
        ))}
      </Breadcrumb>

      {suffixChildren}
    </span>
  );
};
