import { AxiosResponse } from 'axios';

import { api } from 'ant/plugins/api';
import { DictPaginated } from 'ant/types/api';
import { BaseAttributes, RecordResponse } from 'ant/types/dictionary';

import { Dictionaries } from './dictionaries';
import { DictionariesEndpoints } from './endpoints';

export type DictionaryName = { dictionaryName: Dictionaries.Name };
export type CreateDictionaryRecord = {
  attributes: Partial<BaseAttributes>;
  keyFields?: string[];
};

export const generateCreateDictRecordParams = (name: string): CreateDictionaryRecord => ({
  attributes: { name },
});

export const getDictsList = <T = DictPaginated<Dictionaries.Dict>>(): Promise<AxiosResponse<T>> =>
  api.get({ url: DictionariesEndpoints.dictsList() });

export const getDictsByName = <T = Dictionaries.Dict>({
  dictionaryName,
}: DictionaryName): Promise<AxiosResponse<T>> =>
  api.get({ url: DictionariesEndpoints.dictsByName(dictionaryName) });

export const getDictsRecordsByName = <T = Dictionaries.Dict>({
  dictionaryName,
}: DictionaryName): Promise<AxiosResponse<T>> =>
  api.post({ url: DictionariesEndpoints.dictsSearchRecords(dictionaryName) });

export const createNewDictsRecord = <T = RecordResponse>({
  dictionaryName,
  keyFields,
  attributes,
}: DictionaryName & CreateDictionaryRecord): Promise<AxiosResponse<T>> =>
  api.post({
    url: DictionariesEndpoints.dictsRecordsNew(dictionaryName),
    data: { keyFields, attributes },
  });
