const getCalendarUrl = (url: string) => `/api/calendar${url}`;
const getMeetingsUrl = (url: string) => `/api/meetings/${url}`;

// TODO: зарефакторить
class CalendarEndpoints {
  static list = () => getCalendarUrl('/list');

  static subscriptions = () => getCalendarUrl('/subscriptions');

  static personalEvents = () => getCalendarUrl('/personal/events');

  static personalNewEvents = () => getCalendarUrl('/personal/events/new');

  static personalSummary = () => getCalendarUrl('/personal/summary');

  static exactEvent = (id: string) => getCalendarUrl(`/events/${id}`);

  static eventsPotentialAttendees = () => getCalendarUrl('/events/potential_attendees');

  static eventsStatus = () => getCalendarUrl('/events/status');

  static freeSlots = () => getCalendarUrl('/free_slots');

  static freeBusy = () => getCalendarUrl('/free_busy');

  static freeMeetingRooms = () => getMeetingsUrl('meeting-rooms/free-meeting-rooms/');
}

export { CalendarEndpoints };
