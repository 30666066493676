import classNames from 'classnames';
import { useStore } from 'effector-react';
import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import AddCircleSvg from 'ant/components/svg/add-circle.svg';
import { UiButton } from 'ant/components/ui/button';
import { UiModal, UiModalTypes } from 'ant/components/ui/modals';
import { UiSkeleton } from 'ant/components/ui/skeleton';
import { UiTooltip } from 'ant/components/ui/tooltip';
import { UiTypography } from 'ant/components/ui/typography';
import { UiWidgetHeader } from 'ant/components/ui/widget-header';
import { useAbstractStorage } from 'ant/helpers/hooks/use-abstract-storage';
import authService from 'ant/plugins/authService';
import { createArrayMock } from 'ant/plugins/createMocks';
import SkillsButton from 'profile-frontend/components/skills/button/SkillsButton';
import SkillsEmpty from 'profile-frontend/components/skills/empty/SkillsEmpty';
import SkillsModal from 'profile-frontend/components/skills/modal/SkillsModal';
import { profileFullInfoStorage } from 'profile-frontend/storage';
import { getSkillsStorage } from 'profile-frontend/store/skills';
import { ProfileInfo } from 'profile-frontend/typings/profile-info';
import { UserSkill } from 'profile-frontend/typings/skills';

import styles from './Skills.scss';
import SkillsAdd from './add/SkillsAdd';

const SHOW_SKILLS_COUNT = 10;
const SKILLS_MOCKS_COUNT = 8;

const skillsMocks = createArrayMock(SKILLS_MOCKS_COUNT, (_, id) => id);

type SkillsParams = {
  skillBtnClassName?: string;
  moreBtnClassName?: string;
};

const Skills = (params?: SkillsParams) => {
  const currentUserId = authService.getCurrentUserId();

  const { id: userId } = useParams<{ id: string }>();
  const [showMore, setShowMore] = useState(true);
  const [isAddOpen, setIsAddOpen] = useState(false);
  const [deleteSkillId, setDeleteSkillId] = useState<string | null>(null);

  const skillStorage = useMemo(() => getSkillsStorage({ userId }), [userId]);
  const { deleteSkillEffect } = skillStorage;
  const deleteSkillInProgress = useStore(deleteSkillEffect.pending);

  const { data: skillsList, loading } = useAbstractStorage(skillStorage.storage, {
    autoFetchAndRefetch: true,
    resetStoreOnUnmount: true,
  });

  const { data: profileFullInfoData, loading: profileFullInfoLoading } = useAbstractStorage(
    profileFullInfoStorage.storage,
  );

  const { main: userProfileData } = profileFullInfoData || ({} as ProfileInfo);

  useEffect(() => setShowMore(SHOW_SKILLS_COUNT >= skillsList.length), [skillsList]);

  const isOwner = useMemo(() => userId === currentUserId, [userId]);

  const toggleSkillsAdd = () => setIsAddOpen(!isAddOpen);

  const resetDeleteSkillId = () => setDeleteSkillId(null);

  const submitDelete = () => {
    if (deleteSkillId) {
      deleteSkillEffect({ skillId: deleteSkillId });
    }

    resetDeleteSkillId();
  };

  const SubmitDeleteChildren = (
    <UiButton
      block
      size="large"
      type="primary"
      label="Удалить"
      onClick={submitDelete}
      disabled={deleteSkillInProgress}
      className={styles.profileSkills__deleteBtn}
    />
  );

  const skills = useMemo(
    () =>
      skillsList.map((userSkill: UserSkill) => (
        <SkillsButton
          key={userSkill.skill.pk}
          isOwner={isOwner}
          userSkill={userSkill}
          skillStorage={skillStorage}
          currentUserId={currentUserId}
          onSkillDelete={setDeleteSkillId}
          buttonClassName={params?.skillBtnClassName}
        />
      )),
    [skillsList],
  );

  const ShowMoreButton = () => (
    <UiButton
      loading={loading}
      className={classNames(styles.profileSkills__skillBtn, params?.moreBtnClassName)}
      label={`Ещё ${skills.length - SHOW_SKILLS_COUNT}`}
      onClick={() => setShowMore(true)}
    />
  );

  return (
    <div className={styles.profileSkills}>
      <UiWidgetHeader title="Навыки" className={styles.profileSkills__wrapper}>
        <UiTooltip placement="left" title="Добавить навык">
          <UiButton type="link-secondary" icon={<AddCircleSvg />} onClick={toggleSkillsAdd} />
        </UiTooltip>
      </UiWidgetHeader>

      {loading && (
        <span className={styles.profileSkills__container}>
          {skillsMocks.map((id) => (
            <UiSkeleton
              key={id}
              loading={loading}
              height={38}
              width={80 + id * 10}
              className={styles.profileSkills__skillBtn}
            />
          ))}
        </span>
      )}

      {!loading && (
        <span className={styles.profileSkills__container}>
          {skills.slice(0, showMore ? skills.length : SHOW_SKILLS_COUNT)}
          {!showMore && <ShowMoreButton />}

          {userProfileData && !skills.length && (
            <SkillsEmpty
              isOwner={isOwner}
              addSkill={toggleSkillsAdd}
              userProfile={userProfileData}
              loading={profileFullInfoLoading}
            />
          )}
        </span>
      )}

      <UiModal isMaskClosable={false} type={UiModalTypes.Small} isOpen={isAddOpen} onClose={toggleSkillsAdd}>
        <SkillsAdd skillStorage={skillStorage} closeModal={toggleSkillsAdd} />
      </UiModal>

      <UiModal type={UiModalTypes.Small} isOpen={!!deleteSkillId} onClose={resetDeleteSkillId}>
        <SkillsModal
          title="Удалить навык?"
          onCancel={resetDeleteSkillId}
          submitChildren={SubmitDeleteChildren}
        >
          <UiTypography.Text className={styles.profileSkills__deleteNotice}>
            Навык и все его подтверждения будут удалены без возможности восстановления
          </UiTypography.Text>
        </SkillsModal>
      </UiModal>
    </div>
  );
};

export default Skills;
