import { useStore } from 'effector-react';
import React, { useCallback } from 'react';

import { LayoutItem, Widget } from '@di/core-components';
import currentProfileService from 'ant/plugins/currentProfileService';
import MainPageBreadcrumb from 'components-frontend/components/main-breadcrumb/MainBreadcrumb';

import { BaseLayout, LayoutProps } from '~/components/layouts/component/base-layout/BaseLayout';
import { WidgetsGallery } from '~/components/layouts/widgets/gallery/WidgetsGallery';
import { populatedLayoutStorage } from '~/storage';

const {
  resetLayoutItemsEvent,
  editPopulatedLayoutState,
  setWidgetPopulatedLayoutEvent,
} = populatedLayoutStorage;

const PageMain: React.FC<LayoutProps> = ({ layoutItems, populatedLayout }) => {
  const { isEdit } = useStore(editPopulatedLayoutState);
  const profile = useStore(currentProfileService.currentProfileStore);

  const onDeactivateWidgetClick = useCallback(({ layoutItemId }: { layoutItemId: number }) => {
    resetLayoutItemsEvent({ layoutItemId });
  }, []);

  const onDropHandler = useCallback((widget: Widget, layoutItem: LayoutItem) => {
    setWidgetPopulatedLayoutEvent({
      layoutItemId: layoutItem.id,
      type: layoutItem.types[0],
      widget,
    });
  }, []);

  return (
    <>
      <MainPageBreadcrumb />
      <BaseLayout
        isLayoutsEdit={isEdit}
        isSuperAdmin={Boolean(profile?.superAdmin)}
        layoutItems={layoutItems}
        populatedLayout={populatedLayout}
        onDeactivateWidgetClick={onDeactivateWidgetClick}
        onDropHandler={onDropHandler}
      />
      <WidgetsGallery isSuperAdmin={Boolean(profile?.superAdmin)} />
    </>
  );
};

export { PageMain };
