import { useStore } from 'effector-react';
import React, { useState } from 'react';

import { UiButton } from 'ant/components/ui/button';
import { message } from 'ant/components/ui/message';
import { UiOptionData } from 'ant/components/ui/select';
import { CreatableSearchSelect } from 'ant/components/widgets/CreatableSearchSelect';
import { useAbstractStorage } from 'ant/helpers/hooks/use-abstract-storage';
import { generateAutoCompleteOptions } from 'ant/plugins/utils/options-helper';
import { getDictsStorage } from 'ant/store/dictionaries';
import { Dictionaries } from 'ant/store/dictionaries/dictionaries';
import { DictDataParams, DictMatchTypes } from 'ant/types/api';
import { RecordResponse } from 'ant/types/dictionary';
import SkillsModal from 'profile-frontend/components/skills/modal/SkillsModal';
import { SkillsStorage } from 'profile-frontend/store/skills';
import { SaveSkillParams } from 'profile-frontend/store/skills/api';

type Props = {
  closeModal: () => void;
  skillStorage: SkillsStorage;
};

const skillsDictsStorage = getDictsStorage<RecordResponse, DictDataParams>({
  dictionaryName: Dictionaries.Names.Skills,
  dataBuilder: ({ name: { type, value } }) => ({ name: { type, value } }),
  getEndpointParams: () => ({ ordering: 'name' }),
});
const skillsDictsOptions = skillsDictsStorage.storage.store.map(({ data }) =>
  generateAutoCompleteOptions(data),
);

const SkillsAdd: React.FC<Props> = (props) => {
  const { closeModal, skillStorage } = props;
  const [searchText, setSearchText] = useState<string>('');
  const [selectedSkill, setSelectedSkill] = useState<UiOptionData | null>(null);

  const { saveSkillEffect } = skillStorage;
  const saveSkillInProgress = useStore(saveSkillEffect.pending);

  useAbstractStorage(skillsDictsStorage.storage, {
    autoFetchAndRefetch: searchText.length > 0,
    autoFetchParams: { name: { type: DictMatchTypes.Icontains, value: searchText } },
  });

  const { createNewDictsRecordEffect } = skillsDictsStorage;
  const skillsOptions = useStore(skillsDictsOptions);
  const createSkillPending = useStore(createNewDictsRecordEffect.pending);

  const onSaveSkillHandler = () => {
    if (selectedSkill) {
      const saveSkillParams: SaveSkillParams = {
        name: selectedSkill.value,
        skillId: selectedSkill.key,
      };

      saveSkillEffect(saveSkillParams)
        .then(closeModal)
        .catch(({ response }) => message.error(response.data[0].name[0] || 'Ошибка'));
    }
  };

  const onSearchSkill = (value: string) => {
    setSearchText(value);
    setSelectedSkill(null);
  };

  const onSelectSkill = (_: string, option: UiOptionData) => {
    setSelectedSkill(option);
  };

  const SubmitChildren = (
    <UiButton
      block
      size="large"
      type="primary"
      label="Сохранить"
      onClick={onSaveSkillHandler}
      loading={saveSkillInProgress}
      disabled={createSkillPending || !selectedSkill}
    />
  );

  return (
    <SkillsModal title="Добавить навык" onCancel={closeModal} submitChildren={SubmitChildren}>
      <CreatableSearchSelect
        size="large"
        placeholder="Выбрать"
        defaultActiveFirstOption
        value={searchText}
        options={skillsOptions}
        onSearch={onSearchSkill}
        onSelect={onSelectSkill}
        disabled={createSkillPending}
        onCreateEffect={createNewDictsRecordEffect}
        maxLength={50}
      />
    </SkillsModal>
  );
};

export default SkillsAdd;
