import React from 'react';

import { UiTypography } from 'ant/components/ui/typography';
import { ProjectItem } from 'profile-frontend/typings/projects';

import styles from './InfoReferencesProjects@myteam.scss';

export type ProjectsProps = {
  projects: ProjectItem[];
  className: string;
};

const ProjectsSeparator = () => <span className={styles.infoReferences__projects__separator}> • </span>;

const InfoReferencesProjects: React.FC<ProjectsProps> = (props) => {
  const { projects, className } = props;

  return (
    <UiTypography.Text className={className}>
      {projects.map(({ project }) => (
        <React.Fragment key={project.id}>
          {project.name}
          <ProjectsSeparator />
        </React.Fragment>
      ))}
    </UiTypography.Text>
  );
};

export default InfoReferencesProjects;
