import React from 'react';

import { UiTypography } from 'ant/components/ui/typography';
import { getFullNameWithoutPatronymic } from 'ant/plugins/name-formatters';
import { ProfileInfo as ProfileInfoType } from 'profile-frontend/typings/profile-info';

import styles from './InfoFullName@myteam.scss';

type ProfileInfoProps = {
  userData: ProfileInfoType;
};

const InfoFullName: React.FC<ProfileInfoProps> = (props) => {
  const { userData } = props;

  const { job, main } = userData;

  const { fullName } = main;
  const { position } = job;

  return (
    <span className={styles.infoFullName}>
      {fullName && (
        <UiTypography.Title level={4} className={styles.infoFullName__title}>
          {getFullNameWithoutPatronymic(fullName)}
        </UiTypography.Title>
      )}
      {position && (
        <UiTypography.Text type="secondary" className={styles.infoFullName__subTitle}>
          {position}
        </UiTypography.Text>
      )}
    </span>
  );
};

export default InfoFullName;
