import { UiModalTypes } from './typings';

export const MODAL_OFFSET = 40;

const stickedHeight = `calc(100vh - ${MODAL_OFFSET * 2}px)`;

export const modalWidths: Record<UiModalTypes, string> = {
  [UiModalTypes.Auto]: 'auto',
  [UiModalTypes.Small]: '460px',
  [UiModalTypes.Medium]: '596px',
  [UiModalTypes.Large]: '860px',
  [UiModalTypes.LargeAlternative]: '1024px',
  [UiModalTypes.XL]: `calc(100vw - ${MODAL_OFFSET * 2}px)`,
  [UiModalTypes.FullScreen]: '100vw',
};

export const modalHeights: Record<UiModalTypes, string> = {
  [UiModalTypes.Auto]: 'auto',
  [UiModalTypes.Small]: 'auto',
  [UiModalTypes.Medium]: 'auto',
  [UiModalTypes.Large]: stickedHeight,
  [UiModalTypes.LargeAlternative]: stickedHeight,
  [UiModalTypes.XL]: stickedHeight,
  [UiModalTypes.FullScreen]: '100vh',
};

export const getUiModalBodyStyle = (type: UiModalTypes): React.CSSProperties => ({
  width: modalWidths[type],
  height: modalHeights[type],
  maxHeight: type === UiModalTypes.FullScreen ? undefined : stickedHeight,
});
