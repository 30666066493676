import { AxiosError } from 'axios';
import { createEffect } from 'effector';

import { FileModel } from 'ant/types/models/file';

import {
  uploadFileStorageAttachment,
  UploadFileStorageAttachmentParams,
  UploadFileStorageMultipleAttachmentsParams,
} from './api';

export const uploadFileStorageAttachmentEffectFactory = () =>
  createEffect<UploadFileStorageAttachmentParams, FileModel, AxiosError>((params) =>
    uploadFileStorageAttachment(params),
  );

export type UploadFileStorageAttachmentEffect = ReturnType<typeof uploadFileStorageAttachmentEffectFactory>;

export const uploadFileStorageMultipleAttachmentsEffectFactory = () =>
  createEffect<UploadFileStorageMultipleAttachmentsParams, FileModel[], AxiosError>(
    ({ files, onUploadProgress, apiVersion }) =>
      Promise.all(
        files.map((fileToUpload) =>
          uploadFileStorageAttachment({
            file: fileToUpload.rawFile,
            onUploadProgress:
              onUploadProgress && ((percent: number) => onUploadProgress(fileToUpload.id, percent)),
            apiVersion,
          }),
        ),
      ),
  );
