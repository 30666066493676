// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._-_-_-ant-src-components-ui-badge--ui-badge .ant-badge-count{font-weight:700;font-size:12px;line-height:20px}\n", ""]);
// Exports
exports.locals = {
	"ui-badge": "_-_-_-ant-src-components-ui-badge--ui-badge",
	"uiBadge": "_-_-_-ant-src-components-ui-badge--ui-badge"
};
module.exports = exports;
