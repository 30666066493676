import { AxiosResponse } from 'axios';

import { api } from 'ant/plugins/api';
import { PagesEndpoints } from 'ant/store/pages/endpoints';
import { GetThemePickerColorsParams } from 'ant/theme/picker/store';
import { ColorThemePicker } from 'ant/theme/picker/typings';

export type SaveThemePickerUserColorsParams = {
  colors: ColorThemePicker[];
};

export const saveThemePickerUserColors = <T = unknown>({
  userId,
  colors = [],
}: SaveThemePickerUserColorsParams & GetThemePickerColorsParams): Promise<AxiosResponse<T>> =>
  api.post({
    url: PagesEndpoints.userColors(userId),
    data: colors,
  });
