import React from 'react';

import { UiScrollbars, UiScrollbarsProps, ScrollbarContext } from './UiScrollbars';
import styles from './UiScrollbars@myteam.scss';

export const ScrollbarContextMyteam = ScrollbarContext;

export type UiScrollbarsMytemProps = UiScrollbarsProps;

export const UiScrollbarsMyteam: React.FC<UiScrollbarsMytemProps> = ({ children, ...props }) => (
  <UiScrollbars
    autoHide
    autoHideTimeout={2000}
    autoHideDuration={500}
    size={4}
    thumbClassName={styles.uiScrollbars__thumb}
    trackClassName={styles.uiScrollbars__track}
    {...props}
  >
    {children}
  </UiScrollbars>
);
