import format from 'date-fns/format';
import isValid from 'date-fns/isValid';
import ru from 'date-fns/locale/ru';
import React from 'react';

import { UiTypography } from 'ant/components/ui/typography';
import { Devision } from 'profile-frontend/typings/devision@myteam';
import { ProfileInfo } from 'profile-frontend/typings/profile-info';

import styles from './InfoItems@myteam.scss';
import { InfoItem } from './info-item/InfoItem@myteam';
import { UserItem } from './user-item/UserItem@myteam';

type Props = {
  userData: ProfileInfo;
  userId?: string;
  devisionData?: Devision;
};

type InfoBlockItem = {
  title: string;
  value?: string;
  content?: React.ReactNode;
};

type InfoBlock = {
  key: string;
  items: InfoBlockItem[];
};

const InfoItems: React.FC<Props> = (props) => {
  const { userData, devisionData, userId } = props;
  const { job, main, projects, jobHistory } = userData;
  const { startOfWork, birthDayMonth, birthYear, isBirthYearHidden } = main;
  const { boss } = job;

  const devisionName =
    ((devisionData && devisionData.name) || (projects && projects.length > 0)) && devisionData
      ? devisionData.name
      : projects[0].project.name;
  const bossContent = boss && (
    <UserItem
      clickable={userId !== boss.id}
      userId={boss.id}
      fullName={boss.fullName}
      userEmail={boss.workEmail}
    />
  );
  const devisionLeader = devisionData && devisionData.leaderAttrs && (
    <UserItem
      clickable={userId !== devisionData.leaderAttrs.id}
      userId={devisionData.leaderAttrs.id}
      fullName={devisionData.leaderAttrs}
      userEmail={devisionData.leaderAttrs.email || ''}
    />
  );

  const startOfWorkValue =
    startOfWork && isValid(new Date(startOfWork))
      ? format(new Date(startOfWork), 'dd MMMM yyyy', {
          locale: ru,
        })
      : '';

  const workMode = '';
  const workPlace = '';
  const hrPartner = null;
  const birthDayValue =
    birthDayMonth && `${birthDayMonth}${!isBirthYearHidden && Boolean(birthYear) ? ` ${birthYear}` : ''}`;
  const jobHistoryContent =
    jobHistory?.length &&
    jobHistory.map(({ organization }) => (
      <UiTypography.Text key={organization} className={styles.infoItems__itemValue}>
        {organization}
      </UiTypography.Text>
    ));

  const infoBlocks: InfoBlock[] = [
    {
      key: 'workInfo',
      items: [
        {
          title: 'Отдел',
          value: devisionName,
        },
        {
          title: 'Руководитель',
          content: bossContent,
        },
        {
          title: 'HR Бизнес-партнер',
          content: hrPartner,
        },
        {
          title: 'Руководитель подразделения',
          content: devisionLeader,
        },
      ],
    },
    {
      key: 'workMode',
      items: [
        {
          title: 'Режим работы',
          value: workMode,
        },
        {
          title: 'Место в офисе',
          value: workPlace,
        },
        {
          title: 'В компании с ',
          value: startOfWorkValue,
        },
      ],
    },
    {
      key: 'biography',
      items: [
        {
          title: 'День рождения',
          value: birthDayValue,
        },
        {
          title: 'Где работал',
          content: jobHistoryContent,
        },
      ],
    },
  ];

  const infoContent: React.ReactNode[] = [];

  infoBlocks.forEach(({ key, items }) => {
    const blockContent = items
      .filter(({ content, value }) => Boolean(content || value))
      .map(({ content, value, title }) => (
        <InfoItem key={title} title={title} value={value}>
          {content || null}
        </InfoItem>
      ));

    if (blockContent.length) {
      infoContent.push(<div key={`${key}_separator`} className={styles.infoItems__separator} />);
      infoContent.push(
        <div key={key} className={styles.infoItems__content}>
          {blockContent}
        </div>,
      );
    }
  });

  return <>{infoContent}</>;
};

export default InfoItems;
