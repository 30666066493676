import React from 'react';
import { useParams } from 'react-router';

import { WidgetModule } from '@di/core-components';
import { UiMenu } from 'ant/components/ui/menu/UiMenu';
import { UiTypography } from 'ant/components/ui/typography';
import authService from 'ant/plugins/authService';

import styles from '../ProfileMenu.scss';
import itemStyles from '../title/ProfileMenuTitle.scss';

const ProfileMenuLogout: React.FC<WidgetModule> = () => {
  const { id } = useParams<{ id: string }>();
  const isOwner = id === authService.getCurrentUserId();

  if (isOwner) {
    return (
      <UiMenu selectable={false} onClick={() => authService.doLogout()} className={styles.profileMenu}>
        <UiMenu.Item className={styles.profileMenu__item}>
          <UiTypography.Text className={itemStyles.profileMenuTitle__header}>Выйти</UiTypography.Text>
        </UiMenu.Item>
      </UiMenu>
    );
  }

  return null;
};

export default ProfileMenuLogout;
