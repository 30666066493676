import React, { useCallback } from 'react';
import { useHistory, useParams } from 'react-router';

import { UiAvatarMyteam } from 'ant/components/ui/avatar/UiAvatar@myteam';
import { UiButton } from 'ant/components/ui/button';
import { UiTypography } from 'ant/components/ui/typography';
import { getFullNameWithoutPatronymic } from 'ant/plugins/name-formatters';
import { getRoutePath, RouteNames } from 'ant/plugins/routes';

import styles from './UserItem@myteam.scss';

type Props = {
  userId: string;
  clickable: boolean;
  userEmail: string;
  fullName: {
    firstName: string;
    lastName: string;
  };
};

export const UserItem: React.FC<Props> = ({ clickable, userId, fullName, userEmail }) => {
  const history = useHistory();
  const { id: structId } = useParams<{ id?: string }>();
  const handleLinkClick = useCallback((id: string) => {
    history.push(
      getRoutePath(RouteNames.Profile, {
        userId: id,
        ...(structId && { id: structId }),
      }),
    );
  }, []);

  const Wrapper: React.FC<{ className: string }> = (props) =>
    clickable ? (
      <UiButton onClick={() => handleLinkClick(userId)} type="link" {...props} />
    ) : (
      <div {...props} />
    );

  return (
    <Wrapper className={styles.userItem}>
      <UiAvatarMyteam email={userEmail} size={24} className={styles.userItem__icon} />
      <UiTypography.Text className={styles.userItem__title}>
        {getFullNameWithoutPatronymic(fullName)}
      </UiTypography.Text>
    </Wrapper>
  );
};
