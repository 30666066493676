export interface BaseAttributes {
  name: string;
}

export type RecordResponse<Attributes = BaseAttributes> = {
  attributes: Attributes;
  created: string;
  deletedAt: string | null;
  dictionary: string;
  id: string;
  isDeleted: boolean;
  isEnabled: boolean;
};

export type FetchRecordsParams = {
  withDeleted?: boolean;
  withDisabled?: boolean;
  pageNumber?: boolean;
  pageSize?: boolean;
};

export type FetchRecordsResponse<Attributes = BaseAttributes> = {
  objects: RecordResponse<Attributes>[];
  meta: {
    objectsCount: number;
    objectsTotal: number;
    pagesCount: number;
    pageNumber: number;
  };
};

export type FetchDictsResponse<Scheme> = {
  id: string;
  name: string;
  scheme: Scheme;
};

export interface ContactLinkAttributes extends BaseAttributes {
  icon: string;
  recordId: string;
  prefix: string;
  placeholder: string;
}

export type BaseRecord = {
  id: string;
  name: string;
};

export enum ContactDictKinds {
  Messenger = 'messenger',
  ExternalLink = 'external_link',
}

export type FetchDictsListStorageParams = {
  items: RecordResponse[];
};

export type DictsListStorageParams = {
  ids: string[];
};
