// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._-_-_-profile-frontend-src-components-profile-sidebar-avatar--sidebar-avatar__wrapper{text-align:center}._-_-_-profile-frontend-src-components-profile-sidebar-avatar--sidebar-avatar__image{display:inline-block}\n", ""]);
// Exports
exports.locals = {
	"sidebar-avatar__wrapper": "_-_-_-profile-frontend-src-components-profile-sidebar-avatar--sidebar-avatar__wrapper",
	"sidebarAvatar__wrapper": "_-_-_-profile-frontend-src-components-profile-sidebar-avatar--sidebar-avatar__wrapper",
	"sidebar-avatar__image": "_-_-_-profile-frontend-src-components-profile-sidebar-avatar--sidebar-avatar__image",
	"sidebarAvatar__image": "_-_-_-profile-frontend-src-components-profile-sidebar-avatar--sidebar-avatar__image"
};
module.exports = exports;
