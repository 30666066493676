import classNames from 'classnames';
import React from 'react';
import { isMobile } from 'react-device-detect';
import { useHistory } from 'react-router';

import ArrowBackSvg from 'ant/components/svg/arrow-back@myteam.svg';
import { UiButton } from 'ant/components/ui/button';
import { UiIcon } from 'ant/components/ui/icon';
// import { UiTypography } from 'ant/components/ui/typography';

import styles from './ProfileSidebarHeader@myteam.scss';

const ProfileSidebarHeader: React.FC = () => {
  const history = useHistory();
  // const officeStatus = 'Не в офисе';

  return (
    <div className={styles.sidebarHeader}>
      <UiButton
        onClick={history.goBack}
        className={classNames(
          styles.sidebarHeader__backButton,
          isMobile && styles.sidebarHeader__backButton_mobile,
        )}
      >
        <UiIcon width={24} height={24} component={ArrowBackSvg} />
      </UiButton>
      {/*
       <UiTypography.Text type="secondary" className={styles.sidebarHeader__status}>
         {officeStatus}
       </UiTypography.Text>
      */}
    </div>
  );
};

export default ProfileSidebarHeader;
