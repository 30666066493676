// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._-_-_-ant-src-components-ui-breadcrumb--ui-breadcrumb{display:flex}._-_-_-ant-src-components-ui-breadcrumb--ui-breadcrumb__wrapper{display:flex;height:64px;padding:22px 20px}\n", ""]);
// Exports
exports.locals = {
	"ui-breadcrumb": "_-_-_-ant-src-components-ui-breadcrumb--ui-breadcrumb",
	"uiBreadcrumb": "_-_-_-ant-src-components-ui-breadcrumb--ui-breadcrumb",
	"ui-breadcrumb__wrapper": "_-_-_-ant-src-components-ui-breadcrumb--ui-breadcrumb__wrapper",
	"uiBreadcrumb__wrapper": "_-_-_-ant-src-components-ui-breadcrumb--ui-breadcrumb__wrapper"
};
module.exports = exports;
