import classNames from 'classnames';
import React from 'react';

import { UiTooltip, UiTooltipPlacementProps } from 'ant/components/ui/tooltip';

import styles from './SlotsTableSlot.scss';

type Props = {
  tooltip?: React.ReactNode;
  tooltipPlacement?: UiTooltipPlacementProps;
  width?: number;
  height?: number;
  top?: number;
  shift?: number;
  isSelected?: boolean;
  type: 'free' | 'busy';
  onClick?: () => void;
};

export type SlotsTableSlotComponent = React.FC<Props>;

const SlotsTableSlot: SlotsTableSlotComponent = (props) => {
  const {
    tooltip,
    tooltipPlacement = 'top',
    isSelected,
    top = 14,
    width = 40,
    height = 20,
    shift,
    onClick,
    type,
  } = props;

  const style = {
    left: shift,
    top,
    width,
    height,
  };

  const isFree = type === 'free';

  return (
    <UiTooltip placement={tooltipPlacement} title={tooltip}>
      <button
        className={classNames(styles.slotsTableSlot, {
          [styles.slotsTableSlot_selected]: isSelected,
          [styles.slotsTableSlot_free]: isFree,
        })}
        style={style}
        type="button"
        onClick={onClick}
      />
    </UiTooltip>
  );
};

export { SlotsTableSlot };
