interface FullNameProps {
  firstName?: string | null;
  lastName?: string | null;
  patronymic?: string | null;
  username?: string | null;
}

export const abbreviateFullName = ({ lastName, firstName, patronymic }: FullNameProps) => {
  return [lastName, (firstName ?? '')[0], firstName && patronymic ? patronymic[0] : '']
    .filter(Boolean)
    .join(' ');
};

export const getFullName = ({ lastName, firstName, patronymic, username }: FullNameProps) => {
  if (!lastName && !firstName) {
    return username || '';
  }

  return [lastName, firstName, patronymic].filter(Boolean).join(' ');
};

export const getFullNameWithoutPatronymic = ({ lastName, firstName, username }: FullNameProps) => {
  if (!lastName && !firstName) {
    return username || '';
  }

  return [firstName, lastName].filter(Boolean).join(' ');
};
