import React from 'react';
import { Link } from 'react-router-dom';

import { UiRoute } from 'ant/components/ui/breadcrumb/UiBreadcrumb';
import { UiButton } from 'ant/components/ui/button/UiButton';
import { PortaledBreadcrumb } from 'ant/components/widgets/PortaledBreadcrumb/PortaledBreadcrumb';
import { getRoutePath, RouteNames } from 'ant/plugins/routes';

import styles from './MainBreadcrumb.scss';

const breadcrumbRoutes: UiRoute[] = [
  {
    path: '',
    breadcrumbName: 'Главная',
  },
];

const MainPageBreadcrumb = () => {
  const suffixChildren = (
    <Link to={getRoutePath(RouteNames.MainNews)} className={styles.breadcrumb__suffix}>
      <UiButton type="link" label="Все новости" />
    </Link>
  );

  return <PortaledBreadcrumb breadcrumbRoutes={breadcrumbRoutes} suffixChildren={suffixChildren} />;
};

export default MainPageBreadcrumb;
