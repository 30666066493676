// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._-_-_-ant-src-components-ui-widget-header--ui-widget-header{flex:1;display:flex;align-items:center;justify-content:space-between;padding:24px 0}\n", ""]);
// Exports
exports.locals = {
	"ui-widget-header": "_-_-_-ant-src-components-ui-widget-header--ui-widget-header",
	"uiWidgetHeader": "_-_-_-ant-src-components-ui-widget-header--ui-widget-header"
};
module.exports = exports;
