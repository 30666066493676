import { QWebChannel } from './qwebchannel';

const win = window as {
  qt?: { webChannelTransport: any };
  QWebChannel?: any;
  ThreadFeedJSBridge?: any;
  webkit?: {
    messageHandlers?: any;
  };
  client?: any;
};

if (win.qt && win.qt.webChannelTransport) {
  // eslint-disable-next-line no-new
  new QWebChannel(win.qt.webChannelTransport, (channel: any) => {
    win.client = channel.objects.client;
  });
}

export const isAndroidWebView = () => Boolean(win.ThreadFeedJSBridge);
export const isIOSWebView = () => Boolean(win.webkit && win.webkit.messageHandlers);
export const isDesktopWebView = () => Boolean(win.client);

let sendEvent: ((event: string, data: Record<string, unknown>) => void) | null = null;

const initNativeBridge = () => {
  const androidBridge: Record<string, (...data: any) => void> | undefined = isAndroidWebView()
    ? win.ThreadFeedJSBridge
    : undefined;

  const iosBridge: Record<string, { postMessage?: (data: any) => void }> | undefined = isIOSWebView()
    ? win.webkit?.messageHandlers
    : undefined;

  const desktopBridge: { postEvent: (data: any) => void } | undefined = isDesktopWebView()
    ? win.client
    : undefined;

  if (isDesktopWebView()) {
    sendEvent = (event, data) =>
      desktopBridge &&
      typeof desktopBridge.postEvent === 'function' &&
      desktopBridge.postEvent({ event, data });
  } else if (isIOSWebView()) {
    sendEvent = (event, data) => {
      const iosBridgeEvent =
        iosBridge &&
        iosBridge[event] &&
        typeof iosBridge[event].postMessage === 'function' &&
        iosBridge[event];

      iosBridgeEvent && iosBridgeEvent.postMessage && iosBridgeEvent.postMessage(data);
    };
  } else if (isAndroidWebView()) {
    sendEvent = (event, data) => {
      if (androidBridge && androidBridge[event]) {
        if (data && typeof data === 'object' && !Array.isArray(data)) {
          androidBridge[event](...Object.values(data));
        } else {
          androidBridge[event](data);
        }
      }
    };
  } else {
    sendEvent = (event, data) => {
      window.console.log(`[MYTEAM_LOG] sendEvent ${event}, ${JSON.stringify(data)}`);

      if (window.parent !== window && window.parent && typeof window.parent.postMessage === 'function') {
        window.parent.postMessage({ method: event, data }, '*');
      }
    };
  }
};

export const sendNativeAppEvent: (event: string, data: Record<string, unknown>) => void = (...props) => {
  if (!sendEvent) {
    initNativeBridge();
  }

  sendEvent && sendEvent(...props);
};
