import { Registry } from '@bem-react/di';

import ProfilePageMyteam from 'profile-frontend/components/ProfilePage@myteam';
import ProfileSidebarMyteam from 'profile-frontend/components/ProfileSidebar@myteam';

import { HeaderDefault } from '~/components/header/Header@default';
import { PageSearchDefault } from '~/components/layouts/mail/search/PageSearch@default';
import { PageStructureMyteam } from '~/components/layouts/mail/structure/PageStructure@myteam';
import { RouterMyteam } from '~/components/routes/router@myteam';
import { PagesFrontendRegistry } from '~/typings/registry';

export const pagesFrontendMyteamRegistry = new Registry({ id: 'pages-frontend' });

pagesFrontendMyteamRegistry.set<PagesFrontendRegistry['Header']>('Header', HeaderDefault);
pagesFrontendMyteamRegistry.set<PagesFrontendRegistry['ProfilePage']>('ProfilePage', ProfilePageMyteam);
pagesFrontendMyteamRegistry.set<PagesFrontendRegistry['ProfileSidebar']>(
  'ProfileSidebar',
  ProfileSidebarMyteam,
);
pagesFrontendMyteamRegistry.set<PagesFrontendRegistry['PageSearch']>('PageSearch', PageSearchDefault);
pagesFrontendMyteamRegistry.set<PagesFrontendRegistry['Router']>('Router', RouterMyteam);
pagesFrontendMyteamRegistry.set<PagesFrontendRegistry['PageStructure']>('PageStructure', PageStructureMyteam);
