import * as t from 'io-ts';
import { isRight, fold } from 'fp-ts/lib/Either';
import { pipe } from 'fp-ts/lib/pipeable';

const customPathReporter = <ResponseType>(validation: t.Validation<ResponseType>) =>
  pipe(
    validation,
    fold(
      (errors) => {
        const errorsText: string[] = [];
        errors.map(({ context }) => {
          const lastItem = context[context.length - 1];
          const path = context.map(({ key }) => key).join('.');
          errorsText.push(`Invalid value in ${path}\nexpected type: ${lastItem.type.name}`);
          return context.map(({ key }) => key).join('.');
        });
        return errorsText;
      },
      () => ['no errors'],
    ),
  );

export const ioTypescriptCheck = <ResponseType>(IOType: t.Type<ResponseType>, data: unknown) => {
  const result = IOType.decode(data);
  const isResultRight = isRight(result);

  if (!isResultRight) {
    // eslint-disable-next-line
    console.error(customPathReporter<ResponseType>(IOType.decode(data)));
  }

  return isResultRight;
};
