const getFileStorageUrl = (url: string) => `/filestorage/api/${url}`;
const getFileStorageUrlV2 = (url: string) => `/api/filestorage/v2/${url}`;

// TODO перейти везде на вторую версию ручки
class FileStorageEndpoints {
  static fileUpload = () => getFileStorageUrl('attachments/');

  static fileUploadV2 = () => getFileStorageUrlV2('file/upload/');

  static fileContent = (imageId: number) => `/api/filestorage/v2/file/${imageId}/content/`;
}

export { FileStorageEndpoints };
