import { AxiosError } from 'axios';
import { createEffect } from 'effector';
import { Stringifiable } from 'query-string';

import { abstractStorageFactory } from 'ant/helpers/storage/abstract-storage-factory';
import { buildEndpointWithQueryParams } from 'ant/plugins/utils/endpoint-builder';
import { Dictionaries } from 'ant/store/dictionaries/dictionaries';
import { DictPaginatedObjects } from 'ant/types/api';
import { DictsListStorageParams, FetchDictsListStorageParams, RecordResponse } from 'ant/types/dictionary';

import { createNewDictsRecord, CreateDictionaryRecord } from './api';
import { DictionariesEndpoints } from './endpoints';

interface GetDictStorageParams<P extends Record<string, unknown>> {
  dictionaryName: Dictionaries.Name;
  dataBuilder?: (params: P) => Record<string, unknown>;
  getEndpointParams?: (params: P) => Record<string, Stringifiable>;
}

export const getDictsStorage = <T extends RecordResponse, P extends Record<string, unknown>>({
  dictionaryName,
  dataBuilder = () => ({}),
  getEndpointParams = () => ({}),
}: GetDictStorageParams<P>) => {
  const storage = abstractStorageFactory<DictPaginatedObjects<T>, T[], T[], P>({
    endpointBuilder: (params) =>
      buildEndpointWithQueryParams(
        DictionariesEndpoints.dictsSearchRecords(dictionaryName),
        getEndpointParams(params),
      ),
    requestMethod: 'post',
    defaultValue: [],
    cancelPendingRequestOnFetch: true,
    dataBuilder: (params: P) => dataBuilder(params),
    dataMapper: (data) => data.objects,
    paginationInfoRetriever: ({ meta }) => ({ count: meta.pagesCount }),
  });

  const createNewDictsRecordEffect = createEffect<CreateDictionaryRecord, RecordResponse, AxiosError>(
    (params) => createNewDictsRecord({ ...params, dictionaryName }).then(({ data }) => data),
  );

  storage.store.on(createNewDictsRecordEffect.doneData, (state, newDictItem) => ({
    ...state,
    data: [...state.data, newDictItem as T],
  }));

  return { storage, createNewDictsRecordEffect };
};

export const getDictsListStorage = () =>
  abstractStorageFactory<
    FetchDictsListStorageParams,
    RecordResponse[],
    RecordResponse[],
    DictsListStorageParams
  >({
    endpointBuilder: DictionariesEndpoints.dictsListByIds,
    dataBuilder: ({ ids }) => ({ ids }),
    dataMapper: (data) => data.items,
    requestMethod: 'post',
    defaultValue: [],
  });
