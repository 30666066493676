import React from 'react';
import classNames from 'classnames';

import styles from './UiModalFooter.scss';

const ccn = classNames.bind(styles);

export interface UiModalFooterProps {
  className?: string;
  hasTopBorder?: boolean;
}

export type ModalFooterComponent = React.FC<UiModalFooterProps>;

export const UiModalFooter: ModalFooterComponent = (props) => {
  const { children, className, hasTopBorder = true } = props;

  const modalFooterClassName = ccn(
    styles.uiModalFooter,
    {
      [styles.uiModalFooter_withBorder]: hasTopBorder,
    },
    className,
  );

  return <div className={modalFooterClassName}>{children}</div>;
};
