import { KeycloakUser } from './keycloak-user';

export interface UserModel {
  id: string | number;
  username?: string;
  email?: string;
  patronymic?: string;
  firstName?: string;
  lastName?: string;
  gender?: Gender;
  avatar?: string;
  keycloakUser?: KeycloakUser;
  position?: string;
}

export type FullName = {
  firstName: string;
  lastName: string;
  patronymic: string;
};

export enum Gender {
  Female = '0',
  Male = '1',
}

export interface UserProfile {
  id: string;
  fullName: FullName;
  gender: Gender;
  groupPath: string | null;
  absence: {
    begin: string;
    cause: string;
    end: string;
  };
  actualAddress: string;
  avatar: string;
  avatarImg?: string;
  birthDate: string;
  birthDayMonth: string;
  birthYear: string;
  email?: string;
  isBirthYearHidden?: boolean;
  colleague: boolean | null;
  follow: boolean;
  startOfWork: string;
  superAdmin: boolean;
  smallAvatar: string;
  townLive?: string;
  townLiveRid?: string;
  metroStation?: string;
  metroStationRid?: string;
}

// TODO найти общее место для всех Permissions когда они начнут приходить с бэка в полном объеме
// Вот тут есть еще: packages/groups-frontend/src/types/models/blog.ts
export enum NewsAllowedActions {
  CreateNews = 'create_news',
  ManageAllNews = 'manage_all_news',
  ManageAdmins = 'manage_admins',
  ManageCategories = 'manage_categories',
}

export type UserPermissions = {
  news: {
    id: string;
    allowedActions: NewsAllowedActions[];
  };
};
