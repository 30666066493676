import QRCode from 'qrcode.react';
import React from 'react';

import { UiIcon } from 'ant/components/ui/icon/UiIcon';
import { UiSkeleton } from 'ant/components/ui/skeleton';
import { UiTypography } from 'ant/components/ui/typography/UiTypography';
import { themePalette } from 'ant/theme';
import QRIcon from 'profile-frontend/components/svg/contacts-qr.svg';

import styles from './QRCard.scss';

const QRCodeSize = 68;

type QRCardProps = {
  loading?: boolean;
  QRCodeBgColor?: string;
};

const QRCard: React.FC<QRCardProps> = (props) => {
  const { loading, QRCodeBgColor } = props;

  return (
    <span className={styles.qrCard}>
      <span className={styles.qrCard__wrapper}>
        <UiTypography.Title
          level={3}
          type="secondary"
          style={{ marginBottom: 8 }}
          className={styles.qrCard__title}
        >
          <UiIcon component={QRIcon} width={20} height={20} className={styles.qrCard__titleIcon} />
          <UiSkeleton loading={loading} height={20} width={90}>
            QR-визитка
          </UiSkeleton>
        </UiTypography.Title>

        <UiTypography.Text type="secondary">
          <UiSkeleton count={2} loading={loading} height={18}>
            Просканируйте код с&nbsp;помощью камеры смартфона
          </UiSkeleton>
        </UiTypography.Text>
      </span>

      <UiSkeleton loading={loading} height={QRCodeSize} width={QRCodeSize}>
        <QRCode
          value={window.location.href}
          size={QRCodeSize}
          level="M"
          renderAs="svg"
          includeMargin={false}
          bgColor={QRCodeBgColor || themePalette.white}
          fgColor={themePalette.black}
        />
      </UiSkeleton>
    </span>
  );
};

export default QRCard;
