import classNames from 'classnames';
import { useStore } from 'effector-react';
import React, { useEffect, useMemo, useState } from 'react';

import ArrowLeft from 'ant/components/svg/arrow-left.svg';
import ArrowRight from 'ant/components/svg/arrow-right.svg';
import { UiButton } from 'ant/components/ui/button';
import { UiCheckbox } from 'ant/components/ui/checkbox';
import { UiIcon } from 'ant/components/ui/icon';
import { placementAlignMap, UiSelect } from 'ant/components/ui/select';
import { UiSkeleton } from 'ant/components/ui/skeleton';
import { UiSwiper } from 'ant/components/ui/swiper/UiSwiper';
import { UiTypography } from 'ant/components/ui/typography';
import { useAbstractStorage } from 'ant/helpers/hooks/use-abstract-storage';
import { createArrayMock } from 'ant/plugins/createMocks';

import styles from './WidgetsGallery.scss';
import { WidgetsGallerySlide } from './slide/WidgetsGallerySlide';

import { populatedLayoutStorage, widgetListStorage, widgetTypesStorage } from '~/storage';
import { LayoutTypes } from '~/typings/populated-layout';

const MOCK_WIDTH = 280;
const MOCK_COUNT = Math.round(window.innerWidth / MOCK_WIDTH);
const widgetsListMocks = createArrayMock(MOCK_WIDTH, (_, key) => (
  <UiSkeleton key={key} loading width="100%" height={150} />
));
const swiperButton = 'swiper-button';
const navigation = {
  nextEl: `.${styles.widgetsGallery__swiperButton_next}`,
  prevEl: `.${styles.widgetsGallery__swiperButton_prev}`,
};

const widgetTypesOptionsStorage = widgetTypesStorage.storage.store.map(({ data }) =>
  data.map((widgetType) => ({ label: widgetType, value: widgetType })),
);

export type Props = {
  isSuperAdmin: boolean;
};

const WidgetsGallery: React.FC<Props> = ({ isSuperAdmin }) => {
  const [selectedType, setSelectedType] = useState<LayoutTypes>();
  const {
    editPopulatedLayoutState,
    cancelLayoutEditEvent,
    saveLayoutEditEffect,
    setIsSuperAdminPopulateEvent,
  } = populatedLayoutStorage;
  const { isEdit, isSuperAdminPopulateWidgets } = useStore(editPopulatedLayoutState);

  useEffect(() => {
    if (isEdit) {
      setSelectedType(undefined);
    }
  }, [isEdit]);

  const onSelectType = (type: string) => {
    setSelectedType(type as LayoutTypes);
  };

  const { data: widgetsListData, loading } = useAbstractStorage(widgetListStorage.storage, {
    autoFetchAndRefetch: isEdit,
    autoFetchParams: selectedType && { types: [selectedType] },
    cancelPendingRequestOnUnmount: true,
  });

  useAbstractStorage(widgetTypesStorage.storage, {
    autoFetchAndRefetch: isEdit,
    cancelPendingRequestOnUnmount: true,
  });

  const widgetTypesOptions = useStore(widgetTypesOptionsStorage);

  const widgetsSlides = useMemo(() => {
    return !loading && Array.isArray(widgetsListData?.items)
      ? widgetsListData?.items.map((widget) => <WidgetsGallerySlide key={widget.id} widget={widget} />)
      : widgetsListMocks;
  }, [widgetsListData, loading]);

  const slideClassName = useMemo(
    () => classNames(styles.widgetsGallery__slide, { [styles.widgetsGallery__slide_loading]: loading }),
    [loading],
  );

  const swiperParams = {
    navigation,
    slidesPerView: MOCK_COUNT,
    slideClassName,
    mousewheel: true,
    simulateTouch: false,
    spaceBetween: 20,
    pagination: { clickable: true },
  };

  return (
    <div className={classNames(styles.widgetsGallery, { [styles.widgetsGallery_opened]: isEdit })}>
      <div className={styles.widgetsGallery__header}>
        <UiTypography.Text className={styles.widgetsGallery__headerType}>
          Тип
          <UiSelect
            type="text"
            bordered={false}
            defaultValue=""
            value={selectedType as string}
            onSelect={onSelectType}
            options={widgetTypesOptions}
            dropdownMatchSelectWidth={false}
            dropdownAlign={placementAlignMap.top}
          />
          {isSuperAdmin && (
            <UiCheckbox
              className={styles.widgetsGallery__checkBox}
              checked={isSuperAdminPopulateWidgets}
              onChange={() => setIsSuperAdminPopulateEvent()}
            >
              Задать для всех
            </UiCheckbox>
          )}
        </UiTypography.Text>
        <UiTypography.Text className={styles.widgetsGallery__headerTitle}>
          Начните перетаскивать виджет
        </UiTypography.Text>
        <span className={styles.widgetsGallery__headerControls}>
          <UiButton label="Сохранить" type="primary" onClick={() => saveLayoutEditEffect(isSuperAdmin)} />
          <UiButton label="Отменить" type="primary" onClick={() => cancelLayoutEditEvent()} />
        </span>
      </div>

      <div className={styles.widgetsGallery__slider}>
        <UiButton
          size="large"
          type="primary"
          disabled={loading}
          className={classNames(
            swiperButton,
            styles.widgetsGallery__swiperButton,
            styles.widgetsGallery__swiperButton_prev,
          )}
        >
          <UiIcon component={ArrowLeft} width={20} height={20} />
        </UiButton>

        <UiSwiper {...swiperParams} className={styles.widgetsGallery__swiper}>
          {widgetsSlides}
        </UiSwiper>

        {widgetsSlides?.length === 0 && (
          <div className={styles.widgetsGallery__listEmpty}>
            Нет виджетов с типом&nbsp;
            {selectedType}
          </div>
        )}

        <UiButton
          size="large"
          type="primary"
          disabled={loading}
          className={classNames(
            swiperButton,
            styles.widgetsGallery__swiperButton,
            styles.widgetsGallery__swiperButton_next,
          )}
        >
          <UiIcon component={ArrowRight} width={20} height={20} />
        </UiButton>
      </div>
    </div>
  );
};

export { WidgetsGallery };
