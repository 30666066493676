import { Checkbox as AntCheckbox } from 'antd';
import { CheckboxProps } from 'antd/es/checkbox';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import React from 'react';

export interface UiCheckboxComposition {
  Group: typeof AntCheckbox.Group;
}

export type UiCheckboxChangeEvent = CheckboxChangeEvent;
export type CheckboxChangeEventHandler = (e: UiCheckboxChangeEvent) => void;
export const getInvertUiCheckboxValue = (value?: boolean): boolean => !value;

const UiCheckbox: React.FC<CheckboxProps> & UiCheckboxComposition = (props) => <AntCheckbox {...props} />;

UiCheckbox.Group = AntCheckbox.Group;

export { UiCheckbox };
