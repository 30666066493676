import authService from 'ant/plugins/authService';

const getUserPopulatedLayoutUrl = (url: string) => `/pages/users/${authService.getCurrentUserId()}/${url}`;
const getDefaultPopulatedLayoutUrl = (url: string) => `/pages/default/${url}`;

export class PopulatedLayoutEndpoints {
  static populatedLayout = (id: number) => getUserPopulatedLayoutUrl(`populated_layout/${id}/`);

  static populate = () => getUserPopulatedLayoutUrl(`populate/`);

  static populateDefault = () => getDefaultPopulatedLayoutUrl('populate/');

  static resetLayoutItems = () => getUserPopulatedLayoutUrl('reset_layout_items/');

  static resetDefaultLayoutItems = () => getDefaultPopulatedLayoutUrl('reset_layout_items/');
}
