import classNames from 'classnames';
import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import AddCircleSvg from 'ant/components/svg/add-circle.svg';
import Appreaction3Svg from 'ant/components/svg/appreaction-3.svg';
import ExploreSvg from 'ant/components/svg/explore.svg';
import VoteUpSvg from 'ant/components/svg/vote-up.svg';
import { UiButton } from 'ant/components/ui/button';
import { UiModal, UiModalTypes } from 'ant/components/ui/modals';
import { UiSkeleton } from 'ant/components/ui/skeleton';
import { UiTooltip } from 'ant/components/ui/tooltip/UiTooltip';
import { UiWidgetHeader } from 'ant/components/ui/widget-header';
import { useAbstractStorage } from 'ant/helpers/hooks/use-abstract-storage';
import authService from 'ant/plugins/authService';
import { createArrayMock } from 'ant/plugins/createMocks';
import { closeGlobalModal, openGlobalModal } from 'ant/store/global-modals';
import { GlobalModalNames } from 'ant/store/global-modals/modal-types';
import AddThanks from 'profile-frontend/components/gamification/add-thanks/AddThanks';
import GamificationEmpty from 'profile-frontend/components/gamification/empty/GamificationEmpty';
import { GamificationItem } from 'profile-frontend/components/gamification/item/GamificationItem';
import GamificationList from 'profile-frontend/components/gamification/list/GamificationList';
import {
  getGamificationUserBadgesSummaryStorage,
  getGamificationUserThanksSummaryStorage,
} from 'profile-frontend/store/gamification';
import { Gamification as GamificationTypings } from 'profile-frontend/typings/gamification';

import styles from './Gamification.scss';

const SHOW_ACHIEVMENTS_PROGRESS_BADGE = false; // TODO: Раздел в разработке

const MOCKS_COUNT = 4;
const gamificationMocks = createArrayMock(MOCKS_COUNT, (_, key) => (
  <UiSkeleton key={key} circle loading width={72} height={70} className={styles.gamification__skeleton} />
));

const Gamification = () => {
  const { id: userId } = useParams<{ id: string }>();

  const [openedListType, setOpenedListType] = useState<string>('');
  const [isAddThankOpen, setIsAddThankOpen] = useState(false);

  const userBadgesStorage = useMemo(() => getGamificationUserBadgesSummaryStorage({ userId }), [userId]);
  const userThanksStorage = useMemo(() => getGamificationUserThanksSummaryStorage({ userId }), [userId]);

  const { data: userBadges, loading: isBadgesLoading } = useAbstractStorage(userBadgesStorage.storage, {
    autoFetchAndRefetch: true,
    resetStoreOnUnmount: true,
  });

  const { data: userThanks, loading: isThanksLoading } = useAbstractStorage(userThanksStorage.storage, {
    autoFetchAndRefetch: true,
    resetStoreOnUnmount: true,
  });

  const gamificationWasLoaded = !isBadgesLoading && !isThanksLoading;
  const isOwner = userId === authService.getCurrentUserId();
  const showBadge = isOwner || userBadges.length > 0;
  const userThanksIsEmpty = !isThanksLoading && !userThanks.length;
  const userBadgesIsEmpty = !isBadgesLoading && !userBadges.length;

  const onCloseListHandler = () => setOpenedListType('');
  const toggleAddThankOpen = () => setIsAddThankOpen((value) => !value);

  useEffect(() => {
    if (isAddThankOpen) {
      const unsubscribe = userThanksStorage.createThanksEffect.doneData.watch((thank) => {
        openGlobalModal(GlobalModalNames.ProfileAddThanksSuccess, {
          onClose: () => closeGlobalModal(GlobalModalNames.ProfileAddThanksSuccess),
          thank,
        });

        unsubscribe();
      });
    }
  }, [isAddThankOpen]);

  return (
    <div className={styles.gamification}>
      <UiWidgetHeader title="Благодарности" className={styles.gamification__wrapper}>
        {!isOwner && (
          <UiTooltip placement="left" title="Поблагодарить">
            <UiButton type="link-secondary" icon={<AddCircleSvg />} onClick={toggleAddThankOpen} />
          </UiTooltip>
        )}
      </UiWidgetHeader>

      <span className={styles.gamification__items}>
        {isThanksLoading && gamificationMocks}
        {!userThanksIsEmpty &&
          userThanks.map((thanks) => (
            <GamificationItem
              key={thanks.name}
              name={thanks.name}
              image={thanks.image}
              icon={VoteUpSvg}
              count={thanks.count}
              onGamificationClick={() => setOpenedListType(GamificationTypings.ItemTypes.Thanks)}
            />
          ))}
        {userThanksIsEmpty && (
          <GamificationEmpty
            isOwner={isOwner}
            toggleAddThanksOpen={toggleAddThankOpen}
            type={GamificationTypings.ItemTypes.Thanks}
          />
        )}
      </span>

      {showBadge && (
        <>
          <UiWidgetHeader
            title="Достижения"
            className={classNames(styles.gamification__wrapper, styles.gamification__separator)}
          >
            {SHOW_ACHIEVMENTS_PROGRESS_BADGE && isOwner && (
              <UiTooltip placement="left" title="Прогресс достижений">
                <UiButton type="link-secondary" icon={<ExploreSvg />} />
              </UiTooltip>
            )}
          </UiWidgetHeader>

          <span className={styles.gamification__items}>
            {isBadgesLoading && gamificationMocks}
            {!userBadgesIsEmpty &&
              userBadges.map((badge) => (
                <GamificationItem
                  key={badge.name}
                  name={badge.name}
                  image={badge.image}
                  icon={Appreaction3Svg}
                  onGamificationClick={() => setOpenedListType(GamificationTypings.ItemTypes.Badges)}
                  count={badge.maxBadgeNum > 0 ? `${badge.badgeCount} из ${badge.maxBadgeNum}` : null}
                />
              ))}
            {userBadgesIsEmpty && <GamificationEmpty isOwner type={GamificationTypings.ItemTypes.Badges} />}
          </span>
        </>
      )}

      <UiModal type={UiModalTypes.Large} isOpen={Boolean(openedListType)}>
        <GamificationList
          isOwner={isOwner}
          onClose={onCloseListHandler}
          openedListType={openedListType}
          toggleAddThanksOpen={toggleAddThankOpen}
          gamificationWasLoaded={gamificationWasLoaded}
        />
      </UiModal>

      <UiModal type={UiModalTypes.Medium} isOpen={isAddThankOpen} onClose={toggleAddThankOpen}>
        <AddThanks onClose={toggleAddThankOpen} userThanksStorage={userThanksStorage} />
      </UiModal>
    </div>
  );
};

export default Gamification;
