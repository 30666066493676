import { useStore } from 'effector-react';
import round from 'lodash/round';
import React, { useCallback, useMemo, useState } from 'react';
import ReactCrop, { Crop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

import UploadSvg from 'ant/components/svg/upload.svg';
import { UiButton } from 'ant/components/ui/button';
import { UiIcon } from 'ant/components/ui/icon/UiIcon';
import { message } from 'ant/components/ui/message';
import { UiModal } from 'ant/components/ui/modals';
import { UiTypography } from 'ant/components/ui/typography';
import { FilePicker } from 'ant/components/widgets/FilePicker/FilePicker';
import authService from 'ant/plugins/authService';
import currentProfileService from 'ant/plugins/currentProfileService';
import { dataUrlToFile } from 'ant/plugins/dataUrlToFile';
import { getImageDimensions } from 'ant/plugins/getImageDimensions';
import { getModalStepsForSingleTitle } from 'ant/plugins/utils/get-modal-steps-for-single-title';
import { uploadFileStorageAttachmentEffectFactory } from 'ant/store/filestorage';
import { FileToUploadModel } from 'ant/types/models/file';
import { FileUploadAccepts } from 'ant/types/models/file-upload-accepts';
import { uploadProfileUserAvatarStorage } from 'profile-frontend/store/profile';
import { CropImageParams } from 'profile-frontend/store/profile/api';

import styles from './AvatarUploadModal.scss';

const UNIT = '%';
const CIRCLE_ASPECT = 1;
const PREVIEW_ASPECT = 10 / 15;
const MAX_FILES_SIZE = 10 * 1024 * 1024;
const MIN_WIDTH = 200;
const MIN_HEIGHT = 300;
const RULES = [
  '• фотография хорошего качества, чёткая, без шумов, крупных пикселей и других искажений;',
  '• ваше лицо хорошо видно и ничто его не заслоняет, в том числе солнцезащитные очки;',
  '• нельзя использовать фотографии с голыми плечами и торсом;',
  '• на итоговой фотографии не должно быть других людей или животных;',
  '• минимальный размер фото 200 × 300 пикселей;',
  '• максимальный вес файла 10 мегабайт.',
];

const imageStyles: React.CSSProperties = { width: '100%' };

const getCenterCropPosition = (aspect: number, imgWidth: number, imgHeight: number): Crop => {
  const crop = { unit: UNIT, aspect } as Crop;

  if (imgWidth > imgHeight * aspect) {
    crop.width = ((imgHeight * aspect) / imgWidth) * 100;
    crop.height = 100;
  } else {
    crop.height = (imgWidth / (aspect * imgHeight)) * 100;
    crop.width = 100;
  }

  crop.x = Math.max((100 - crop.width) / 2, 0);
  crop.y = Math.max((100 - crop.height) / 2, 0);

  return crop;
};

const getCenterCropCirclePosition = (aspect: number, imgWidth: number, imgHeight: number): Crop => {
  const crop = { unit: UNIT, aspect } as Crop;
  const isPortrait = imgHeight > imgWidth;

  crop.width = isPortrait ? 100 : (100 * imgHeight) / imgWidth;
  crop.height = isPortrait ? (100 * imgWidth) / imgHeight : 100;
  crop.x = (100 - crop.width) / 2;
  crop.y = (100 - crop.height) / 2;

  return crop;
};

const normalizeCropData = async (crop: Crop, imageFile: FileToUploadModel): Promise<CropImageParams> => {
  const { x: cropX = 0, y: cropY = 0, width: cropWidth = 0, height: cropHeight = 0 } = crop;
  const { width: imageWidth, height: imageHeight } = await getImageDimensions(imageFile.data);

  const x = round(imageWidth * parseFloat((cropX / 100).toFixed(4)));
  const y = round(imageHeight * parseFloat((cropY / 100).toFixed(4)));
  const width = round(imageWidth * parseFloat((cropWidth / 100).toFixed(4)));
  const height = round(imageHeight * parseFloat((cropHeight / 100).toFixed(4)));

  return { x, y, width, height };
};

type Props = {
  onClose: () => void;
  onAvatarUpdate: (src: string) => void;
};

const AvatarUploadModal: React.FC<Props> = ({ onClose, onAvatarUpdate }) => {
  const userId = String(authService.getCurrentUserId());

  const [crop, setCrop] = useState<Crop>({ unit: UNIT });
  const [cropCircle, setCropCircle] = useState<Crop>({ unit: UNIT });
  const [imageFile, setImageFile] = useState<FileToUploadModel | undefined>();
  const [fileId, setFileId] = useState<number | undefined>();
  const [minCropWidth, setMinCropWidth] = useState(0);
  const [minCropHeight, setMinCropHeight] = useState(0);

  const { uploadProfileUserAvatarEffect } = useMemo(() => uploadProfileUserAvatarStorage(), []);
  const uploadAttachmentEffect = useMemo(() => uploadFileStorageAttachmentEffectFactory(), []);
  const uploadAttachmentInProgress = useStore(uploadAttachmentEffect.pending);
  const uploadAvatarInProgress = useStore(uploadProfileUserAvatarEffect.pending);

  const onLoad = useCallback((img: HTMLImageElement) => {
    setCrop(getCenterCropPosition(PREVIEW_ASPECT, img.width, img.height));
    setCropCircle(getCenterCropCirclePosition(CIRCLE_ASPECT, img.width, img.height));

    setMinCropWidth(Math.ceil((MIN_WIDTH * img.width) / img.naturalWidth));
    setMinCropHeight(Math.ceil((MIN_HEIGHT * img.height) / img.naturalHeight));

    return false;
  }, []);

  const onAddFiles = async (files: FileToUploadModel[]) => {
    if (!files.length) {
      return message.error('Файл не был загружен');
    }

    if (files[0].rawFile.size > MAX_FILES_SIZE) {
      return message.error('Максимальный вес файла должен быть 10 мегабайт.');
    }

    const { data, filename } = files[0];
    const file = await dataUrlToFile(data, filename);
    const { width, height } = await getImageDimensions(data);

    if (width < MIN_WIDTH || height < MIN_HEIGHT) {
      return message.error('Минимальный размер фото 200 × 300 пикселей.');
    }

    setImageFile(files[0]);

    return uploadAttachmentEffect({ file }).then(({ id }) => setFileId(id));
  };

  const onSuccessUpload = (avatar: string) => {
    onClose();
    currentProfileService.currentProfileStorage.refetchWithLastParams();
    onAvatarUpdate(avatar);
  };

  const onSave = async () => {
    if (userId && fileId && imageFile) {
      uploadProfileUserAvatarEffect({
        userId,
        fileId,
        settings: {
          medium: await normalizeCropData(crop, imageFile),
          small: await normalizeCropData(cropCircle, imageFile),
        },
      })
        .then(onSuccessUpload)
        .catch(({ response }) => message.error(response.data.detail || 'Ошибка загрузки'));
    }
  };

  const onRectangleCropChange = useCallback((_: ReactCrop.Crop, percentCrop: ReactCrop.PercentCrop) => {
    if (percentCrop.width !== 0 && percentCrop.height !== 0) {
      setCrop(percentCrop);
    }
  }, []);

  const onCircularCropChange = useCallback((_: ReactCrop.Crop, percentCrop: ReactCrop.PercentCrop) => {
    if (percentCrop.width !== 0 && percentCrop.height !== 0) {
      setCropCircle(percentCrop);
    }
  }, []);

  return (
    <>
      <UiModal.Header>
        <UiModal.Header.Title steps={getModalStepsForSingleTitle('Фото профиля')} />
      </UiModal.Header>
      <UiModal.Content className={styles.avatarUploadModal__content}>
        <UiTypography.Title level={4}>
          Загрузите свою лучшую фотографию и убедитесь, что она соответствует правилам:
        </UiTypography.Title>

        {RULES.map((rule) => (
          <UiTypography.Text key={rule} className={styles.avatarUploadModal__rule}>
            {rule}
          </UiTypography.Text>
        ))}

        <FilePicker onAddFiles={onAddFiles} accept={FileUploadAccepts.ImagePngJpg}>
          <UiButton
            size="large"
            type="secondary"
            loading={uploadAttachmentInProgress}
            disabled={uploadAttachmentInProgress || uploadAvatarInProgress}
            icon={<UiIcon component={UploadSvg} />}
            className={styles.avatarUploadModal__uploadBtn}
          >
            Загрузить фотографию
          </UiButton>
        </FilePicker>

        <span className={styles.avatarUploadModal__crop}>
          <UiTypography.Text>
            {imageFile?.data && 'Область основного фото'}
            <ReactCrop
              crop={crop}
              onImageLoaded={onLoad}
              src={imageFile?.data || ''}
              imageStyle={imageStyles}
              className={styles.avatarUploadModal__cropPreview}
              onChange={onRectangleCropChange}
              keepSelection
              minWidth={minCropWidth}
              minHeight={minCropHeight}
            />
          </UiTypography.Text>

          <UiTypography.Text>
            {imageFile?.data && 'Область миниатюры'}
            <ReactCrop
              circularCrop
              crop={cropCircle}
              src={imageFile?.data || ''}
              imageStyle={imageStyles}
              className={styles.avatarUploadModal__cropPreview}
              onChange={onCircularCropChange}
              keepSelection
              minWidth={minCropWidth}
              minHeight={minCropWidth}
            />
          </UiTypography.Text>
        </span>
      </UiModal.Content>

      <UiModal.Footer className={styles.avatarUploadModal__footer}>
        <UiButton
          loading={uploadAvatarInProgress}
          disabled={!imageFile?.data || uploadAvatarInProgress || uploadAttachmentInProgress}
          size="large"
          type="primary"
          onClick={onSave}
        >
          Сохранить
        </UiButton>
        <UiButton size="large" type="tertiary" onClick={onClose} disabled={uploadAvatarInProgress}>
          Отмена
        </UiButton>
      </UiModal.Footer>
    </>
  );
};

export default AvatarUploadModal;
