import { Modal as AntModal } from 'antd';
import { getAntdModalFuncProps } from './helpers';
import { ConfirmModal, ConfirmModalFuncProps, UiModalBaseType } from './types';

const UiModalBase = AntModal as UiModalBaseType;

const baseConfirm = AntModal.confirm;

UiModalBase.confirm = (modalFuncProps: ConfirmModalFuncProps): ConfirmModal => {
  const modal = baseConfirm(getAntdModalFuncProps(modalFuncProps));
  const baseModalUpdate = modal.update;

  (modal as ConfirmModal).update = (modalFuncUpdateProps: ConfirmModalFuncProps) => {
    baseModalUpdate.call(modal, getAntdModalFuncProps(modalFuncUpdateProps));
  };

  return modal as ConfirmModal;
};

export { UiModalBase };
