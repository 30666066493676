import classNames from 'classnames';
import React from 'react';

import { UiTypography } from 'ant/components/ui/typography';

import styles from './InfoItem@myteam.scss';

type Props = {
  title: string;
  value?: string;
  className?: string;
};

export const InfoItem: React.FC<Props> = ({ title, className, value, children }) => (
  <div className={classNames(styles.infoItem, className)} key={title}>
    <UiTypography.Text type="secondary">{title}</UiTypography.Text>
    {value && <UiTypography.Text className={styles.infoItem__content}>{value}</UiTypography.Text>}
    {children}
  </div>
);
