// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._-_-_-ant-src-components-ui-modals-modal--ui-modal{position:relative;display:flex;justify-content:center;top:0;height:100vh;width:100vw}._-_-_-ant-src-components-ui-modals-modal--ui-modal__form{width:100%;height:100%}._-_-_-ant-src-components-ui-modals-modal--ui-modal_type_fullscreen .ant-modal-content{border-radius:0}._-_-_-ant-src-components-ui-modals-modal--ui-modal .ant-modal-body{font-weight:400;font-size:15px;line-height:20px;display:flex;flex-direction:column;padding:0;height:100%}._-_-_-ant-src-components-ui-modals-modal--ui-modal .ant-modal-content{position:absolute;top:50%;transform:translateY(-50%)}\n", ""]);
// Exports
exports.locals = {
	"ui-modal": "_-_-_-ant-src-components-ui-modals-modal--ui-modal",
	"uiModal": "_-_-_-ant-src-components-ui-modals-modal--ui-modal",
	"ui-modal__form": "_-_-_-ant-src-components-ui-modals-modal--ui-modal__form",
	"uiModal__form": "_-_-_-ant-src-components-ui-modals-modal--ui-modal__form",
	"ui-modal_type_fullscreen": "_-_-_-ant-src-components-ui-modals-modal--ui-modal_type_fullscreen",
	"uiModal_type_fullscreen": "_-_-_-ant-src-components-ui-modals-modal--ui-modal_type_fullscreen"
};
module.exports = exports;
