import React, { useEffect, useState } from 'react';
import useClipboard from 'react-use-clipboard';

import ContactWorkPhoneSvg from 'ant/components/svg/case.svg';
import ContactMailSvg from 'ant/components/svg/contact-mail.svg';
import ContactPhoneSvg from 'ant/components/svg/contact-phone.svg';
import CopySvg from 'ant/components/svg/copy.svg';
import LinkSvg from 'ant/components/svg/link.svg';
import ContactLocationSvg from 'ant/components/svg/location.svg';
import { UiButton } from 'ant/components/ui/button';
import { UiIcon } from 'ant/components/ui/icon/UiIcon';
import { message } from 'ant/components/ui/message';
import { UiTypography } from 'ant/components/ui/typography';
import { formatPhoneNumber, PhoneMask, RegExpPattern } from 'ant/plugins/phone-formatter';

import styles from './Contact.scss';

enum PhoneNames {
  MobilePhone = 'mobilePhone',
  PersonalEmail = 'personalEmail',
  WorkEmail = 'workEmail',
  WorkPhone = 'workPhone',
  ContactLocation = 'contactLocation',
}

export const PhoneIcons: Record<PhoneNames, SvgrComponent> = {
  [PhoneNames.MobilePhone]: ContactPhoneSvg,
  [PhoneNames.PersonalEmail]: ContactMailSvg,
  [PhoneNames.WorkEmail]: ContactMailSvg,
  [PhoneNames.WorkPhone]: ContactWorkPhoneSvg,
  [PhoneNames.ContactLocation]: ContactLocationSvg,
};

type Props = {
  value: string;
  icon?: SvgrComponent;
  iconUrl?: string;
  isPhone?: boolean;
  disableCopy?: boolean;
  title?: string;
  phoneMask?: string;
};

const Contact: React.FC<Props> = ({
  title,
  value,
  isPhone,
  disableCopy,
  icon,
  iconUrl,
  phoneMask = PhoneMask.InternationalRu,
}) => {
  const [isIconUploadError, setIsIconUploadError] = useState(false);
  const [isCopied, setCopied] = useClipboard(value, { successDuration: 300 });

  useEffect(() => {
    if (isCopied) {
      message.success('Скопировано');
    }
  }, [isCopied]);

  const onError = () => setIsIconUploadError(true);
  const showDictsIcon = !icon && iconUrl && !isIconUploadError;
  const label = isPhone ? formatPhoneNumber(value, RegExpPattern.InternationalRu, phoneMask) : value;

  return (
    <span className={styles.contact}>
      {icon && !iconUrl && <UiIcon component={icon} width={20} height={20} />}
      {showDictsIcon && <img src={iconUrl} width={20} height={20} alt="" onError={onError} />}
      {isIconUploadError && <UiIcon component={LinkSvg} width={20} height={20} />}
      <div className={styles.contact__wrapper}>
        {title && <UiTypography.Text className={styles.contact__title}>{title}</UiTypography.Text>}
        <UiTypography.Text className={styles.contact__label}>{label}</UiTypography.Text>
      </div>
      {!disableCopy && (
        <UiButton
          type="link-secondary"
          onClick={setCopied}
          icon={<UiIcon component={CopySvg} width={20} height={20} />}
          className={styles.contact__copyBtn}
        />
      )}
    </span>
  );
};

export default Contact;
