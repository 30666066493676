import classNames from 'classnames';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import ImgPlaceholderSvg from 'ant/components/svg/img-placeholder.svg';
import { UiIcon } from 'ant/components/ui/icon/UiIcon';

import styles from './UiImage.scss';

type ImageStatus = 'normal' | 'error' | 'loading';

export interface UiImageProps
  extends Omit<React.ImgHTMLAttributes<HTMLImageElement>, 'placeholder' | 'onClick'> {
  src?: string;
  wrapperClassName?: string;
  wrapperStyle?: React.CSSProperties;
  placeholder?: React.ReactNode;
  placeholderSize?: number;
}

const DEFAULT_PLACEHOLDER_SIZE = 160;

const UiImage: React.FC<UiImageProps> = (props) => {
  const isLoaded = useRef(false);

  const {
    alt = '',
    src: imgSrc,
    width,
    height,
    placeholder,
    className,
    wrapperStyle,
    wrapperClassName,
    onError: onImageError,
    placeholderSize = DEFAULT_PLACEHOLDER_SIZE,
    ...imageProps
  } = props;
  const isCustomPlaceholder = placeholder && placeholder !== true;

  const [status, setStatus] = useState<ImageStatus>(isCustomPlaceholder ? 'loading' : 'normal');

  const isError = status === 'error';
  const isLoading = status === 'loading';
  const wrapperClass = classNames(
    styles.uiImage,
    {
      [styles.uiImage__loading]: isLoading,
      [styles.uiImage__preview]: isError,
    },
    wrapperClassName,
  );

  const imageClassNames = classNames(
    {
      [styles.uiImage__image]: !isLoading && !isError,
    },
    className,
  );

  const onLoad = () => {
    setStatus('normal');
  };

  const onError = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
    if (onImageError) {
      onImageError(e);
    }

    setStatus('error');
  };

  const getImgRef = (img: HTMLImageElement | null) => {
    isLoaded.current = false;

    if (status !== 'loading') {
      return;
    }

    if (img?.complete && (img.naturalWidth || img.naturalHeight)) {
      isLoaded.current = true;
      onLoad();
    }
  };

  useEffect(() => {
    if (!imgSrc) {
      setStatus('error');
    }

    if (isError) {
      setStatus('normal');
    }

    if (isCustomPlaceholder && !isLoaded.current) {
      setStatus('loading');
    }
  }, [imgSrc]);

  const errorPlaceholder = useMemo(
    () =>
      isError ? (
        <UiIcon
          width={placeholderSize}
          height={placeholderSize}
          component={ImgPlaceholderSvg}
          className={styles.uiImage__placeholder}
        />
      ) : null,
    [isError, placeholderSize],
  );

  return (
    <div
      style={{
        width,
        height,
        ...wrapperStyle,
      }}
      className={wrapperClass}
    >
      {isError ? (
        errorPlaceholder
      ) : (
        <img
          alt={alt}
          ref={getImgRef}
          src={imgSrc}
          onLoad={onLoad}
          onError={onError}
          className={imageClassNames}
          {...imageProps}
        />
      )}

      {status === 'loading' && placeholder}
    </div>
  );
};

export { UiImage };
