import React from 'react';
import { Route } from 'react-router-dom';

import { RouteNames, routes } from './routes';

export type PagesMapping<R = RouteNames> = {
  [key in R & string]: (props: any) => any;
};

export const buildRoutesFromConfig = (pagesMapping: PagesMapping) => {
  return Object.keys(pagesMapping).map((routeName) => {
    const path = (routes as Record<string, string>)[routeName];
    const Component = (pagesMapping as Record<string, (props: any) => any>)[routeName];

    return <Route key={routeName} render={(props) => <Component {...props} />} exact path={path} />;
  });
};
