import { Registry, useComponentRegistry, withRegistry } from '@bem-react/di';
import { ConfigProvider } from 'antd';
import { ConfigProviderProps } from 'antd/lib/config-provider';
import rusLocale from 'antd/lib/locale/ru_RU';
import { useStore } from 'effector-react';
import React, { useCallback, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

import 'antd/dist/antd.variable.less';
import 'ant/assets/scss/styles/fonts-includes.css';
import 'ant/assets/scss/styles/index.scss';
import 'ant/assets/scss/ant-rewriting/index.scss';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import { useAbstractStorage } from 'ant/helpers/hooks/use-abstract-storage';
import currentProfileService from 'ant/plugins/currentProfileService';
import antTheme from 'ant/theme';
import { ThemePicker, themePickerStorage } from 'ant/theme/picker';
import { ThemeProvider, ThemeVariables } from 'ant/theme/provider';

const THEME_VARIABLES = 'themeVariables';
const initialTheme = {
  name: process.env.CUSTOMER || 'b2bcore',
  varsPrefix: ['--', process.env.CUSTOMER, process.env.CUSTOMER && '-', THEME_VARIABLES].join(''),
  variables: antTheme[THEME_VARIABLES] as ThemeVariables,
};

function App(configProps?: ConfigProviderProps) {
  const [theme, setTheme] = useState(initialTheme);
  const { Router } = useComponentRegistry('pages-frontend');

  const profile = useStore(currentProfileService.currentProfileStore);

  useAbstractStorage(themePickerStorage.storage, {
    autoFetchAndRefetch: Boolean(profile?.id),
    autoFetchParams: { userId: profile?.id || '' },
  });

  const { colors } = useStore(themePickerStorage.themePickerState);

  useEffect(() => {
    const variables = { ...theme.variables };

    colors.forEach(({ name, color }) => {
      variables[name] = color;
    });

    setTheme({ ...theme, variables });
  }, [colors]);

  const onChangeTheme = useCallback(
    ({ variables, ...rest }) => setTheme({ ...rest, variables: { ...theme.variables, ...variables } }),
    [theme],
  );

  return (
    <ThemeProvider theme={theme} onChange={onChangeTheme}>
      <ConfigProvider locale={rusLocale} {...configProps}>
        <Router />
      </ConfigProvider>
      <ThemePicker />
    </ThemeProvider>
  );
}

export const runApp = (registry: Registry, configProps?: ConfigProviderProps) => {
  const AppWithRegistry = withRegistry(registry)(App);

  ReactDOM.render(<AppWithRegistry {...configProps} />, document.getElementById('root'));
};
