// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._-_-_-profile-frontend-src-components-profile-sidebar--profile-sidebar-main__content{padding:0 14px 14px}\n", ""]);
// Exports
exports.locals = {
	"profile-sidebar-main__content": "_-_-_-profile-frontend-src-components-profile-sidebar--profile-sidebar-main__content",
	"profileSidebarMain__content": "_-_-_-profile-frontend-src-components-profile-sidebar--profile-sidebar-main__content"
};
module.exports = exports;
