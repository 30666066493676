// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._-_-_-ant-src-components-ui-avatar--ui-avatar svg{color:var(--myteam-themeVariables-colorBrandFaded)}._-_-_-ant-src-components-ui-avatar--ui-avatar__square{display:flex;align-items:center;justify-content:center}._-_-_-ant-src-components-ui-avatar--ui-avatar__circle:after{content:'';display:block;top:0;height:100%;width:100%;position:absolute;border-radius:50%;box-shadow:inset 0 0 4px rgba(41,44,46,0.08)}\n", ""]);
// Exports
exports.locals = {
	"ui-avatar": "_-_-_-ant-src-components-ui-avatar--ui-avatar",
	"uiAvatar": "_-_-_-ant-src-components-ui-avatar--ui-avatar",
	"ui-avatar__square": "_-_-_-ant-src-components-ui-avatar--ui-avatar__square",
	"uiAvatar__square": "_-_-_-ant-src-components-ui-avatar--ui-avatar__square",
	"ui-avatar__circle": "_-_-_-ant-src-components-ui-avatar--ui-avatar__circle",
	"uiAvatar__circle": "_-_-_-ant-src-components-ui-avatar--ui-avatar__circle"
};
module.exports = exports;
