import { AutoComplete as AntAutoComplete, Input as AntInput } from 'antd';
import { AutoCompleteProps } from 'antd/es/auto-complete';
import classNames from 'classnames';
import { OptionData, OptionGroupData } from 'rc-select/es/interface';
import React from 'react';

import DropDownSvg from 'ant/components/svg/drop-down.svg';
import { UiIcon } from 'ant/components/ui/icon/UiIcon';

import styles from './UiAutoComplete.scss';

export const AnimatedDropDownIcon = () => <UiIcon width={20} height={20} component={DropDownSvg} />;

export interface UiAutoCompleteProps extends AutoCompleteProps {
  placeholder?: string;
}

export type UiOptionData = OptionData | OptionGroupData;

export type UiAntInputRef = AntInput;

export type UiAutoCompleteComponent = React.ForwardRefExoticComponent<
  React.RefAttributes<UiAntInputRef> & UiAutoCompleteProps
>;

export const UiAutoComplete: UiAutoCompleteComponent = React.forwardRef((props, ref) => {
  const { suffixIcon, placeholder, size, value, className, ...rest } = props;
  const suffix = suffixIcon !== undefined ? suffixIcon : <AnimatedDropDownIcon />;

  return (
    <AntAutoComplete
      ref={ref}
      value={value}
      getPopupContainer={(trigger) => trigger.parentElement}
      className={classNames(styles.uiAutoComplete, className)}
      {...rest}
    >
      <AntInput size={size} value={value} placeholder={placeholder} suffix={suffix} />
    </AntAutoComplete>
  );
});

export const UiAutoCompleteOption = AntAutoComplete.Option;
