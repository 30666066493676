import React from 'react';

import { UiTypography } from 'ant/components/ui/typography';
import { ProjectItem } from 'profile-frontend/typings/projects';

export type ProjectsProps = {
  projects: ProjectItem[];
  className: string;
};

const InfoReferencesProjects: React.FC<ProjectsProps> = (props) => {
  const { projects, className } = props;

  return (
    <UiTypography.Text className={className}>
      {projects.map(({ project }) => project.name).join(' • ')}
    </UiTypography.Text>
  );
};

export default InfoReferencesProjects;
