const getPagesUrl = (url: string) => `/pages/${url}`;

class PagesEndpoints {
  static layoutWidgetList = () => getPagesUrl('widgets/list/');

  static widgetDataNew = () => getPagesUrl('widget_data/new/');

  static widgetsTypes = () => getPagesUrl('widgets/types/');

  static widgetData = (id: number) => getPagesUrl(`widgets/${id}/`);

  static userColors = (userId: string) => getPagesUrl(`${userId}/colors/`);
}

export { PagesEndpoints };
