enum Declension {
  Single,
  Two,
  Many,
}
type Expressions = { [K in Declension]: string };

export const declension = (number: number, expressions: Expressions) => {
  let count = Math.abs(number);

  count %= 100;

  if (count >= 5 && count <= 20) {
    return expressions[Declension.Many];
  }

  count %= 10;

  if (count === 1) {
    return expressions[Declension.Single];
  }

  if (count >= 2 && count <= 4) {
    return expressions[Declension.Two];
  }

  return expressions[Declension.Many];
};

export const employeeDeclension: Expressions = {
  [Declension.Single]: 'сотрудник',
  [Declension.Two]: 'сотрудника',
  [Declension.Many]: 'сотрудников',
};

export const memberDeclension: Expressions = {
  [Declension.Single]: 'участник',
  [Declension.Two]: 'участника',
  [Declension.Many]: 'участников',
};

export const colleagueDeclension: Expressions = {
  [Declension.Single]: 'коллега',
  [Declension.Two]: 'коллеги',
  [Declension.Many]: 'коллег',
};

export const peopleDeclension: Expressions = {
  [Declension.Single]: 'человек',
  [Declension.Two]: 'человека',
  [Declension.Many]: 'человек',
};

export const coinsDeclension: Expressions = {
  [Declension.Single]: 'койн',
  [Declension.Two]: 'койна',
  [Declension.Many]: 'койнов',
};

export const seatsDeclension: Expressions = {
  [Declension.Single]: 'место',
  [Declension.Two]: 'места',
  [Declension.Many]: 'мест',
};

export const voteDeclension: Expressions = {
  [Declension.Single]: 'Проголосовал',
  [Declension.Two]: 'Проголосовали',
  [Declension.Many]: 'Проголосовали',
};

/**
 * В винительном падеже
 * Например, "можно взять одного гостя с собой"
 */
export const guestsAccusativeDeclension: Expressions = {
  [Declension.Single]: 'гостя',
  [Declension.Two]: 'гостя',
  [Declension.Many]: 'гостей',
};

export const chapterDeclension: Expressions = {
  [Declension.Single]: 'глава',
  [Declension.Two]: 'главы',
  [Declension.Many]: 'глав',
};

export const lessonDeclension: Expressions = {
  [Declension.Single]: 'урок',
  [Declension.Two]: 'урока',
  [Declension.Many]: 'уроков',
};

export const meetingRoomDeclension: Expressions = {
  [Declension.Single]: 'переговорная',
  [Declension.Two]: 'переговорные',
  [Declension.Many]: 'переговорных',
};

export const questionWordDeclension: Expressions = {
  [Declension.Single]: 'вопрос',
  [Declension.Two]: 'вопроса',
  [Declension.Many]: 'вопросов',
};

export const mistakeDeclension: Expressions = {
  [Declension.Single]: 'ошибка',
  [Declension.Two]: 'ошибки',
  [Declension.Many]: 'ошибок',
};

export const minutesDeclension: Expressions = {
  [Declension.Single]: 'минута',
  [Declension.Two]: 'минуты',
  [Declension.Many]: 'минут',
};

export const secondsDeclension: Expressions = {
  [Declension.Single]: 'секунда',
  [Declension.Two]: 'секунды',
  [Declension.Many]: 'секунд',
};

export const answerDeclension: Expressions = {
  [Declension.Single]: 'ответ',
  [Declension.Two]: 'ответа',
  [Declension.Many]: 'ответов',
};

export const rightDeclension: Expressions = {
  [Declension.Single]: 'правильный',
  [Declension.Two]: 'правильных',
  [Declension.Many]: 'правильных',
};

export const attemptsDeclension: Expressions = {
  [Declension.Single]: 'попытка',
  [Declension.Two]: 'попытки',
  [Declension.Many]: 'попыток',
};

export const leftDeclension: Expressions = {
  [Declension.Single]: 'Осталась',
  [Declension.Two]: 'Осталось',
  [Declension.Many]: 'Осталось',
};

export const groupAccusativeDeclension: Expressions = {
  [Declension.Single]: 'группу',
  [Declension.Two]: 'группы',
  [Declension.Many]: 'групп',
};

export const blockDeclension: Expressions = {
  [Declension.Single]: 'блок',
  [Declension.Two]: 'блока',
  [Declension.Many]: 'блоков',
};
