import { createEvent } from 'effector';

import { api } from 'ant/plugins/api';
import {
  CalendarEventCreateParams,
  CalendarEventModel,
  CalendarEventsSummary,
  CalendarEventUpdateParams,
  CalendarPotentialAttendee,
  CalendarStatusUpdate,
  CalendarList,
  CalendarSubscriptions,
} from 'ant/types/models/calendar';

import { CalendarEndpoints } from './endpoints';

export const fetchCalendarEvent = (id: string) =>
  api.get<CalendarEventModel>({
    url: CalendarEndpoints.exactEvent(id),
  });

export const createCalendarEvent = (data: CalendarEventCreateParams) =>
  api.post<CalendarEventModel>({
    url: CalendarEndpoints.personalNewEvents(),
    data,
  });

export const updateCalendarEvent = ({ id, ...data }: CalendarEventUpdateParams) =>
  api.put<CalendarEventModel>({
    url: CalendarEndpoints.exactEvent(id),
    data,
  });

export const deleteCalendarEvent = (id: string) => api.delete({ url: CalendarEndpoints.exactEvent(id) });

export const updateUserStatus = async (statuses: CalendarStatusUpdate) =>
  api.put({
    url: CalendarEndpoints.eventsStatus(),
    data: statuses,
  });

export const refetchOnCreateUpdateMeetingEvent = createEvent();

// TODO: остатки от рефакторинга - не используемые пока сервисы
const fetchCalendarsList = async () => {
  const { data } = await api.get<CalendarList>({ url: CalendarEndpoints.list() });

  return data;
};

const fetchSubscriptions = async () => {
  const { data } = await api.get<CalendarSubscriptions>({ url: CalendarEndpoints.subscriptions() });

  return data;
};

const fetchPersonalEventsSummary = async () => {
  const { data } = await api.get<CalendarEventsSummary>({ url: CalendarEndpoints.personalSummary() });

  return data;
};

// TODO: Используется. Придумать куда вынести
const fetchEventsPotentialAttendees = async (searchInput: string) => {
  const { data } = await api.get<CalendarPotentialAttendee[]>({
    url: CalendarEndpoints.eventsPotentialAttendees(),
    params: { searchInput },
  });

  return data;
};

export { fetchCalendarsList, fetchSubscriptions, fetchPersonalEventsSummary, fetchEventsPotentialAttendees };
