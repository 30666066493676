import React, { useState, useMemo, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import CloseSVG from 'ant/components/svg/close.svg';
import { UiBadge } from 'ant/components/ui/badge';
import { UiButton } from 'ant/components/ui/button';
import { UiModal } from 'ant/components/ui/modals';
import { UiTabs } from 'ant/components/ui/tabs';
import { useAbstractStorage } from 'ant/helpers/hooks/use-abstract-storage';
import { createArrayMock } from 'ant/plugins/createMocks';
import { ListMeta } from 'ant/types/api';
import GamificationBadgePost from 'profile-frontend/components/gamification/badge-post/GamificationBadgePost';
import GamificationEmpty from 'profile-frontend/components/gamification/empty/GamificationEmpty';
import GamificationThankPost from 'profile-frontend/components/gamification/thank-post/GamificationThankPost';
import { profileFullInfoStorage } from 'profile-frontend/storage';
import {
  getGamificationUserBadgesStorage,
  getGamificationUserThanksStorage,
} from 'profile-frontend/store/gamification';
import { Gamification } from 'profile-frontend/typings/gamification';
import { ProfileInfo } from 'profile-frontend/typings/profile-info';

import styles from './GamificationList.scss';
import ListItem from './item/ListItem';
import ItemLoading from './item/loading/ItemLoading';

type GamificationItemTypesTitle = { type: Gamification.ItemTypes; title: string; meta?: ListMeta };

type Props = {
  isOwner: boolean;
  onClose: () => void;
  toggleAddThanksOpen: () => void;
  openedListType: string;
  gamificationWasLoaded: boolean;
};

const gamificationTitles: Array<GamificationItemTypesTitle> = [
  { type: Gamification.ItemTypes.Thanks, title: 'Благодарности' },
  { type: Gamification.ItemTypes.Badges, title: 'Достижения' },
];

const MOCKS_LENGTH = 4;
const gamificationListMocks = createArrayMock(MOCKS_LENGTH, (_, key) => <ItemLoading key={key} />);

const GamificationList: React.FC<Props> = (props) => {
  const { isOwner, onClose, openedListType, toggleAddThanksOpen, gamificationWasLoaded } = props;

  const { id: userId } = useParams<{ id: string }>();
  const [activeKey, setActiveKey] = useState<string>(Gamification.ItemTypes.Thanks);

  const userThanksStorage = useMemo(() => getGamificationUserThanksStorage({ userId }), [userId]);
  const { data: userThanks, loading: isUserThanksLoading } = useAbstractStorage(userThanksStorage.storage, {
    autoFetchAndRefetch: true,
    resetStoreOnUnmount: true,
  });

  const userBadgesStorage = useMemo(() => getGamificationUserBadgesStorage({ userId }), [userId]);
  const { data: userBadges, loading: isUserBadgesLoading } = useAbstractStorage(userBadgesStorage.storage, {
    autoFetchAndRefetch: true,
    resetStoreOnUnmount: true,
  });

  const { data: profileFullInfoData } = useAbstractStorage(profileFullInfoStorage.storage);

  const { main: toUser } = profileFullInfoData || ({} as ProfileInfo);

  useEffect(() => {
    setActiveKey(openedListType);
  }, [openedListType]);

  useEffect(() => {
    if (gamificationWasLoaded) {
      userThanksStorage.storage.refetchWithLastParams();
      userBadgesStorage.storage.refetchWithLastParams();
    }
  }, [gamificationWasLoaded]);

  const isBadges = activeKey === Gamification.ItemTypes.Badges;
  const isThanks = activeKey === Gamification.ItemTypes.Thanks;
  const loading = (isThanks && isUserThanksLoading) || (isBadges && isUserBadgesLoading);

  const showBadge = isOwner || userBadges?.items?.length !== 0;
  const tabs = showBadge ? gamificationTitles : gamificationTitles.slice(0, -1);
  const showGamificationEmptyThanks = isThanks && !isUserThanksLoading && !userThanks?.items?.length;
  const showGamificationEmptyBadge = isBadges && !isUserBadgesLoading && !userBadges?.items?.length;
  const showGamificationEmpty = showGamificationEmptyThanks || showGamificationEmptyBadge;

  const onChangeActiveKey = (key: string) => setActiveKey(key);

  const TabItem = ({ title, meta }: { title: string; meta?: ListMeta }) => {
    return (
      <>
        {title}
        {meta?.objectsTotal !== undefined && meta?.objectsTotal > 0 && (
          <UiBadge count={meta?.objectsTotal} className={styles.gamificationList__count} />
        )}
      </>
    );
  };

  return (
    <>
      <UiModal.Header className={styles.gamificationList__header}>
        <UiTabs defaultActiveKey={activeKey} activeKey={activeKey} onChange={onChangeActiveKey}>
          {tabs.map((list) => {
            const tabItem = (
              <TabItem
                title={list.title}
                meta={list.type === Gamification.ItemTypes.Thanks ? userThanks?.meta : userBadges?.meta}
              />
            );

            return <UiTabs.Tab className={styles.gamificationList__tab} key={list.type} tab={tabItem} />;
          })}
        </UiTabs>

        <UiButton
          type="link"
          onClick={onClose}
          icon={<CloseSVG />}
          className={styles.gamificationList__headerClose}
        />
      </UiModal.Header>

      <UiModal.Content>
        {loading && gamificationListMocks}

        {isThanks &&
          toUser &&
          !isUserThanksLoading &&
          userThanks?.items?.map((thank) => (
            <ListItem
              key={thank.id}
              onClose={onClose}
              createdAt={thank.createdAt}
              headerUser={thank.fromUser}
              headerToUser={toUser}
              type={Gamification.ItemTypes.Thanks}
            >
              <GamificationThankPost thank={{ ...thank, toUser }} onClose={onClose} />
            </ListItem>
          ))}

        {isBadges &&
          !isUserBadgesLoading &&
          userBadges?.items?.map((badges) => (
            <ListItem
              key={badges.id}
              onClose={onClose}
              headerUser={badges.user}
              createdAt={badges.awardedAt}
              type={Gamification.ItemTypes.Badges}
            >
              <GamificationBadgePost badges={badges} />
            </ListItem>
          ))}

        {showGamificationEmpty && (
          <GamificationEmpty
            isOwner={isOwner}
            toggleAddThanksOpen={toggleAddThanksOpen}
            className={styles.gamificationList__empty}
            type={isThanks ? Gamification.ItemTypes.Thanks : Gamification.ItemTypes.Badges}
          />
        )}
      </UiModal.Content>
    </>
  );
};

export default GamificationList;
