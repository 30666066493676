import { getWidgetListStorage, getWidgetTypesStorage } from 'ant/store/pages';

import { getLayoutStorage } from '~/store/layouts';
import { getPopulatedLayoutStorage } from '~/store/populated-layout';

export const widgetListStorage = getWidgetListStorage();

export const populatedLayoutStorage = getPopulatedLayoutStorage();

export const layoutStorage = getLayoutStorage();

export const widgetTypesStorage = getWidgetTypesStorage();
