import { AxiosResponse } from 'axios';
import { format, isValid } from 'date-fns';

import { api } from 'ant/plugins/api';
import { Endpoints } from 'ant/plugins/endpoints';
import { UserProfile } from 'ant/types/models/user';
import { ProfileEditContactsForm } from 'profile-frontend/components/profile-edit/contacts/ProfileEditContacts';
// import { UserIdParams } from 'profile-frontend/store/profile';
import { AdditionalInfo } from 'profile-frontend/typings/additional-info';
import { Biography } from 'profile-frontend/typings/biography';
import { JobHistory } from 'profile-frontend/typings/job';

import { ProfileEndpoints } from './endpoints';

export type ProfileFullInfoParams = { userId: string };

export type MainEditParams = ProfileFullInfoParams & Partial<UserProfile>;
export type JobsHistoryParams = ProfileFullInfoParams & { jobHistory: JobHistory[] };
export type BiographyParams = ProfileFullInfoParams & Biography;
export type ProjectsParams = ProfileFullInfoParams & { projects: number[] };
export type ContactsParams = ProfileFullInfoParams & ProfileEditContactsForm;

export const getProfileFullInfoEndpoint = ({ userId }: ProfileFullInfoParams) =>
  ProfileEndpoints.fullInfo(userId);

export type PatchProfileProjectParams = { name: string };

export type CropImageParams = {
  x: number;
  y: number;
  width: number;
  height: number;
};

export type ProfileUserAvatarUploadParams = {
  userId: string;
  fileId: number;
  settings: {
    medium: CropImageParams;
    small: CropImageParams;
  };
};

export type ProfileEditAboutWorkForm = {
  projects: number[];
};

export const profileUserAvatarUpload = <T = unknown>({
  userId,
  fileId,
  settings,
}: ProfileUserAvatarUploadParams): Promise<AxiosResponse<T>> =>
  api.post({
    url: ProfileEndpoints.userAvatarCreate(userId),
    data: { fileId, settings },
  });

export const patchProfileContacts = <T = unknown>({
  userId,
  mobilePhone,
  personalEmail,
  externalLinks,
  otherContacts,
  otherLinks,
}: ContactsParams): Promise<AxiosResponse<T>> =>
  api.patch({
    url: ProfileEndpoints.userContacts(userId),
    data: {
      mobilePhone,
      personalEmail,
      externalLinks,
      otherContacts,
      otherLinks,
    },
  });

export const createProfileProject = <T = unknown>({
  name,
}: PatchProfileProjectParams): Promise<AxiosResponse<T>> =>
  api.post({
    url: ProfileEndpoints.projects(),
    data: { name },
  });

export const patchProfileUserProjects = <T = unknown>({
  userId,
  projects,
}: ProfileFullInfoParams & ProfileEditAboutWorkForm): Promise<AxiosResponse<T>> =>
  api.patch({
    url: ProfileEndpoints.userProjects(userId),
    data: { projects },
  });

export const patchProfileUserAdditionalInfo = <T = unknown>({
  id,
  cars,
  devices,
}: AdditionalInfo): Promise<AxiosResponse<T>> =>
  api.patch({
    url: ProfileEndpoints.userAdditionalInfo(id),
    data: {
      cars: cars.filter((car) => car.carRid || car.carNumber),
      devices: devices.filter((device) => device.typeRid || device.deviceRid || device.osRid),
    },
  });

export const postUserSubscribtion = ({ userId }: ProfileFullInfoParams) =>
  api.post({
    url: Endpoints.blogUserFollow(userId),
  });

export const patchUserSubscribtion = ({ userId }: ProfileFullInfoParams) =>
  api.patch({
    url: Endpoints.blogUserUnfollow(userId),
  });

export const patchMainProfile = <T = unknown>({
  userId,
  ...params
}: MainEditParams): Promise<AxiosResponse<T>> => {
  const { isBirthYearHidden, birthDate } = params;
  const data = {} as MainEditParams;

  if ('actualAddress' in params) {
    data.actualAddress = params.actualAddress;
  }

  if (params.metroStationRid === null || Boolean(params.metroStationRid?.length)) {
    data.metroStationRid = params.metroStationRid;
  }

  if (params.metroStationRid === null || Boolean(params.townLiveRid?.length)) {
    data.townLiveRid = params.townLiveRid;
  }

  if (isBirthYearHidden !== undefined) {
    data.isBirthYearHidden = isBirthYearHidden;
  }

  if (birthDate && isValid(new Date(birthDate))) {
    data.birthDate = format(new Date(birthDate), 'yyyy-MM-dd');
  }

  return api.patch({ url: ProfileEndpoints.userMain(userId), data });
};

export const patchJobHistoryProfile = <T = unknown>({
  userId,
  jobHistory,
}: JobsHistoryParams): Promise<AxiosResponse<T>> =>
  api.patch({
    url: ProfileEndpoints.userJobHistory(userId),
    data: jobHistory.map(({ pk, ...job }) => (!pk ? job : { pk, ...job })),
  });

export const patchBiographyProfile = <T = unknown>({
  userId,
  ...data
}: BiographyParams): Promise<AxiosResponse<T>> =>
  api.patch({
    url: ProfileEndpoints.userBiography(userId),
    data: {
      ...data,
      education: {
        ...data.education,
        additional: data.education.additional.map(({ id, ...educ }) => (!id ? educ : { id, ...educ })),
      },
      languages: data.languages,
    },
  });
