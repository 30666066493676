import React, { useCallback, useContext } from 'react';

import { UiButton } from 'ant/components/ui/button';
import { UiIcon } from 'ant/components/ui/icon';
import { ScrollbarContextMyteam } from 'ant/components/ui/scrollbars/UiScrollbars@myteam';
import { UiSkeleton } from 'ant/components/ui/skeleton';
import { UiTooltip } from 'ant/components/ui/tooltip';
import { useInitialScrollTop } from 'ant/helpers/hooks/use-initial-scroll-top';
import { sendNativeAppEvent } from 'ant/plugins/native-app-bridge@myteam';
import CallIcon from 'profile-frontend/components/svg/actions-call@myteam.svg';
import MessageIcon from 'profile-frontend/components/svg/actions-message@myteam.svg';
import VideoIcon from 'profile-frontend/components/svg/actions-videocall@myteam.svg';
import { Devision } from 'profile-frontend/typings/devision@myteam';
import { ProfileInfo as ProfileInfoType } from 'profile-frontend/typings/profile-info';

import InfoContacts from './InfoContacts@myteam';
import styles from './ProfileSidebarInfo@myteam.scss';
import InfoItems from './items/InfoItems@myteam';
import InfoFullName from './name/InfoFullName@myteam';

const PROFILE_FULL_NAME_MOCK = (
  <div className={styles.profileSidebarInfo__skeletonWrapper_fullName}>
    <UiSkeleton width={200} height={20} loading />
    <UiSkeleton width={200} height={20} loading />
  </div>
);
const PROFILE_INFO_MOCK = (
  <>
    <div className={styles.profileSidebarInfo__separator} />
    <div className={styles.profileSidebarInfo__skeletonWrapper}>
      <UiSkeleton width={90} height={20} loading />
      <UiSkeleton width={200} height={20} loading />
    </div>
    <div className={styles.profileSidebarInfo__separator} />
    <div className={styles.profileSidebarInfo__skeletonWrapper}>
      <UiSkeleton width={90} height={20} loading />
      <UiSkeleton width={200} height={20} loading />
      <UiSkeleton width={90} height={20} loading />
      <UiSkeleton width={200} height={20} loading />
    </div>
    <div className={styles.profileSidebarInfo__separator} />
    <div className={styles.profileSidebarInfo__skeletonWrapper}>
      <UiSkeleton loading width={90} height={20} />
      <UiSkeleton loading width={200} height={20} />
    </div>
  </>
);

type ProfileInfoProps = {
  userData: ProfileInfoType | null;
  loading: boolean;
  isOwner: boolean;
  userId?: string;
  devisionData?: Devision;
};

const ProfileSidebarInfo: React.FC<ProfileInfoProps> = (props) => {
  const { userData, userId, loading, isOwner, devisionData } = props;
  const email = userData && userData.main && userData.main.email;
  const { current } = useContext(ScrollbarContextMyteam);

  useInitialScrollTop(current, [userId]);

  const messageHandler = useCallback(() => {
    sendNativeAppEvent('message', { userId: email });
  }, [email]);
  const callHandler = useCallback(() => {
    sendNativeAppEvent('call', { userId: email, video: false });
  }, [email]);
  const videCallHandler = useCallback(() => {
    sendNativeAppEvent('call', { userId: email, video: true });
  }, [email]);

  const guestActionButtons = (
    <div className={styles.profileSidebarInfo__actionButtons}>
      <UiTooltip title="Написать">
        <UiButton onClick={messageHandler} className={styles.profileSidebarInfo__actionButton}>
          <UiIcon width="18" height="18" component={MessageIcon} />
        </UiButton>
      </UiTooltip>
      <UiTooltip title="Позвонить">
        <UiButton onClick={callHandler} className={styles.profileSidebarInfo__actionButton}>
          <UiIcon width="18" height="18" component={CallIcon} />
        </UiButton>
      </UiTooltip>
      <UiTooltip title="Видео звонок">
        <UiButton onClick={videCallHandler} className={styles.profileSidebarInfo__actionButton}>
          <UiIcon width="20" height="14" component={VideoIcon} />
        </UiButton>
      </UiTooltip>
    </div>
  );

  return (
    <>
      {!loading && userData ? <InfoFullName userData={userData} /> : PROFILE_FULL_NAME_MOCK}
      {!isOwner && email && guestActionButtons}
      <InfoContacts userId={userId} />
      {!loading && userData ? (
        <InfoItems userId={userId} userData={userData} devisionData={devisionData} />
      ) : (
        PROFILE_INFO_MOCK
      )}
    </>
  );
};

export default ProfileSidebarInfo;
