import React from 'react';

import { useQueryConfig } from '@di/pages-frontend/src/components/routes/router@myteam/query-config';
import { UiAvatar, UiAvatarProps } from 'ant/components/ui/avatar/UiAvatar';

interface UiAvatarMyteamProps extends UiAvatarProps {
  email: string;
}

const SIZES = [64, 128, 256, 1024];

export const UiAvatarMyteam: React.FC<UiAvatarMyteamProps> = (props) => {
  const {
    avatarUrl = 'https://ub.internal.myteam.mail.ru/api/v65/files/avatar/get',
    aimsid,
  } = useQueryConfig();

  let src = '';
  const { size } = props;

  if (size && aimsid && props?.email && avatarUrl) {
    const imgSize = SIZES.find((s) => s >= size * 2) || SIZES[SIZES.length - 1];

    src = `${avatarUrl}?targetSn=${props?.email}&aimsid=${aimsid}&size=${imgSize}&r=0`;
  }

  return <UiAvatar {...props} src={src} />;
};
