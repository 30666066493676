import { api } from 'ant/plugins/api';
import { FileModel, FileToUploadModelWithoutData } from 'ant/types/models/file';

import { FileStorageEndpoints } from './endpoints';

export enum FileStorageApiVersions {
  v1,
  v2,
}

export type UploadFileStorageAttachmentParams = {
  file: File;
  onUploadProgress?: (percent: number) => void;
  apiVersion?: FileStorageApiVersions;
};

export type UploadFileStorageMultipleAttachmentsParams = {
  files: FileToUploadModelWithoutData[];
  onUploadProgress?: (id: string, percent: number) => void;
  apiVersion: FileStorageApiVersions;
};

export const uploadFileStorageAttachment = async ({
  file,
  onUploadProgress,
  apiVersion = FileStorageApiVersions.v2,
}: UploadFileStorageAttachmentParams) => {
  const formData = new window.FormData();

  formData.append('file', file);

  return api
    .post<FileModel>({
      url:
        apiVersion === FileStorageApiVersions.v1
          ? FileStorageEndpoints.fileUpload()
          : FileStorageEndpoints.fileUploadV2(),
      data: formData,
      onUploadProgress:
        onUploadProgress &&
        ((progressEvent) => {
          onUploadProgress(Math.round((progressEvent.loaded * 100) / progressEvent.total));
        }),
    })
    .then((response) => response.data);
};
