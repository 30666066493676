import { api } from 'ant/plugins/api';
import { ProfileEndpoints } from 'profile-frontend/store/skills/endpoints';

export type ApproveSkillParams = { skillId: string; isAccept: boolean };
export type DeleteSkillParams = { skillId: string };
export type SaveSkillParams = { name: string; skillId?: string | number };
export type GetSkillsStorageParams = { userId: string };

type ApproveSkillApiParams = ApproveSkillParams & GetSkillsStorageParams;
type SaveSkillApiParams = SaveSkillParams & GetSkillsStorageParams;
type DeleteSkillApiParams = DeleteSkillParams & GetSkillsStorageParams;

export const approveSkill = ({ userId, skillId, isAccept }: ApproveSkillApiParams) =>
  api.put({
    url: ProfileEndpoints.skillAction(userId, skillId),
    data: { action: isAccept ? 'accept' : 'decline' },
  });

export const saveSkill = ({ userId, name, skillId }: SaveSkillApiParams) =>
  api.post({
    url: ProfileEndpoints.skills(userId),
    data: [{ name, skillId }],
  });

export const deleteSkill = ({ userId, skillId }: DeleteSkillApiParams) =>
  api.delete({
    url: ProfileEndpoints.skillAction(userId, skillId),
  });
