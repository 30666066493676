import { AxiosError } from 'axios';
import { createEffect, createEvent } from 'effector';

import { abstractStorageFactory } from 'ant/helpers/storage/abstract-storage-factory';
import { Endpoints } from 'ant/plugins/endpoints';
import { buildEndpointWithQueryParams } from 'ant/plugins/utils/endpoint-builder';
import { getDictsStorage } from 'ant/store/dictionaries';
import { CreateDictionaryRecord } from 'ant/store/dictionaries/api';
import { Dictionaries } from 'ant/store/dictionaries/dictionaries';
import { DictDataParams, DictMatchTypes, DictPaginated } from 'ant/types/api';
import { FetchSelectEntityParams } from 'ant/types/creatable-multiselect';
import { ContactLinkAttributes, RecordResponse } from 'ant/types/dictionary';
import { UserProfile } from 'ant/types/models/user';
import { AdditionalInfo } from 'profile-frontend/typings/additional-info';
import { Biography } from 'profile-frontend/typings/biography';
import { Contacts } from 'profile-frontend/typings/contacts';
import { FollowingsResponse } from 'profile-frontend/typings/followings';
import { JobHistory } from 'profile-frontend/typings/job';
import { ProfileInfo } from 'profile-frontend/typings/profile-info';
import { Project, ProjectItem } from 'profile-frontend/typings/projects';

import {
  profileUserAvatarUpload,
  ProfileUserAvatarUploadParams,
  ProfileFullInfoParams,
  patchProfileContacts,
  createProfileProject,
  patchProfileUserProjects,
  getProfileFullInfoEndpoint,
  postUserSubscribtion,
  patchUserSubscribtion,
  patchMainProfile,
  patchBiographyProfile,
  patchJobHistoryProfile,
  BiographyParams,
  MainEditParams,
  JobsHistoryParams,
  patchProfileUserAdditionalInfo,
  ProjectsParams,
  ContactsParams,
} from './api';
import { ProfileEndpoints } from './endpoints';

export type UserIdParams = { userId: string };

export const getProfileUserFollowingsIdsStorage = () => {
  const storage = abstractStorageFactory<FollowingsResponse, string[], string[], UserIdParams>({
    endpointBuilder: ({ userId }) => Endpoints.blogUserFollowings(userId),
    defaultValue: [],
    dataMapper: ({ items }) => items.map(({ toUser }) => String(toUser.keycloakUser?.keycloakId)),
  });

  const followUserEffect = createEffect<UserIdParams, unknown, AxiosError>(postUserSubscribtion);

  const unFollowUserEffect = createEffect<UserIdParams, unknown, AxiosError>(patchUserSubscribtion);

  storage.store.on(followUserEffect.done, (state, { params }) => ({
    ...state,
    data: [...state.data, params.userId],
  }));

  storage.store.on(unFollowUserEffect.done, (state, { params }) => ({
    ...state,
    data: state.data.filter((id) => id !== params.userId),
  }));

  return { storage, followUserEffect, unFollowUserEffect };
};

type ProfileUserAvatarUploadResponse = {
  large: string;
  medium: string;
  small: string;
};

export const uploadProfileUserAvatarStorage = () => {
  const uploadProfileUserAvatarEffect = createEffect<ProfileUserAvatarUploadParams, string, AxiosError>({
    handler: (params) =>
      profileUserAvatarUpload<ProfileUserAvatarUploadResponse>(params).then(
        (response) => response.data.medium,
      ),
  });

  return { uploadProfileUserAvatarEffect };
};

export const getProfileContactsStorage = ({ userId }: UserIdParams) => {
  const storage = abstractStorageFactory<Contacts, Contacts, null>({
    endpointBuilder: () => ProfileEndpoints.userContacts(userId),
    defaultValue: null,
    cancelPendingRequestOnFetch: true,
  });

  const patchProfileContactsEffect = createEffect<ContactsParams, Contacts, AxiosError>((params) =>
    patchProfileContacts<Contacts>(params).then((response) => response.data),
  );

  storage.store.on(patchProfileContactsEffect.doneData, (state, contacts) =>
    state.data
      ? {
          ...state,
          data: {
            ...state.data,
            ...contacts,
          },
        }
      : state,
  );

  const profileContactsStore = storage.store.map(({ data }) => data);

  return { storage, profileContactsStore, patchProfileContactsEffect };
};

export const getProfileContactsDictsStorage = () => {
  const messengerDictsStorage = getDictsStorage<RecordResponse<ContactLinkAttributes>, DictDataParams>({
    dictionaryName: Dictionaries.Names.ContactTypes,
    dataBuilder: ({ kind: { type, value } }) => ({ kind: { type, value } }),
  });

  const messengerDictsOptionsStore = messengerDictsStorage.storage.store.map(({ data }) =>
    data.map((messenger) => ({
      value: messenger.id,
      icon: messenger.attributes.icon,
      label: messenger.attributes.name,
    })),
  );

  const externalLinkDictsStorage = getDictsStorage<RecordResponse<ContactLinkAttributes>, DictDataParams>({
    dictionaryName: Dictionaries.Names.ContactTypes,
    dataBuilder: ({ kind: { type, value } }) => ({ kind: { type, value } }),
  });

  const externalLinkDictsOptionsStore = externalLinkDictsStorage.storage.store.map(({ data }) =>
    data.map((link) => ({
      recordId: link.id,
      icon: link.attributes.icon,
      name: link.attributes.name,
      prefix: link.attributes.prefix,
      placeholder: link.attributes.placeholder,
    })),
  );

  return {
    messengerDictsStorage,
    messengerDictsOptionsStore,
    externalLinkDictsStorage,
    externalLinkDictsOptionsStore,
  };
};

export const convertSearchProjectToDictLikeEntity = (project: Project) =>
  ({
    id: String(project.id),
    attributes: { name: project.name },
  } as RecordResponse);

export const projectsDictLikeStorage = abstractStorageFactory<
  DictPaginated<Project>,
  RecordResponse[],
  RecordResponse[],
  FetchSelectEntityParams
>({
  endpointBuilder: () =>
    buildEndpointWithQueryParams(ProfileEndpoints.searchProjects(), { ordering: 'name' }),
  dataBuilder: ({ value }) => ({ name: { type: DictMatchTypes.Icontains, value } }),
  requestMethod: 'post',
  defaultValue: [],
  cancelPendingRequestOnFetch: true,
  dataMapper: (data) => data.items.map(convertSearchProjectToDictLikeEntity),
});

export const createProjectDictLikeEffect = createEffect<CreateDictionaryRecord, RecordResponse, AxiosError>(
  ({ attributes }) =>
    attributes.name
      ? createProfileProject<Project>({ name: attributes.name }).then(({ data }) =>
          convertSearchProjectToDictLikeEntity(data),
        )
      : Promise.reject(),
);

projectsDictLikeStorage.store.on(createProjectDictLikeEffect.doneData, (state, newProject) => ({
  ...state,
  data: [...state.data, newProject],
}));

export const getProfileFullInfoStorage = () => {
  const storage = abstractStorageFactory<ProfileInfo, ProfileInfo, null, ProfileFullInfoParams>({
    endpointBuilder: getProfileFullInfoEndpoint,
    defaultValue: null,
    cancelPendingRequestOnFetch: true,
  });

  const updateAvatarEvent = createEvent<string>();

  storage.store.on(updateAvatarEvent, (state, avatarSrc) =>
    state.data
      ? {
          ...state,
          data: {
            ...state.data,
            main: {
              ...state.data?.main,
              avatar: avatarSrc,
            },
          },
        }
      : state,
  );

  const patchMainProfileEffect = createEffect<MainEditParams, UserProfile, AxiosError>((params) =>
    patchMainProfile<UserProfile>(params).then((response) => response.data),
  );

  storage.store.on(patchMainProfileEffect.doneData, (state, userProfile) =>
    state.data
      ? {
          ...state,
          data: {
            ...state.data,
            main: userProfile,
          },
        }
      : state,
  );

  const patchJobHistoryProfileEffect = createEffect<JobsHistoryParams, JobHistory[], AxiosError>((params) =>
    patchJobHistoryProfile<JobHistory[]>(params).then((response) => response.data),
  );

  storage.store.on(patchJobHistoryProfileEffect.doneData, (state, jobHistory) =>
    state.data
      ? {
          ...state,
          data: {
            ...state.data,
            jobHistory,
          },
        }
      : state,
  );

  const patchBiographyProfileEffect = createEffect<BiographyParams, Biography, AxiosError>((params) =>
    patchBiographyProfile<Biography>(params).then((response) => response.data),
  );

  storage.store.on(patchBiographyProfileEffect.doneData, (state, biography) =>
    state.data
      ? {
          ...state,
          data: {
            ...state.data,
            biography,
          },
        }
      : state,
  );

  const patchProfileUserAdditionalInfoEffect = createEffect<AdditionalInfo, AdditionalInfo, AxiosError>(
    (params) => patchProfileUserAdditionalInfo<AdditionalInfo>(params).then(({ data }) => data),
  );

  storage.store.on(patchProfileUserAdditionalInfoEffect.doneData, (state, additionalInfo) =>
    state.data
      ? {
          ...state,
          data: {
            ...state.data,
            additionalInfo,
          },
        }
      : state,
  );

  const patchProfileUserProjectsEffect = createEffect<ProjectsParams, ProjectItem[], AxiosError>((params) =>
    patchProfileUserProjects<ProjectItem[]>(params).then(({ data }) => data),
  );

  storage.store.on(patchProfileUserProjectsEffect.doneData, (state, projects) =>
    state.data
      ? {
          ...state,
          data: {
            ...state.data,
            projects,
          },
        }
      : state,
  );
  return {
    storage,
    updateAvatarEvent,
    patchJobHistoryProfileEffect,
    patchMainProfileEffect,
    patchBiographyProfileEffect,
    patchProfileUserAdditionalInfoEffect,
    patchProfileUserProjectsEffect,
  };
};

export type UploadProfileUserAvatarStorage = ReturnType<typeof uploadProfileUserAvatarStorage>;
export type GetProfileContactsStorage = ReturnType<typeof getProfileContactsStorage>;
export type GetProfileContactsDictsStorage = ReturnType<typeof getProfileContactsDictsStorage>;
export type GetProfileFullInfoStorage = ReturnType<typeof getProfileFullInfoStorage>;
