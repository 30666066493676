import React from 'react';

import { UiAvatarMyteam } from 'ant/components/ui/avatar/UiAvatar@myteam';
import { UserProfile } from 'ant/types/models/user';

import styles from './ProfileSidebarAvatar@myteam.scss';

type Props = {
  profileMain: UserProfile | undefined;
  loading: boolean;
};

const ProfileSidebarAvatar: React.FC<Props> = ({ profileMain, loading }) => {
  return (
    <div className={styles.sidebarAvatar__wrapper}>
      <UiAvatarMyteam
        shape="circle"
        size={76}
        email={loading || !profileMain?.email ? '' : profileMain.email}
        className={styles.sidebarAvatar__image}
      />
    </div>
  );
};

export default ProfileSidebarAvatar;
