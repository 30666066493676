import classnames from 'classnames';
import React from 'react';

import { UiTable, TableProps } from 'ant/components/ui/table/UiTable';

import styles from './SlotsTable.scss';
import { SlotsTableSelection, SlotsTableSelectionComponent } from './selection/SlotsTableSelection';
import { SlotsTableSlot, SlotsTableSlotComponent } from './slot/SlotsTableSlot';

type SlotsTableComposition = {
  Slot: SlotsTableSlotComponent;
  Selection: SlotsTableSelectionComponent;
};

const SlotsTable: React.FC<TableProps> & SlotsTableComposition = ({ className, ...restProps }) => {
  return (
    <UiTable
      scroll={{ x: 'max-content' }}
      sticky
      {...restProps}
      pagination={false}
      className={classnames(styles.slotsTable, className)}
    />
  );
};

SlotsTable.Slot = SlotsTableSlot;
SlotsTable.Selection = SlotsTableSelection;

export { SlotsTable };
