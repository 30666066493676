// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".layouts-component-base-layout--base-layout{width:100%;min-height:100%;flex-grow:1;display:flex;flex-direction:column;overflow:hidden;margin:0 auto}.layouts-component-base-layout--base-layout__widget-gallery_shift{margin-bottom:230px}.layouts-component-base-layout--base-layout__row{display:flex;flex-direction:row;margin-left:16px;margin-bottom:16px}.layouts-component-base-layout--base-layout__center{flex-grow:1;height:auto;margin-top:16px}.layouts-component-base-layout--base-layout__content{flex-grow:1;margin-top:16px;height:auto}.layouts-component-base-layout--base-layout__column{display:flex;flex-direction:column;margin:8px;min-width:calc(373px + 16px)}@media (max-width: 1439px){.layouts-component-base-layout--base-layout__column{max-width:calc(373px + 16px)}}@media (min-width: 1440px) and (max-width: 1799px){.layouts-component-base-layout--base-layout__column{max-width:calc(361px + 16px)}}@media screen and (min-width: 1800px){.layouts-component-base-layout--base-layout__column{max-width:calc(386px + 16px)}}.layouts-component-base-layout--base-layout__column-wrapper{display:flex;flex-direction:column}@media screen and (min-width: 1800px){.layouts-component-base-layout--base-layout__column-wrapper{flex-direction:row}}.layouts-component-base-layout--base-layout__column>*{height:auto}.layouts-component-base-layout--base-layout__column>*>:not(:only-child){margin:8px}.layouts-component-base-layout--base-layout__column>*>:nth-child(2){margin:0}.layouts-component-base-layout--base-layout__column:nth-child(1){margin-bottom:0}.layouts-component-base-layout--base-layout__column:nth-child(2){margin-top:0}@media screen and (min-width: 1800px){.layouts-component-base-layout--base-layout__column:nth-child(1){margin-right:0;margin-bottom:8px}.layouts-component-base-layout--base-layout__column:nth-child(2){margin-left:0;margin-top:8px}}\n", ""]);
// Exports
exports.locals = {
	"base-layout": "layouts-component-base-layout--base-layout",
	"baseLayout": "layouts-component-base-layout--base-layout",
	"base-layout__widget-gallery_shift": "layouts-component-base-layout--base-layout__widget-gallery_shift",
	"baseLayout__widgetGallery_shift": "layouts-component-base-layout--base-layout__widget-gallery_shift",
	"base-layout__row": "layouts-component-base-layout--base-layout__row",
	"baseLayout__row": "layouts-component-base-layout--base-layout__row",
	"base-layout__center": "layouts-component-base-layout--base-layout__center",
	"baseLayout__center": "layouts-component-base-layout--base-layout__center",
	"base-layout__content": "layouts-component-base-layout--base-layout__content",
	"baseLayout__content": "layouts-component-base-layout--base-layout__content",
	"base-layout__column": "layouts-component-base-layout--base-layout__column",
	"baseLayout__column": "layouts-component-base-layout--base-layout__column",
	"base-layout__column-wrapper": "layouts-component-base-layout--base-layout__column-wrapper",
	"baseLayout__columnWrapper": "layouts-component-base-layout--base-layout__column-wrapper"
};
module.exports = exports;
