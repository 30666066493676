import { Avatar, Badge, AvatarProps } from 'antd';
import classNames from 'classnames';
import React from 'react';

import AvatarUserSvg from 'ant/components/svg/avatar-user.svg';
import { UiIcon } from 'ant/components/ui/icon';

import styles from './UiAvatar.scss';

const ccn = classNames.bind(styles);

export interface UiAvatarProps extends Exclude<AvatarProps, 'size'> {
  size?: number;
  withBadge?: boolean;
}

export const UiAvatar: React.FC<UiAvatarProps> = (props) => {
  const { icon, size: propSize, className, withBadge = false, shape = 'circle', ...avatarProps } = props;

  const size = withBadge ? 64 : propSize;
  const Icon = icon || <UiIcon component={AvatarUserSvg} height={size} width={size} />;

  const classNameStyles = {
    [styles.uiAvatar__circle]: shape === 'circle',
    [styles.uiAvatar__square]: shape === 'square',
  };

  const AvatarComponent = (
    <Avatar
      style={{ minWidth: size, minHeight: size }}
      {...avatarProps}
      shape={shape}
      size={size}
      icon={Icon}
      className={ccn(styles.uiAvatar, classNameStyles, className)}
    />
  );

  return withBadge ? (
    <Badge dot count={Number(withBadge)} offset={[-10, 10]}>
      {AvatarComponent}
    </Badge>
  ) : (
    AvatarComponent
  );
};
