import { useStore } from 'effector-react';
import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import LinkSvg from 'ant/components/svg/link.svg';
import { UiIcon } from 'ant/components/ui/icon/UiIcon';
import { UiSkeleton } from 'ant/components/ui/skeleton';
import { UiWidgetHeader } from 'ant/components/ui/widget-header';
import { useAbstractStorage } from 'ant/helpers/hooks/use-abstract-storage';
import { createArrayMock } from 'ant/plugins/createMocks';
import { DictMatchTypes } from 'ant/types/api';
import { ContactDictKinds } from 'ant/types/dictionary';
import SocialLink from 'profile-frontend/components/contacts/social-link/SocialLink';
import QRCard from 'profile-frontend/components/qr-card/QRCard';
import { getProfileContactsDictsStorage, getProfileContactsStorage } from 'profile-frontend/store/profile';

import styles from './Contacts.scss';
import Contact, { PhoneIcons } from './contact/Contact';
import WorkplaceStatus, { WorkplaceStatuses } from './workplace-status/WorkplaceStatus';

const MOCKS_COUNT = 5;
const ContactsMocks = createArrayMock(MOCKS_COUNT, (_, id) => (
  <span key={id} className={styles.profileContacts__loadingItem}>
    <UiSkeleton loading height={18} width={170} />
    <UiSkeleton loading height={18} width={18} />
  </span>
));

type ContactsParams = {
  QRCodeBgColor?: string;
};

const Contacts: React.FC<ContactsParams> = (params?: ContactsParams) => {
  const { id: userId } = useParams<{ id: string }>();
  const profileContactsStorage = useMemo(() => getProfileContactsStorage({ userId }), [userId]);
  const {
    messengerDictsStorage,
    messengerDictsOptionsStore,
    externalLinkDictsStorage,
    externalLinkDictsOptionsStore,
  } = useMemo(getProfileContactsDictsStorage, []);

  useAbstractStorage(messengerDictsStorage.storage, {
    autoFetchAndRefetch: true,
    autoFetchParams: { kind: { type: DictMatchTypes.Exact, value: ContactDictKinds.Messenger } },
  });

  useAbstractStorage(externalLinkDictsStorage.storage, {
    autoFetchAndRefetch: true,
    autoFetchParams: { kind: { type: DictMatchTypes.Exact, value: ContactDictKinds.ExternalLink } },
  });

  const { data: contacts, loading } = useAbstractStorage(profileContactsStorage.storage, {
    autoFetchAndRefetch: userId.length > 0,
  });

  const hasLinks = Boolean(contacts?.externalLinks?.length) || Boolean(contacts?.otherLinks?.length);

  const messengerDictOptions = useStore(messengerDictsOptionsStore);
  const messengerIconByRecordId = useMemo(() => {
    return new Map(messengerDictOptions.map(({ value: recordId, icon }) => [recordId, icon]));
  }, [messengerDictOptions]);

  const externalLinkDictsOptions = useStore(externalLinkDictsOptionsStore);
  const externalLinkIconByRecordId = useMemo(() => {
    return new Map(
      externalLinkDictsOptions.map(({ recordId, icon, prefix }) => [recordId, { icon, prefix }]),
    );
  }, [externalLinkDictsOptions]);

  return (
    <div className={styles.profileContacts}>
      <UiWidgetHeader title="Контакты" className={styles.profileContacts__header}>
        <WorkplaceStatus status={WorkplaceStatuses.OutOfWorkspace} />
      </UiWidgetHeader>

      <span className={styles.profileContacts__wrapper}>
        {loading && ContactsMocks}

        {contacts?.workPhone && <Contact isPhone value={contacts?.workPhone} icon={PhoneIcons.workPhone} />}
        {contacts?.mobilePhone && (
          <Contact isPhone value={contacts?.mobilePhone} icon={PhoneIcons.mobilePhone} />
        )}
        {contacts?.workEmail && <Contact value={contacts?.workEmail} icon={PhoneIcons.workEmail} />}
        {contacts?.personalEmail && (
          <Contact value={contacts?.personalEmail} icon={PhoneIcons.personalEmail} />
        )}

        {contacts?.otherContacts?.map((contact) => (
          <Contact
            key={contact.recordId}
            value={contact.value}
            iconUrl={messengerIconByRecordId.get(contact.recordId)}
            isPhone={/\D/gi.test(contact.value)}
          />
        ))}

        {hasLinks && (
          <span className={styles.profileContacts__links}>
            <UiIcon
              component={LinkSvg}
              width={20}
              height={20}
              className={styles.profileContacts__linksMarker}
            />

            <span className={styles.profileContacts__linksContainer}>
              {contacts?.externalLinks?.map((contact) => (
                <SocialLink
                  key={contact.recordId}
                  value={contact.value}
                  name={contact.name}
                  iconUrl={externalLinkIconByRecordId.get(contact.recordId)?.icon}
                  prefixUrl={externalLinkIconByRecordId.get(contact.recordId)?.prefix}
                />
              ))}

              {contacts?.otherLinks?.map((contact) => (
                <SocialLink key={contact.name} value={contact.value} name={contact.name} />
              ))}
            </span>
          </span>
        )}
      </span>

      <QRCard loading={loading} QRCodeBgColor={params?.QRCodeBgColor} />
    </div>
  );
};

export default Contacts;
