import { useComponentRegistry } from '@bem-react/di';
import React from 'react';

import styles from './CommonLayout.scss';

import { DynamicRenderComponent } from '~/components/layouts/component/dynamic-render-component/DynamicRenderComponent';
import { CommonLayoutPaddedBlock } from '~/components/layouts/mail/common/padded-block/CommonLayoutPaddedBlock';

type CommonLayoutComposition = React.FC & {
  PaddedBlock: typeof CommonLayoutPaddedBlock;
};

const Navbar = () => <DynamicRenderComponent modulePath="Navbar" />;

export const CommonLayout: CommonLayoutComposition = ({ children }) => {
  const { Header } = useComponentRegistry('pages-frontend');

  return (
    <div className={styles.commonLayout}>
      <div className={styles.commonLayout__wrapper}>
        <div className={styles.commonLayout__header}>
          <Header />
        </div>
        <div className={styles.commonLayout__navbar}>
          <Navbar />
        </div>
        <div className={styles.commonLayout__main}>
          <div id="breadcrumb-container" className={styles.commonLayout__breadcrumbContainer} />
          {children}
        </div>
      </div>
    </div>
  );
};

CommonLayout.PaddedBlock = CommonLayoutPaddedBlock;
