import { AxiosError } from 'axios';
import { createStore, createEvent, createEffect } from 'effector';

import { abstractStorageFactory } from 'ant/helpers/storage/abstract-storage-factory';
import { buildEndpointWithQueryParams } from 'ant/plugins/utils/endpoint-builder';
import { PagesEndpoints } from 'ant/store/pages/endpoints';
import { themeVariables } from 'ant/theme';
import { saveThemePickerUserColors, SaveThemePickerUserColorsParams } from 'ant/theme/picker/store/api';

import { ColorThemePicker, ThemePickerState } from '../typings';

const defaultValueColors: ColorThemePicker[] = [
  {
    name: 'colorBrand',
    color: themeVariables.colorBrand,
    description:
      'Фирменный цвет, используется в топбаре, кнопках основного действия, блоках, которые необходимо выделить.',
  },
  {
    name: 'colorInputOnBrand',
    color: themeVariables.colorInputOnBrand,
    description:
      'Цвет интерактивных элементов на фирменном фоне: например, поле поиска в топбаре, кнопка уведомлений.',
  },
  {
    name: 'colorHoverOnBrand',
    color: themeVariables.colorHoverOnBrand,
    description:
      'Цвет при наведении на интерактивные элементы с цветом colorBrandPrimary (кнопки) и colorInputOnPrimary (поле поиска в топбаре)',
  },
  {
    name: 'colorLinkBrand',
    color: themeVariables.colorLinkBrand,
    description: 'Цвет для текстовых ссылок',
  },
  {
    name: 'colorBrandFaded',
    color: themeVariables.colorBrandFaded,
    description: 'Цвет сердечек в посте по умолчанию и рамка фокуса интерактивных элементов',
  },
  {
    name: 'colorBrand32',
    color: themeVariables.colorBrand32,
    description:
      'Вариации брендового цвета с прозрачностью для использования во второстепенных интерактивных элементах',
  },
  {
    name: 'colorBrand16',
    color: themeVariables.colorBrand16,
    description: '',
  },
  {
    name: 'colorBrand08',
    color: themeVariables.colorBrand08,
    description: '',
  },
  {
    name: 'colorBrand04',
    color: themeVariables.colorBrand04,
    description: '',
  },
  {
    name: 'colorTextOnBrand',
    color: themeVariables.colorTextOnBrand,
    description:
      'Цвет основного текста и иконок на брендовом фоне (кнопки, текст на плашках, топбаре). НЕ используем на элементах, цвет которых статичный: тултипы, карточки новостей и т.д.',
  },
];

export type GetThemePickerColorsParams = {
  userId: string;
};

const getThemePickerStorage = () => {
  const storage = abstractStorageFactory<
    ColorThemePicker[],
    ColorThemePicker[],
    ColorThemePicker[],
    GetThemePickerColorsParams
  >({
    endpointBuilder: ({ userId }) => buildEndpointWithQueryParams(PagesEndpoints.userColors(userId)),
    defaultValue: defaultValueColors,
    dataMapper: (colors) => colors,
    cancelPendingRequestOnFetch: true,
  });

  const saveThemePickerUserColorsEffect = createEffect<
    SaveThemePickerUserColorsParams & GetThemePickerColorsParams,
    ColorThemePicker[],
    AxiosError
  >((params) => saveThemePickerUserColors<ColorThemePicker[]>(params).then((response) => response.data));

  const themePickerState = createStore<ThemePickerState>({
    isOpen: false,
    colors: [],
  });

  const setIsOpenThemePickerEvent = createEvent<boolean>();
  const setColorThemePickerEvent = createEvent<Omit<ColorThemePicker, 'description'>>();
  const cancelEditThemePickerEvent = createEvent();

  themePickerState
    .on(storage.store, (state, { data: colors }) => ({
      ...state,
      colors: colors.length > 0 ? colors : defaultValueColors,
    }))
    .on(saveThemePickerUserColorsEffect.doneData, (state, colors) => ({
      ...state,
      isOpen: false,
      colors: colors.length > 0 ? colors : defaultValueColors,
    }))
    .on(setIsOpenThemePickerEvent, (state, isOpen) => ({ ...state, isOpen }))
    .on(setColorThemePickerEvent, (state, { color, name }) => ({
      ...state,
      colors: state.colors.map((colorItem) =>
        colorItem.name === name ? { ...colorItem, color } : colorItem,
      ),
    }))
    .on(cancelEditThemePickerEvent, (state) => {
      const { data: colors } = storage.store.getState();

      return { ...state, isOpen: false, colors };
    });

  return {
    storage,
    themePickerState,
    setIsOpenThemePickerEvent,
    setColorThemePickerEvent,
    cancelEditThemePickerEvent,
    saveThemePickerUserColorsEffect,
  };
};

export { getThemePickerStorage };

export type GetThemePickerStorage = ReturnType<typeof getThemePickerStorage>;
