import React from 'react';
import { useParams } from 'react-router-dom';

import { UiScrollbarsMyteam } from 'ant/components/ui/scrollbars/UiScrollbars@myteam';
import { useAbstractStorage } from 'ant/helpers/hooks/use-abstract-storage';
import authService from 'ant/plugins/authService';
import ProfileSidebarHeader from 'profile-frontend/components/profile/sidebar/header/ProfileSidebarHeader@myteam';
import { profileFullInfoStorage } from 'profile-frontend/storage';
import { Devision } from 'profile-frontend/typings/devision@myteam';

import styles from './ProfileSidebarMain@myteam.scss';
import ProfileSidebarAvatar from './avatar/ProfileSidebarAvatar@myteam';
import ProfileSidebarInfo from './info/ProfileSidebarInfo@myteam';

type ProfileProps = {
  userId?: string;
  devisionData?: Devision;
};

const ProfileSidebarMain = (props?: ProfileProps) => {
  const ownerId = authService.getCurrentUserId();
  const params = useParams<{ id: string }>();
  const id = props?.userId || params.id;
  const isOwner = ownerId === id;
  // const history = useHistory();

  const { data: profileInfoData, loading: profileFullInfoLoading } = useAbstractStorage(
    profileFullInfoStorage.storage,
    {
      autoFetchAndRefetch: true,
      autoFetchParams: {
        userId: id,
      },
    },
  );

  return (
    <>
      <ProfileSidebarHeader />
      <UiScrollbarsMyteam contentClassName={styles.profileSidebarMain__content}>
        <ProfileSidebarAvatar profileMain={profileInfoData?.main} loading={profileFullInfoLoading} />
        <ProfileSidebarInfo
          userData={profileInfoData}
          loading={profileFullInfoLoading}
          isOwner={isOwner}
          userId={id}
          devisionData={props?.devisionData}
        />
      </UiScrollbarsMyteam>
    </>
  );
};

export default ProfileSidebarMain;
