// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._-_-_-profile-frontend-src-components--page-main{display:flex;flex-direction:column;min-height:100%;flex-grow:1;width:100%;margin:0 auto;overflow:hidden}._-_-_-profile-frontend-src-components--page-main__section-container{height:auto}._-_-_-profile-frontend-src-components--page-main__section-container_moved-up{margin-top:-20px}@media screen and (min-width: 1800px){._-_-_-profile-frontend-src-components--page-main__section-container_moved-center{padding:0 133px}}._-_-_-profile-frontend-src-components--page-main__big-section{margin:0 16px}._-_-_-profile-frontend-src-components--page-main__row{display:flex;flex-direction:row;margin-left:16px;margin-bottom:16px}._-_-_-profile-frontend-src-components--page-main__infinity-scroll{flex-grow:1;margin-top:16px;height:auto}._-_-_-profile-frontend-src-components--page-main__row-wrapper{display:flex;flex-direction:column}@media screen and (min-width: 1800px){._-_-_-profile-frontend-src-components--page-main__row-wrapper{flex-direction:row}}._-_-_-profile-frontend-src-components--page-main__column{display:flex;flex-direction:column;margin:8px}._-_-_-profile-frontend-src-components--page-main__column:nth-child(1){margin-bottom:0}._-_-_-profile-frontend-src-components--page-main__column:nth-child(2){margin-top:0}@media screen and (min-width: 1800px){._-_-_-profile-frontend-src-components--page-main__column:nth-child(1){margin-right:0;margin-bottom:8px}._-_-_-profile-frontend-src-components--page-main__column:nth-child(2){margin-left:0;margin-top:8px}}._-_-_-profile-frontend-src-components--page-main__column-item{height:auto;margin:8px}._-_-_-profile-frontend-src-components--page-main__time-slots{padding-top:16px}\n", ""]);
// Exports
exports.locals = {
	"page-main": "_-_-_-profile-frontend-src-components--page-main",
	"pageMain": "_-_-_-profile-frontend-src-components--page-main",
	"page-main__section-container": "_-_-_-profile-frontend-src-components--page-main__section-container",
	"pageMain__sectionContainer": "_-_-_-profile-frontend-src-components--page-main__section-container",
	"page-main__section-container_moved-up": "_-_-_-profile-frontend-src-components--page-main__section-container_moved-up",
	"pageMain__sectionContainer_movedUp": "_-_-_-profile-frontend-src-components--page-main__section-container_moved-up",
	"page-main__section-container_moved-center": "_-_-_-profile-frontend-src-components--page-main__section-container_moved-center",
	"pageMain__sectionContainer_movedCenter": "_-_-_-profile-frontend-src-components--page-main__section-container_moved-center",
	"page-main__big-section": "_-_-_-profile-frontend-src-components--page-main__big-section",
	"pageMain__bigSection": "_-_-_-profile-frontend-src-components--page-main__big-section",
	"page-main__row": "_-_-_-profile-frontend-src-components--page-main__row",
	"pageMain__row": "_-_-_-profile-frontend-src-components--page-main__row",
	"page-main__infinity-scroll": "_-_-_-profile-frontend-src-components--page-main__infinity-scroll",
	"pageMain__infinityScroll": "_-_-_-profile-frontend-src-components--page-main__infinity-scroll",
	"page-main__row-wrapper": "_-_-_-profile-frontend-src-components--page-main__row-wrapper",
	"pageMain__rowWrapper": "_-_-_-profile-frontend-src-components--page-main__row-wrapper",
	"page-main__column": "_-_-_-profile-frontend-src-components--page-main__column",
	"pageMain__column": "_-_-_-profile-frontend-src-components--page-main__column",
	"page-main__column-item": "_-_-_-profile-frontend-src-components--page-main__column-item",
	"pageMain__columnItem": "_-_-_-profile-frontend-src-components--page-main__column-item",
	"page-main__time-slots": "_-_-_-profile-frontend-src-components--page-main__time-slots",
	"pageMain__timeSlots": "_-_-_-profile-frontend-src-components--page-main__time-slots"
};
module.exports = exports;
