import classNames from 'classnames';
import React, { useContext } from 'react';

import ArrowBackSVG from 'ant/components/svg/arrow-back.svg';
import CloseSVG from 'ant/components/svg/close.svg';
import { UiBadge } from 'ant/components/ui/badge';
import { UiButton } from 'ant/components/ui/button';
import { UiTruncateMarkup } from 'ant/components/ui/truncate-markup';

import { UiModalContext } from '../context';
import { UiModalSteps } from '../typings';
import styles from './UiModalHeader.scss';

const ccn = classNames.bind(styles);

export interface UiModalHeaderProps {
  className?: string;
  hasBottomBorder?: boolean;
}

type ModalHeaderTitleComponent = React.FC<UiModalHeaderTitleProps>;
type ModalHeaderButtonsComponent = React.FC;
type ModalHeaderUnderTitleComponent = React.FC;

type HeaderComposition = {
  Title: ModalHeaderTitleComponent;
  Buttons: ModalHeaderButtonsComponent;
  UnderTitle: ModalHeaderUnderTitleComponent;
};

export type UiModalHeaderComponent = React.FC<UiModalHeaderProps> & HeaderComposition;

export type UiModalHeaderTitleProps = {
  wrapperClassName?: string;
  className?: string;
  noCloseIcon?: boolean;
  truncate?: boolean;
  ellipsis?: React.ReactNode | ((element: React.ReactNode) => React.ReactNode);
  steps: UiModalSteps;
  currentStep?: number;
  onStepBack?: (nextStep: number) => void;
  showZeroBadge?: boolean;
};

const UiModalHeader: UiModalHeaderComponent = (props) => {
  const { children, className, hasBottomBorder = false } = props;

  const modalHeaderClassName = ccn(
    styles.uiModalHeader,
    {
      [styles.uiModalHeader_withBorder]: hasBottomBorder,
    },
    className,
  );

  return <div className={modalHeaderClassName}>{children}</div>;
};

const UiModalHeaderTitle: ModalHeaderTitleComponent = (props) => {
  const {
    currentStep = 1,
    onStepBack,
    wrapperClassName,
    noCloseIcon,
    className,
    steps,
    truncate = false,
    ellipsis,
    children,
    showZeroBadge = false,
  } = props;
  const { onClose } = useContext(UiModalContext);

  const titleWrapperClassName = ccn(
    styles.uiModalHeader__titleWrapper,
    {
      [styles.uiModalHeader__titleWrapper_biggerPaddingRight]: true,
    },
    wrapperClassName,
  );
  const titleClassName = ccn(styles.uiModalHeader__titleText, className);

  const currentStepTitle = steps[currentStep]?.title;
  const currentBadgeValue = steps[currentStep]?.badgeValue;
  const hasBadge = currentBadgeValue !== undefined && currentBadgeValue !== null;

  const shouldRenderStepBack = onStepBack && currentStep > 1;

  const handleStepBack = () => {
    if (onStepBack) {
      onStepBack(currentStep - 1);
    }
  };

  return (
    <div className={titleWrapperClassName}>
      {onClose && !noCloseIcon && (
        <UiButton
          size="middle"
          type="link"
          onClick={onClose}
          className={styles.uiModalHeader__closeIcon}
          icon={<CloseSVG />}
        />
      )}
      {shouldRenderStepBack && (
        <UiButton
          type="link"
          onClick={handleStepBack}
          className={styles.uiModalHeader__stepBackIcon}
          icon={<ArrowBackSVG />}
        />
      )}
      <UiTruncateMarkup truncate={truncate} ellipsis={ellipsis}>
        <span className={titleClassName}>{currentStepTitle}</span>
      </UiTruncateMarkup>

      {hasBadge && (
        <UiBadge
          className={styles.uiModalHeader__badge}
          count={currentBadgeValue}
          showZero={showZeroBadge}
          overflowCount={9999999}
        />
      )}

      {children}
    </div>
  );
};

const UiModalHeaderButtons: ModalHeaderButtonsComponent = ({ children }) => (
  <div className={styles.uiModalHeader__buttons}>{children}</div>
);

const UiModalHeaderUnderTitle: ModalHeaderButtonsComponent = ({ children }) => (
  <div className={styles.uiModalHeader__underTitle}>{children}</div>
);

UiModalHeader.Title = UiModalHeaderTitle;
UiModalHeader.Buttons = UiModalHeaderButtons;
UiModalHeader.UnderTitle = UiModalHeaderUnderTitle;

export { UiModalHeader, UiModalHeaderTitle };
