import React, { useCallback, useRef, useState } from 'react';
import { SketchPicker, ColorState, ColorChangeHandler } from 'react-color';

import { UiTypography } from 'ant/components/ui/typography/UiTypography';
import { useOnClickOutside } from 'ant/helpers/hooks/use-on-click-outside';
import { getColorBasedOnBackgroundColor } from 'ant/plugins/utils/get-color-based-on-background-color';
import { ColorThemePicker } from 'ant/theme/picker/typings';

import styles from './ColorPicker.scss';

enum ColorSourceTypes {
  Hex = 'hex',
  Rgb = 'rgb',
  Hsl = 'hsl',
  Hsv = 'hsv',
}

type Props = {
  color: ColorThemePicker;
  onChange: (colorThemePicker: Omit<ColorThemePicker, 'description'>) => void;
};

const ColorPicker: React.FC<Props> = ({ color: selectedColorProps, onChange: onChangeColorThemePicker }) => {
  const { color: selectedColor, name, description } = selectedColorProps;
  const [color, setColor] = useState(selectedColor);
  const [isColorPickerOpen, setIsColorPickerOpen] = useState(false);

  const getColorResult = ({ source, hex, rgb }: ColorState): string => {
    let colorValue = hex;

    if (source === ColorSourceTypes.Rgb) {
      colorValue = `rgba(${[rgb.r, rgb.g, rgb.b, rgb.a].join(',')})`;
    }

    return colorValue;
  };

  const onChange: ColorChangeHandler<ColorState> = (colorResult) => setColor(getColorResult(colorResult));
  const onChangeComplete: ColorChangeHandler<ColorState> = useCallback(
    (colorResult) => {
      onChangeColorThemePicker({ name, color: getColorResult(colorResult) });
    },
    [name],
  );

  const onClickOpenPickColor = () => setIsColorPickerOpen(true);
  const onClickOutsideOverlay = () => setIsColorPickerOpen(false);

  const sketchPickerRef = useRef<HTMLDivElement>(null);

  useOnClickOutside(sketchPickerRef, onClickOutsideOverlay);

  return (
    <div className={styles.colorPicker}>
      <span ref={sketchPickerRef}>
        <button
          type="button"
          onClick={onClickOpenPickColor}
          style={{ background: color, color: getColorBasedOnBackgroundColor(color) }}
          className={styles.colorPicker__palette}
        >
          {color.startsWith(ColorSourceTypes.Rgb) ? color : color.toUpperCase()}
        </button>
        {isColorPickerOpen && (
          <SketchPicker
            color={color}
            onChange={onChange}
            onChangeComplete={onChangeComplete}
            className={styles.colorPicker__picker}
          />
        )}
      </span>

      <UiTypography.Title level={3}>{name}</UiTypography.Title>
      {description && (
        <UiTypography.Text type="secondary" className={styles.colorPicker__description}>
          {description}
        </UiTypography.Text>
      )}
    </div>
  );
};

export { ColorPicker };
