import format from 'date-fns/format';
import isValid from 'date-fns/isValid';
import ru from 'date-fns/locale/ru';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router';

import { UiAvatar } from 'ant/components/ui/avatar';
import { UiButton } from 'ant/components/ui/button';
import { UiTypography } from 'ant/components/ui/typography';
import { getFullNameWithoutPatronymic } from 'ant/plugins/name-formatters';
import { getRoutePath, RouteNames } from 'ant/plugins/routes';
import { ProjectsProps } from 'profile-frontend/components/profile/info/references/InfoReferencesProjects@default';
import { ProfileInfo } from 'profile-frontend/typings/profile-info';

import styles from './InfoReferences.scss';

const titleStyle: React.CSSProperties = { marginBottom: '4px' };
const subTitleStyle: React.CSSProperties = { margin: '0' };

type Props = {
  userData: ProfileInfo;
  InfoReferencesProjects: React.FC<ProjectsProps>;
};

const InfoReferences: React.FC<Props> = (props) => {
  const history = useHistory();

  const { userData, InfoReferencesProjects } = props;
  const { job, main, projects } = userData;

  const { fullName, startOfWork } = main;
  const { position, boss } = job;

  const startOfWorkValue =
    startOfWork && isValid(new Date(startOfWork))
      ? `с ${format(new Date(startOfWork), 'dd MMMM yyyy', {
          locale: ru,
        })}`
      : null;

  const handleLinkClick = useCallback((id: string) => {
    history.push(getRoutePath(RouteNames.Profile, { id }));
  }, []);

  const projectsContent = projects && projects.length > 0 && (
    <InfoReferencesProjects projects={projects} className={styles.infoReferences__projects} />
  );

  const bossContent = boss && (
    <span className={styles.infoReferences__links}>
      <UiButton
        onClick={() => handleLinkClick(boss.id)}
        type="link-secondary"
        className={styles.infoReferences__linksItem}
      >
        <UiAvatar src={boss.avatar} size={24} className={styles.infoReferences__linksItemIcon} />
        <UiTypography.Text className={styles.infoReferences__linksItemTitle}>
          {getFullNameWithoutPatronymic(boss.fullName)}
        </UiTypography.Text>
      </UiButton>
    </span>
  );

  const jobSinceContent = startOfWorkValue && <UiTypography.Text>{startOfWorkValue}</UiTypography.Text>;

  const referencesItems = [
    {
      title: 'Проекты',
      content: projectsContent,
    },
    {
      title: 'Руководители',
      content: bossContent,
    },
    {
      title: 'В компании',
      content: jobSinceContent,
    },
  ];

  const referencesContent = referencesItems.map(
    ({ content, title }) =>
      Boolean(content) && (
        <div className={styles.infoReferences__wrapper} key={title}>
          <UiTypography.Text type="secondary">{title}</UiTypography.Text>
          {content}
        </div>
      ),
  );

  return (
    <div className={styles.infoReferences}>
      <span className={styles.infoReferences__header}>
        {fullName && (
          <UiTypography.Title level={1} style={titleStyle}>
            {getFullNameWithoutPatronymic(fullName)}
          </UiTypography.Title>
        )}

        {position && (
          <UiTypography.Title level={4} type="secondary" style={subTitleStyle}>
            {position}
          </UiTypography.Title>
        )}
      </span>

      <div className={styles.infoReferences__content}>{referencesContent}</div>
    </div>
  );
};

export default InfoReferences;
