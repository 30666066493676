import React from 'react';

import { RouteNames, routes } from 'ant/plugins/routes';
import { PagesMapping } from 'ant/plugins/routes-builder';

// import { PageProfile } from '~/components/layouts/mail/profile/PageProfile@myteam';
import { PageStructureAdapter } from '~/components/layouts/mail/structure/PageStructureAdapter';
import Page, { Pages } from '~/components/pages/Page';

routes[RouteNames.OrgStructure] = '/structure/:id?/team/:teamId?/user/:userId?';
routes[RouteNames.Profile] = '/structure/:id?/team/:teamId?/user/:userId?';

export const pagesMap: PagesMapping = {
  [RouteNames.Main]: () => <PageStructureAdapter />,
  [RouteNames.OrgStructureRoot]: () => <PageStructureAdapter />,
  [RouteNames.OrgStructure]: () => <PageStructureAdapter />,
  [RouteNames.ProfileEdit]: () => <Page name={Pages.ProfileEdit} />,
};
