import { Spin } from 'antd';
import classnames from 'classnames';
import React, { useEffect } from 'react';

import SpinnerSvg from 'ant/components/svg/spinner.svg';
import { UiIcon } from 'ant/components/ui/icon/UiIcon';

interface Props extends React.ComponentProps<typeof Spin> {
  height?: number | string;
  width?: number | string;
  delayMs?: number;
  containerStyle?: React.CSSProperties;
  circle?: boolean;
  circleClassName?: string;
}

export const UiSpinner: React.FC<Props> = ({
  height,
  width,
  delayMs,
  containerStyle: containerStyleProps = {},
  size = 'large',
  circle,
  circleClassName,
  ...restProps
}) => {
  const [visible, setVisibility] = React.useState(!delayMs);
  const circleIcon = circle ? (
    <UiIcon
      className={classnames('anticon-spin', circleClassName)}
      spin
      component={SpinnerSvg}
      width={width}
      height={height}
    />
  ) : (
    undefined
  );

  useEffect(() => {
    const timer =
      delayMs !== undefined
        ? window.setTimeout(() => {
            setVisibility(true);
          }, delayMs)
        : 0;

    return () => (delayMs ? window.clearTimeout(timer) : undefined);
  }, [delayMs]);

  const containerStyle: React.CSSProperties = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: height || '100%',
    width: width || '100%',
    ...containerStyleProps,
  };

  return (
    <div style={containerStyle}>{visible && <Spin indicator={circleIcon} size={size} {...restProps} />}</div>
  );
};
