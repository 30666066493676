import React, { useEffect, useState } from 'react';

import { DynamicRenderComponentType, WidgetModule } from '@di/core-components';
import { LongreadProps } from 'components-frontend/components/longread/Longread';
import { CreateUpdateBlogModalProps } from 'groups-frontend/components/blogs/create-blog-modal-content/CreateUpdateBlogAdapter';
import { BlogFilesModalProps } from 'groups-frontend/components/blogs/files/files-modal/BlogFilesModalContent';

export type DynamicModuleProps =
  | WidgetModule
  | LongreadProps
  | CreateUpdateBlogModalProps
  | BlogFilesModalProps
  | Record<string, React.ReactNode>;

const DynamicRenderComponent: DynamicRenderComponentType<DynamicModuleProps> = ({
  modulePath,
  moduleProps,
}) => {
  const [module, setModule] = useState<React.ReactElement | null>(null);
  const getDynamicModule = () => {
    import(/* webpackMode: "lazy-once" */ `modules/${modulePath}`)
      .then(({ default: Widget }) => {
        setModule(<Widget {...moduleProps} />);
      })
      .catch((e) => {
        setModule(null);
        // eslint-disable-next-line no-console
        console.error(e);
      });
  };

  useEffect(() => {
    getDynamicModule();
  }, [setModule, modulePath, moduleProps]);

  return module;
};

export { DynamicRenderComponent };
