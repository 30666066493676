// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._-_-_-profile-frontend-src-components-gamification-list--gamification-list__tab{display:flex}._-_-_-profile-frontend-src-components-gamification-list--gamification-list__header{display:flex;align-items:center;justify-content:space-between;box-shadow:0px 2px 4px rgba(46,48,51,0.08);margin-bottom:0}._-_-_-profile-frontend-src-components-gamification-list--gamification-list__header-close{margin-right:24px}._-_-_-profile-frontend-src-components-gamification-list--gamification-list__count{margin-left:8px}._-_-_-profile-frontend-src-components-gamification-list--gamification-list__empty{margin:24px;display:block}\n", ""]);
// Exports
exports.locals = {
	"gamification-list__tab": "_-_-_-profile-frontend-src-components-gamification-list--gamification-list__tab",
	"gamificationList__tab": "_-_-_-profile-frontend-src-components-gamification-list--gamification-list__tab",
	"gamification-list__header": "_-_-_-profile-frontend-src-components-gamification-list--gamification-list__header",
	"gamificationList__header": "_-_-_-profile-frontend-src-components-gamification-list--gamification-list__header",
	"gamification-list__header-close": "_-_-_-profile-frontend-src-components-gamification-list--gamification-list__header-close",
	"gamificationList__headerClose": "_-_-_-profile-frontend-src-components-gamification-list--gamification-list__header-close",
	"gamification-list__count": "_-_-_-profile-frontend-src-components-gamification-list--gamification-list__count",
	"gamificationList__count": "_-_-_-profile-frontend-src-components-gamification-list--gamification-list__count",
	"gamification-list__empty": "_-_-_-profile-frontend-src-components-gamification-list--gamification-list__empty",
	"gamificationList__empty": "_-_-_-profile-frontend-src-components-gamification-list--gamification-list__empty"
};
module.exports = exports;
