import { AxiosResponse } from 'axios';

import { api } from 'ant/plugins/api';

import { GamificationEndpoints } from './endpoints';

export type CreateThanksParams = {
  msg: string;
  toUser: string;
  typeRid: string | number;
  coins?: number;
};

export const createThanks = <T = unknown>({
  msg,
  toUser,
  typeRid,
  coins,
}: CreateThanksParams): Promise<AxiosResponse<T>> =>
  api.post({
    url: GamificationEndpoints.thanksCreate(),
    data: { msg, toUser, typeRid, coins },
  });
