import classNames from 'classnames';
import { useStore } from 'effector-react';
import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import VoteUpSvg from 'ant/components/svg/vote-up.svg';
import { UiButton } from 'ant/components/ui/button';
import { UiInputNumber } from 'ant/components/ui/input-number';
import { UiInput } from 'ant/components/ui/input/UiInput';
import { message } from 'ant/components/ui/message';
import { UiModal } from 'ant/components/ui/modals';
import { UiSkeleton } from 'ant/components/ui/skeleton';
import { UiTooltipPlacement } from 'ant/components/ui/tooltip';
import { UiTypography } from 'ant/components/ui/typography';
import { useAbstractStorage } from 'ant/helpers/hooks/use-abstract-storage';
import { MAX_LENGTH_INPUT_300 } from 'ant/helpers/validation';
import authService from 'ant/plugins/authService';
import { createArrayMock } from 'ant/plugins/createMocks';
import { coinsDeclension, declension } from 'ant/plugins/declension';
import { getModalStepsForSingleTitle } from 'ant/plugins/utils/get-modal-steps-for-single-title';
import { GamificationItem } from 'profile-frontend/components/gamification/item/GamificationItem';
import {
  GamificationUserThanksSummaryStorage,
  getGamificationThanksStorage,
  getGamificationWalletStorage,
} from 'profile-frontend/store/gamification';
import { Gamification } from 'profile-frontend/typings/gamification';

import styles from './AddThanks.scss';

const ccn = classNames.bind(styles);

const MOCKS_COUNT = 6;
const addThanksMocks = createArrayMock(MOCKS_COUNT, (_, key) => (
  <UiSkeleton key={key} circle loading width={80} height={80} className={styles.addThanks__listThank} />
));

type Props = {
  onClose: () => void;
  userThanksStorage: GamificationUserThanksSummaryStorage;
};

const AddThanks: React.FC<Props> = (props) => {
  const { id: userProfile } = useParams<{ id: string }>();
  const { onClose, userThanksStorage } = props;
  const currentUserId = authService.getCurrentUserId() || '';
  const createThanksPending = useStore(userThanksStorage.createThanksEffect.pending);
  const [selectedThank, setSelectedThank] = useState<Gamification.ThanksBase | null>(null);
  const [messageThank, setMessageThank] = useState<string>('');
  const [coinsCount, setCoinsCount] = useState(0);

  const thanksStorage = useMemo(() => getGamificationThanksStorage(), [currentUserId]);
  const { data: thanks, loading: isThanksLoading } = useAbstractStorage(thanksStorage.storage, {
    autoFetchAndRefetch: true,
    resetStoreOnUnmount: true,
  });

  const userWalletStorage = useMemo(() => getGamificationWalletStorage({ userId: currentUserId }), [
    currentUserId,
  ]);
  const { data: userWallet } = useAbstractStorage(userWalletStorage.storage, {
    autoFetchAndRefetch: true,
    resetStoreOnUnmount: true,
  });

  useEffect(() => setSelectedThank(thanks[0] || null), [thanks]);

  const showThanks = !isThanksLoading && thanks.length > 0;
  const messageMaxInput = `${messageThank.length} из ${MAX_LENGTH_INPUT_300}`;

  const sendThanks = () => {
    if (selectedThank) {
      const createData = {
        msg: messageThank.trim(),
        toUser: userProfile,
        typeRid: selectedThank.id,
        coins: coinsCount || 0,
      };

      userThanksStorage
        .createThanksEffect(createData)
        .then(onClose)
        .catch((e) => {
          message.error(e?.response?.data?.detail?.toString() || 'Ошибка');

          thanksStorage.storage.refetchWithLastParams();
          userWalletStorage.storage.refetchWithLastParams();
        });
    }
  };

  const maxCoinsCount = parseInt(userWallet?.balance || '', 10) || undefined;
  const maxCoinsCountLabel = maxCoinsCount
    ? `У вас ${maxCoinsCount} ${declension(maxCoinsCount, coinsDeclension)}`
    : 'У вас нет койнов';

  const onChangeCoinsHandler = (value: number) => {
    if (!maxCoinsCount || Number.isNaN(value) || value <= 0) {
      setCoinsCount(0);
    } else {
      setCoinsCount(value > maxCoinsCount ? maxCoinsCount : value);
    }
  };

  return (
    <>
      <UiModal.Header className={styles.addThanks__header}>
        <UiModal.Header.Title
          steps={getModalStepsForSingleTitle(selectedThank?.attributes?.name || 'Поблагодарить')}
        />
      </UiModal.Header>

      <UiModal.Content className={styles.addThanks__content}>
        <span className={styles.addThanks__list}>
          {isThanksLoading && addThanksMocks}

          {showThanks &&
            thanks.slice(0, 6).map((thank) => (
              <GamificationItem
                size={80}
                count={null}
                key={thank.id}
                name={thank.attributes.name}
                image={thank.attributes.image}
                icon={VoteUpSvg}
                tooltipPlacement={UiTooltipPlacement.Bottom}
                onGamificationClick={() => !createThanksPending && setSelectedThank(thank)}
                className={ccn(styles.addThanks__listThank, {
                  [styles.addThanks__selected]: thank.id === selectedThank?.id,
                })}
              />
            ))}
        </span>

        <span className={styles.addThanks__wrapper}>
          <UiTypography.Text type="secondary">Сообщение</UiTypography.Text>
          <UiInput.TextArea
            value={messageThank}
            maxLength={MAX_LENGTH_INPUT_300}
            disabled={createThanksPending}
            onChange={(e) => setMessageThank(e.target.value)}
            className={classNames(styles.addThanks__input, styles.addThanks__wrapperTextArea)}
          />
          <UiTypography.Text className={styles.addThanks__inputLabel}>{messageMaxInput}</UiTypography.Text>

          <span className={styles.addThanks__coins}>
            <UiTypography.Text type="secondary">Перевести койны</UiTypography.Text>
            <UiInputNumber
              value={coinsCount}
              max={maxCoinsCount}
              onChange={onChangeCoinsHandler}
              disabled={!maxCoinsCount || createThanksPending}
              className={classNames(styles.addThanks__input, styles.addThanks__coinsInput)}
            />
            <UiTypography.Text className={styles.addThanks__inputLabel}>
              {maxCoinsCountLabel}
            </UiTypography.Text>
          </span>
        </span>
      </UiModal.Content>

      <UiModal.Footer className={styles.addThanks__footer}>
        <UiButton
          type="primary"
          label="Поблагодарить"
          onClick={sendThanks}
          disabled={createThanksPending}
          loading={createThanksPending}
        />
        <UiButton label="Отмена" className={styles.addThanks__footerBtn} onClick={onClose} />
      </UiModal.Footer>
    </>
  );
};

export default AddThanks;
