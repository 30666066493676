import React from 'react';
import { useDrag } from 'react-dnd';

import { Widget } from '@di/core-components';
import { UiImage } from 'ant/components/ui/image';
import { UiTypography } from 'ant/components/ui/typography';

import styles from '../WidgetsGallery.scss';

type Props = {
  size?: {
    width: number;
    height: number;
  };
  widget: Widget;
};

const borderImageStyles: React.CSSProperties = { borderRadius: 0 };

const WidgetsGallerySlide: React.FC<Props> = ({ widget, size }) => {
  const { id, name, preview, types } = widget;
  const [{ isDragging }, drag] = useDrag({
    item: { ...widget, type: types[0] },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0.4 : 1;

  return (
    <div ref={drag} style={{ opacity }} className={styles.widgetsGallery__slideWrapper}>
      <UiImage
        src={preview}
        alt={name}
        width={size?.width}
        height={size?.height}
        placeholderSize={150}
        style={borderImageStyles}
        wrapperStyle={borderImageStyles}
        className={styles.widgetsGallery__slideImage}
        wrapperClassName={styles.widgetsGallery__slideImage}
      />
      <UiTypography.Text className={styles.widgetsGallery__slideWrapperInfo}>
        {`id: ${id}`}
        <br />
        {`name: ${name}`}
        <br />
        {`type: ${types[0]}`}
      </UiTypography.Text>
    </div>
  );
};

export { WidgetsGallerySlide };
