import classNames from 'classnames';
import React, { useMemo } from 'react';
import { useDrop, DragObjectWithType } from 'react-dnd';

import { Widget, LayoutItem } from '../../types';
import Iframe, { WidgetsIframeProps } from './iframe/WidgetsIframe';
import WidgetsOverlay, { WidgetsOverlayProps } from './overlay/WidgetsOverlay';

interface WidgetModuleProps<ModuleProps> {
  modulePath: string;
  moduleProps?: ModuleProps;
  moduleEnvironment?: Record<string, string>;
}

interface LayoutsWidgetsProps<ModuleProps> extends WidgetsIframeProps, WidgetsOverlayProps {
  isLayoutsEdit?: boolean;
  moduleProps?: ModuleProps;
  onDropHandler: (widgetItem: Widget, layoutItem: LayoutItem) => void;
}

export type DynamicRenderComponentType<ModuleProps = unknown> = React.FC<WidgetModuleProps<ModuleProps>>;

export interface CreateWidgetComponentParams<ModuleProps = unknown> {
  DynamicRenderComponent?: DynamicRenderComponentType<ModuleProps> | undefined;
  moduleEnvironment?: Record<string, string>;
}

type CollectedDropProps = {
  isOver: boolean;
  canDrop: boolean;
};

type DragLayoutItem = DragObjectWithType & Widget;

function createWidgetComponent<ModuleProps = unknown>(
  createWidgetComponentParams: CreateWidgetComponentParams<ModuleProps> = {},
): React.ComponentType<LayoutsWidgetsProps<ModuleProps>> {
  const { DynamicRenderComponent, moduleEnvironment } = createWidgetComponentParams;

  return (props) => {
    const {
      isLayoutsEdit,
      children = null,
      className,
      style,
      layoutItem,
      widget,
      moduleProps,
      onDeactivateWidgetClick,
      isShowDeactivate,
      isDefaultWidget,
      onDropHandler,
    } = props;
    const { url, isIframe } = widget || {};
    const isIframeView = typeof url === 'string' && isIframe;
    const isModuleView = typeof url === 'string' && !isIframe;
    const isDefaultPlugView = isIframeView && isModuleView;

    const onDrop = ({ type, ...widgetItem }: DragLayoutItem) => {
      if (onDropHandler) {
        onDropHandler(widgetItem, layoutItem);
      }
    };

    const [{ canDrop, isOver }, drop] = useDrop<DragLayoutItem, void, CollectedDropProps>({
      accept: isShowDeactivate || !widget ? layoutItem.types : '',
      drop: onDrop,
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
    });

    const styles = useMemo(
      () =>
        isLayoutsEdit && !widget
          ? {
              height: layoutItem.height,
              ...style,
            }
          : style,
      [widget, layoutItem, style, isLayoutsEdit],
    );

    const classNamesStyles = useMemo(
      () => classNames('layouts-widgets', { 'layouts-widgets_setting-ready': isLayoutsEdit }, className),
      [isLayoutsEdit, className],
    );

    return (
      <div ref={drop} style={styles} className={classNamesStyles}>
        {isIframeView && <Iframe {...props} moduleEnvironment={moduleEnvironment} />}
        {isModuleView && typeof DynamicRenderComponent === 'function' && (
          <DynamicRenderComponent
            modulePath={url as string}
            moduleProps={moduleProps}
            moduleEnvironment={moduleEnvironment}
          />
        )}
        {isDefaultPlugView && (
          <div className="layouts-widgets__empty-content-container">
            <span>Widget no set</span>
          </div>
        )}
        {children}
        <WidgetsOverlay
          {...props}
          className={classNames('layouts-widgets__overlay', {
            'layouts-widgets__overlay-drop': canDrop && isOver,
            'layouts-widgets__overlay-default-widget': isDefaultWidget,
            'layouts-widgets__overlay-available-drop': canDrop,
            // 'widgets-overlay__buttons-disabled': !isIframeView, // TODO Вспомнить почему мы отключаем только isIframeView
          })}
          onDeactivateWidgetClick={onDeactivateWidgetClick}
          isShowDeactivate={isShowDeactivate}
        />
      </div>
    );
  };
}

export { createWidgetComponent };
