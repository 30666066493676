import { format, isSameDay, isSameMinute, isDate } from 'date-fns';

const getValidDate = (date: Date | string) => {
  if (isDate(date)) {
    return date as Date;
  }

  return new Date(date);
};

export const getFormattedDate = (date: string | Date) => format(getValidDate(date), 'dd.MM.yyyy');
export const getFormattedDateTime = (date: string | Date) => format(getValidDate(date), 'HH:mm dd.MM.yyyy');
export const getFormattedTime = (date: string | Date) => format(getValidDate(date), 'HH:mm');

export const getFormattedDateRange = (since: string | Date, till: string | Date) =>
  isSameDay(getValidDate(since), getValidDate(till))
    ? getFormattedDate(since)
    : `${getFormattedDate(since)} – ${getFormattedDate(till)}`;

export const getFormattedTimeRange = (since: string | Date, till: string | Date) =>
  isSameMinute(getValidDate(since), getValidDate(till))
    ? getFormattedTime(since)
    : `${getFormattedTime(since)} – ${getFormattedTime(till)}`;

export const getFormatDateTimeForPreview = (since: string | Date, till: string | Date, delimiter = ', ') =>
  `${getFormattedDateRange(since, till)}${delimiter}${getFormattedTimeRange(since, till)}`;

export const getFormattedTimeWithOptionalDate = (date: string | Date, current: string | Date) =>
  isSameDay(getValidDate(date), getValidDate(current))
    ? getFormattedTime(date)
    : `${getFormattedTime(date)} (${format(getValidDate(date), 'dd.MM')})`;

export const getFormattedRangeWithOptionalDate = (
  since: string | Date,
  till: string | Date,
  current: string | Date = since,
) =>
  `${getFormattedTimeWithOptionalDate(since, current)} – ${getFormattedTimeWithOptionalDate(till, current)}`;
