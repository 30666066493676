import classNames from 'classnames';
import React, { useState } from 'react';

import { UiTruncateMarkup } from 'ant/components/ui/truncate-markup';
import { AppLink } from 'ant/components/widgets/AppLink';
import { capitalizeFirstLetter } from 'ant/plugins/utils/capitalize-first-letter';

import styles from './SocialLink.scss';

type Props = {
  name: string;
  value: string;
  iconUrl?: string;
  prefixUrl?: string;
};

const createSocialLink = (prefixUrl: string, userName: string): string => `https://${prefixUrl}${userName}`;

const SocialLink: React.FC<Props> = (props) => {
  const [isIconUploadError, setIsIconUploadError] = useState(false);
  const { name, value, prefixUrl, iconUrl } = props;

  const onError = () => setIsIconUploadError(true);
  const to = prefixUrl ? createSocialLink(prefixUrl, value) : value;
  const hasIcon = iconUrl !== undefined;

  return (
    <AppLink to={to} target="_blank">
      {hasIcon && !isIconUploadError ? (
        <img alt="" width={32} height={32} src={iconUrl} className={styles.socialLink} onError={onError} />
      ) : (
        <div className={classNames(styles.socialLink, styles.socialLink_button)}>
          <UiTruncateMarkup truncate>{capitalizeFirstLetter(name)}</UiTruncateMarkup>
        </div>
      )}
    </AppLink>
  );
};

export default SocialLink;
