import React from 'react';
import ReactDOM from 'react-dom';

import { UiBreadcrumb, UiBreadcrumbProps } from 'ant/components/ui/breadcrumb/UiBreadcrumb';

const PortaledBreadcrumb: React.FC<UiBreadcrumbProps> = (props) => {
  const breadcrumbContent = <UiBreadcrumb {...props} />;
  const portalElement = document.getElementById('breadcrumb-container');

  if (portalElement) {
    return ReactDOM.createPortal(breadcrumbContent, portalElement);
  }

  return null;
};

export { PortaledBreadcrumb };
