const getGamificationUrl = (url: string) => `/api/gamification/${url}`;

class GamificationEndpoints {
  static records = () => '/dicts/thanks/records/';

  static userWallet = (id: string) => getGamificationUrl(`${id}/wallet/`);

  static userBadges = (id: string) => getGamificationUrl(`${id}/badges/`);

  static userBadgesSummary = (id: string) => getGamificationUrl(`${id}/badges/summary/`);

  static thanks = () => getGamificationUrl(`thanks/`);

  static thanksCreate = () => getGamificationUrl(`thanks/create/`);

  static userThanks = (id: string) => getGamificationUrl(`${id}/thanks/received/`);

  static userThanksSummary = (id: string) => getGamificationUrl(`${id}/thanks/received/summary/`);

  static transactionHistory = () => getGamificationUrl(`transaction/history/`);

  static gamificationThanks = (id: string | number) => getGamificationUrl(`thanks/${id}/`);
}

export { GamificationEndpoints };
