import humps from 'humps';
import { stringify, StringifiableRecord } from 'query-string';

export type EndpointQueryParamsBaseType = StringifiableRecord;

export const buildEndpointWithQueryParams = (endpoint: string, params?: EndpointQueryParamsBaseType) => {
  const decamelizedParams = params ? (humps.decamelizeKeys(params as object) as StringifiableRecord) : {};
  const query = stringify(decamelizedParams);

  return query ? `${endpoint}?${query}` : endpoint;
};
