import React from 'react';
import { Tooltip as AntTooltip } from 'antd';
import { TooltipPropsWithTitle } from 'antd/es/tooltip';

export type UiTooltipPlacementProps = 'top' | 'left' | 'right' | 'bottom';

export enum UiTooltipPlacement {
  Left = 'left',
  Right = 'right',
  Bottom = 'bottom',
  Top = 'top',
}

const getAlignTargetOffset = (placement: UiTooltipPlacementProps = UiTooltipPlacement.Top) => {
  const shift = 2;
  const mapTargetOffset: Record<UiTooltipPlacement, [number, number]> = {
    [UiTooltipPlacement.Left]: [-shift, 0],
    [UiTooltipPlacement.Right]: [shift, 0],
    [UiTooltipPlacement.Top]: [0, -shift],
    [UiTooltipPlacement.Bottom]: [0, shift],
  };

  return mapTargetOffset[placement];
};

export interface UiTooltipProps extends TooltipPropsWithTitle {
  placement?: UiTooltipPlacementProps;
}

const UiTooltip: React.FC<UiTooltipProps> = (props) => {
  const { placement } = props;
  const align = { targetOffset: getAlignTargetOffset(placement) };

  return <AntTooltip align={align} mouseEnterDelay={0.3} {...props} />;
};

export { UiTooltip };
