import React, { useMemo } from 'react';

import { UiIcon } from 'ant/components/ui/icon/UiIcon';
import { UiModal } from 'ant/components/ui/modals';
import { UiTypography } from 'ant/components/ui/typography';
import { useAbstractStorage } from 'ant/helpers/hooks/use-abstract-storage';
import { createArrayMock } from 'ant/plugins/createMocks';
import { getModalStepsForSingleTitle } from 'ant/plugins/utils/get-modal-steps-for-single-title';
import CoinSvg from 'profile-frontend/components/svg/coin.svg';
import { getGamificationTransactionHistory } from 'profile-frontend/store/gamification';

import styles from './ProfileTransactionsHistory.scss';
import Transaction, { TransactionMock } from './transaction/Transaction';

const SHOW_GET_COINS_LINK = false; // TODO: Раздел в разработке

type Props = {
  coinsBalance?: number;
  onClose: () => void;
};

const MOCKS_COUNT = 5;
const transactionsMocks = createArrayMock(MOCKS_COUNT, (_, id) => <TransactionMock key={id} />);

const ProfileTransactionsHistory: React.FC<Props> = ({ coinsBalance = 0, onClose }) => {
  const transactionHistoryStorage = useMemo(() => getGamificationTransactionHistory(), []);
  const { data: transactions, loading } = useAbstractStorage(transactionHistoryStorage.storage, {
    autoFetchAndRefetch: true,
    resetStoreOnUnmount: true,
  });

  const isTransactionsEmpty = !loading && transactions.length === 0;

  return (
    <>
      <UiModal.Header className={styles.transactionHistory__header}>
        <UiModal.Header.Title steps={getModalStepsForSingleTitle('История начислений')} />
        <span className={styles.transactionHistory__coins}>
          <UiIcon width={20} height={20} component={CoinSvg} />
          <UiTypography.Text type="secondary" className={styles.transactionHistory__coinsCount}>
            {coinsBalance}
          </UiTypography.Text>
        </span>
      </UiModal.Header>

      <UiModal.Content className={styles.transactionHistory__content}>
        <div>
          {loading
            ? transactionsMocks
            : transactions.map((transaction) => {
                return <Transaction key={transaction.id} transaction={transaction} onClose={onClose} />;
              })}
        </div>

        {isTransactionsEmpty && (
          <div className={styles.transactionHistory__listEmpty}>
            <UiTypography.Text type="secondary">У вас ещё нет истории начислений.</UiTypography.Text>
            &nbsp;
            {SHOW_GET_COINS_LINK && <UiTypography.Link strong>Как получить койны</UiTypography.Link>}
          </div>
        )}
      </UiModal.Content>
    </>
  );
};

export default ProfileTransactionsHistory;
