import React from 'react';
import { Menu as AntMenu } from 'antd';
import { MenuProps } from 'antd/es/menu';
import { MenuInfo, MenuClickEventHandler } from 'rc-menu/lib/interface';

export type UiMenuInfo = MenuInfo;
export type UiMenuClickEventHandler = MenuClickEventHandler;

export const UiMenu = (props: MenuProps) => {
  return <AntMenu {...props} />;
};

UiMenu.Divider = AntMenu.Divider;
UiMenu.Item = AntMenu.Item;
UiMenu.SubMenu = AntMenu.SubMenu;
UiMenu.ItemGroup = AntMenu.ItemGroup;
