import React from 'react';
import classNames from 'classnames';
import { Tabs as AntTabs } from 'antd';
import { TabsProps, TabPaneProps } from 'antd/es/tabs';

import styles from './UiTabs.scss';

const ccn = classNames.bind(styles);

type UiTabComponent = React.FC<TabPaneProps>;

interface UiTabsComposition {
  Tab: UiTabComponent;
}

type UiTabsComponent = React.FC<TabsProps> & UiTabsComposition;

const UiTab: UiTabComponent = (props) => <AntTabs.TabPane {...props} />;

const UiTabs: UiTabsComponent = (props) => {
  const { className, ...restProps } = props;

  return <AntTabs className={ccn(styles.tabs, className)} {...restProps} />;
};

UiTabs.Tab = UiTab;

export { UiTabs, UiTab };
