export type ListMeta = {
  objectsCount: number;
  objectsTotal: number;
  pageNumber: number;
  pagesCount: number;
};

export type DictPaginated<T> = {
  items: T[];
  meta: ListMeta;
};

export type DictPaginatedObjects<T> = {
  objects: T[];
  meta: ListMeta;
};

export enum DictMatchTypes {
  Exact = '=',
  Contains = 'contains', // с учетом регистра
  Icontains = 'icontains', // по не точному совпадению
}

export type PaginationParams = {
  pageNumber: number;
  pageSize: number;
};

export interface DictExactMatchValue {
  value: string;
  type: DictMatchTypes;
}

export type DictNameDataParams = { name: string };
export type DictDataParams = Record<string, DictExactMatchValue>;

export type SinceTillFetchParams = {
  since: string;
  till: string;
};
