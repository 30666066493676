import classNames from 'classnames';
import React from 'react';

import { UiSkeletonProps } from 'ant/components/ui/skeleton';
import { UserProfile } from 'ant/types/models/user';
import { Gamification } from 'profile-frontend/typings/gamification';

import styles from './ListItem.scss';
import ItemHeader from './header/ItemHeader';

interface Props {
  createdAt: string;
  onClose: () => void;
  headerUser: UserProfile;
  headerToUser?: UserProfile;
  children: React.ReactNode;
  type: Gamification.ItemTypes;
}

const ListItem: React.FC<Props & UiSkeletonProps> = (props) => {
  const { children, type, createdAt, headerUser, headerToUser, onClose } = props;

  const isItemThanks = type === Gamification.ItemTypes.Thanks;

  return (
    <div className={styles.listItem}>
      <ItemHeader
        type={type}
        onClose={onClose}
        headerUser={headerUser}
        createdAt={createdAt}
        toUser={headerToUser}
      />

      <span className={styles.listItem__body}>
        <span
          className={classNames(styles.listItem__bodyPattern, {
            [styles.listItem__bodyWrapper]: isItemThanks,
          })}
        >
          {children}
        </span>
      </span>
    </div>
  );
};

export default ListItem;
