import classNames from 'classnames';
import React, { useRef, RefObject } from 'react';
import { Scrollbars, ScrollbarProps } from 'react-custom-scrollbars';

import styles from './UiScrollbars.scss';

export interface UiScrollbarsProps extends ScrollbarProps {
  thumbClassName?: string;
  trackClassName?: string;
  viewClassName?: string;
  contentClassName?: string;
  contentId?: string;
  updateOnResize?: boolean;
  containerRef?: RefObject<HTMLDivElement>;
  scrollBarsRef?: RefObject<Scrollbars>;
  size?: number;
}

export const ScrollbarContext = React.createContext<
  React.RefObject<Scrollbars> & { container: React.RefObject<HTMLDivElement> }
>({ current: null, container: { current: null } });

export const UiScrollbars: React.FC<UiScrollbarsProps> = (props) => {
  const {
    children,
    thumbClassName,
    trackClassName,
    viewClassName,
    contentClassName,
    contentId,
    scrollBarsRef,
    updateOnResize = true,
    containerRef: contRef,
    size,
    ...other
  } = props;
  const scrollbars = scrollBarsRef ? (scrollBarsRef as RefObject<Scrollbars>) : useRef<Scrollbars>(null);
  /*
  const onResize = useCallback(() => {
    if (scrollbars.current && updateOnResize) {
      scrollbars.current.scrollTop(scrollbars.current.getScrollTop());
    }
  }, [scrollbars]);

   */
  // const { ref: containerRef } = useResizeDetector({ onResize, ...(contRef && { targetRef: contRef }) });
  const containerRef = contRef ? (contRef as RefObject<HTMLDivElement>) : useRef<HTMLDivElement>(null);

  return (
    <Scrollbars
      renderTrackHorizontal={({ style, ...trackProps }) => (
        <div
          {...trackProps}
          style={{ ...style, height: `${size}px` }}
          className={classNames(styles.uiScrollbars__horisontalTrack, trackClassName)}
        />
      )}
      renderTrackVertical={({ style, ...trackProps }) => (
        <div
          {...trackProps}
          style={{ ...style, width: `${size}px` }}
          className={classNames(styles.uiScrollbars__verticalTrack, trackClassName)}
        />
      )}
      renderThumbHorizontal={(thumbProps) => (
        <div {...thumbProps} className={classNames(styles.uiScrollbars__horisontalThumb, thumbClassName)} />
      )}
      renderThumbVertical={(thumbProps) => (
        <div {...thumbProps} className={classNames(styles.uiScrollbars__verticalThumb, thumbClassName)} />
      )}
      renderView={(viewProps) => <div {...viewProps} className={viewClassName} />}
      ref={scrollbars}
      {...other}
    >
      <div className={contentClassName} id={contentId} ref={containerRef}>
        <ScrollbarContext.Provider value={{ ...scrollbars, container: containerRef }}>
          {children}
        </ScrollbarContext.Provider>
      </div>
    </Scrollbars>
  );
};
