import { UserProfile } from 'ant/types/models/user';

export namespace Gamification {
  export enum ItemTypes {
    Thanks = 'thanks',
    Badges = 'badges',
  }

  export type BadgesSummary = {
    name: string;
    image: string;
    badgeCount: number;
    maxBadgeNum: number;
  };

  export type CompletedActions = {
    description: string | null;
    event: string;
  };

  export type BadgeInfo = {
    id: number;
    name: string;
    coins: number;
    image: string;
    description: string;
    quantity: number;
    maxMadgeNum: number;
  };

  export type Badges = {
    awardedAt: string;
    badgeCount: number;
    badge: BadgeInfo;
    completedActions: CompletedActions[];
    id: number;
    user: UserProfile;
  };

  export type Wallet = {
    id: number;
    balance: string;
    createdAt: string;
    updatedAt: string;
    user: UserProfile;
  };

  export interface ThanksBase {
    id: string;
    attributes: ThanksSummary;
  }

  export interface Thanks extends ThanksBase {
    msg: string;
    coins: number;
    createdAt: string;
    fromUser: UserProfile;
  }

  export type ThanksSummary = {
    name: string;
    image: string;
    count: number;
  };

  export interface AddedThank extends Thanks {
    toUser: UserProfile;
  }

  export enum TransactionType {
    Transfer = 'transfer',
    BadgeCompletion = 'badge_completion',
  }

  export type TransactionHistory = {
    amount: number;
    createdAt: string;
    id: number;
    recipient: UserProfile;
    sender: UserProfile;
    text: string;
    type: TransactionType;
  };
}
