import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import humps from 'humps';
import * as t from 'io-ts';
import isPlainObject from 'lodash/isPlainObject';

import authService from 'ant/plugins/authService';

import { ioTypescriptCheck } from './ioTypescriptCheck';

interface SendParams<ResponseType = unknown> extends AxiosRequestConfig {
  headersQuery?: Record<string, string>;
  IOType?: t.Type<ResponseType>;
}

type AdditionalParams = {
  [key in string | number]: string | number;
};

const isDevelopment = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';

// eslint-disable-next-line @typescript-eslint/class-name-casing
export class api {
  private static additionalParams = {};
  public static setAdditionalParams(params: AdditionalParams) {
    api.additionalParams = { ...api.additionalParams, ...params };
  }

  public static get baseUrl() {
    if (isDevelopment) {
      return '/api-proxy';
    }

    return process.env.REMOTE_URL || '';
  }

  public static async send<ResponseType>(params: SendParams<ResponseType>) {
    const { headersQuery, ...otherAxiosConfigProps } = params;

    // TODO выпилить Io-TS с концами
    delete otherAxiosConfigProps.IOType;

    const auth = authService.getToken();
    const authType = authService.getAuthType();
    const baseURL = api.baseUrl;
    const { additionalParams } = api;

    if (otherAxiosConfigProps.data && !(otherAxiosConfigProps.data instanceof window.FormData)) {
      otherAxiosConfigProps.data = humps.decamelizeKeys(params.data);
    }

    if (otherAxiosConfigProps.params) {
      otherAxiosConfigProps.params = humps.decamelizeKeys(params.params);
    }

    otherAxiosConfigProps.params = {
      ...otherAxiosConfigProps.params,
      ...additionalParams,
    };

    try {
      const response = await axios.request<ResponseType>({
        ...(baseURL && { baseURL }),
        ...otherAxiosConfigProps,
        headers: {
          accept: 'application/json',
          ...(auth && { Authorization: `${authType} ${auth}` }),
          ...headersQuery,
        },
      });

      if (params.IOType && !ioTypescriptCheck<ResponseType>(params.IOType, response.data))
        throw new Error(`Path request: ${baseURL || ''}${params.url}`);

      return response;
    } catch (e) {
      if (!axios.isCancel(e)) {
        // eslint-disable-next-line no-console
        console.log(e);
      }

      return Promise.reject(e);
    }
  }

  static async sendCamelizingData(params: SendParams) {
    return api.transformDataFromSnakeToCamelCase(api.send({ ...params }));
  }

  static async transformDataFromSnakeToCamelCase(responsePromise: Promise<AxiosResponse>) {
    const response = await responsePromise;
    const { data } = response;

    if (isPlainObject(data) || Array.isArray(data)) {
      return {
        ...response,
        data: humps.camelizeKeys(data),
      };
    }

    return responsePromise;
  }

  static async get<ResponseType>(params: SendParams): Promise<AxiosResponse<ResponseType>> {
    return api.sendCamelizingData({
      ...params,
      method: 'get',
    });
  }

  static async delete<ResponseType>(params: SendParams) {
    return api.sendCamelizingData({
      ...params,
      method: 'delete',
    }) as Promise<AxiosResponse<ResponseType>>;
  }

  static async post<ResponseType>(params: SendParams) {
    return api.sendCamelizingData({
      ...params,
      method: 'post',
    }) as Promise<AxiosResponse<ResponseType>>;
  }

  static async put<ResponseType>(params: SendParams) {
    return api.sendCamelizingData({
      ...params,
      method: 'put',
    }) as Promise<AxiosResponse<ResponseType>>;
  }

  static async patch<ResponseType>(params: SendParams) {
    return api.sendCamelizingData({
      ...params,
      method: 'patch',
    }) as Promise<AxiosResponse<ResponseType>>;
  }
}
