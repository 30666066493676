import classNames from 'classnames';
import { useStore } from 'effector-react';
import React, { useMemo, useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import UserAddSvg from 'ant/components/svg/user-add.svg';
import UserCheckedSvg from 'ant/components/svg/user-checked.svg';
import { UiButton } from 'ant/components/ui/button';
import { UiIcon } from 'ant/components/ui/icon/UiIcon';
import { message } from 'ant/components/ui/message';
import { UiModal, UiModalTypes } from 'ant/components/ui/modals';
import { UiSkeleton } from 'ant/components/ui/skeleton';
import { useAbstractStorage } from 'ant/helpers/hooks/use-abstract-storage';
import authService from 'ant/plugins/authService';
import { createArrayMock } from 'ant/plugins/createMocks';
import { coinsDeclension, declension } from 'ant/plugins/declension';
import { getRoutePath, RouteNames } from 'ant/plugins/routes';
import { closeGlobalModal, openGlobalModal } from 'ant/store/global-modals';
import { GlobalModalNames } from 'ant/store/global-modals/modal-types';
import AddThanks from 'profile-frontend/components/gamification/add-thanks/AddThanks';
import ProfileAvatar from 'profile-frontend/components/profile/avatar/ProfileAvatar';
import ProfileInfo from 'profile-frontend/components/profile/info/ProfileInfo';
import { ProjectsProps } from 'profile-frontend/components/profile/info/references/InfoReferencesProjects@default';
import CoinSvg from 'profile-frontend/components/svg/coin.svg';
import { profileFullInfoStorage } from 'profile-frontend/storage';
import {
  getGamificationWalletStorage,
  getGamificationUserThanksSummaryStorage,
} from 'profile-frontend/store/gamification';
import { getProfileUserFollowingsIdsStorage } from 'profile-frontend/store/profile';

import styles from './Profile.scss';
import ProfileTransactionsHistory from './transactions-history/ProfileTransactionsHistory';

const REFERENCES_MOCK_NUMBER = 4;
const ABOUT_MOCK_NUMBER = 7;
const PROFILE_INFO_MOCK = (
  <>
    <div className={styles.profileUser__skeletonWrapper}>
      <UiSkeleton width={260} height={32} loading />
      <UiSkeleton width={220} height={20} loading />
    </div>
    <div className={styles.profileUser__skeletonWrapper}>
      {createArrayMock(REFERENCES_MOCK_NUMBER, (_, key) => (
        <UiSkeleton key={key} loading width={200} height={20} />
      ))}
    </div>
    <div className={styles.profileUser__skeletonWrapper}>
      <UiSkeleton loading width={90} height={20} />
    </div>
    <div className={styles.profileUser__skeletonWrapper}>
      {createArrayMock(ABOUT_MOCK_NUMBER, (_, key) => (
        <UiSkeleton key={key} loading width={460} height={20} />
      ))}
    </div>
  </>
);

const {
  storage: profileUserFollowingsIdsStorage,
  followUserEffect,
  unFollowUserEffect,
} = getProfileUserFollowingsIdsStorage();

type ProfileProps = {
  InfoReferencesProjects?: React.FC<ProjectsProps>;
  avatarUploadBtnClassName?: string;
};

const Profile = (props?: ProfileProps) => {
  const ownerId = authService.getCurrentUserId();
  const { id } = useParams<{ id: string }>();
  const history = useHistory();

  const userThanksStorage = useMemo(() => getGamificationUserThanksSummaryStorage({ userId: id }), [id]);

  const { data: profileInfoData, loading: profileFullInfoLoading } = useAbstractStorage(
    profileFullInfoStorage.storage,
    {
      autoFetchAndRefetch: true,
      autoFetchParams: {
        userId: id,
      },
    },
  );

  const isFollowUserEffectPending = useStore(followUserEffect.pending);
  const isUnfollowUserEffectPending = useStore(unFollowUserEffect.pending);

  const { data: profileUserFollowingsIdsData, loading: profileUserFollowingsLoading } = useAbstractStorage(
    profileUserFollowingsIdsStorage,
    {
      autoFetchAndRefetch: true,
      autoFetchParams: {
        userId: String(ownerId),
      },
    },
  );

  const isSubscribeButtonLoading =
    isFollowUserEffectPending || isUnfollowUserEffectPending || profileUserFollowingsLoading;

  // TODO B2BCORE-1044 бэк. Блоги. Сделать ручку для проверки подписания профиля
  const isFollower = useMemo(() => {
    for (let i = 0; i < profileUserFollowingsIdsData.length; i++) {
      if (profileUserFollowingsIdsData[i] === id) {
        return true;
      }
    }

    return false;
  }, [profileUserFollowingsIdsData, id]);

  const [isAddThankOpen, setIsAddThankOpen] = useState(false);
  const [showCoinsHistory, setShowCoinsHistory] = useState(false);
  const isOwner = id === ownerId;

  const toggleAddThankOpen = () => setIsAddThankOpen((value) => !value);
  const toggleCoinsHistory = () => setShowCoinsHistory((value) => !value);

  const onEditClick = () => {
    history.push(getRoutePath(RouteNames.ProfileEdit, { id }));
  };

  useEffect(() => {
    if (isAddThankOpen) {
      const unsubscribe = userThanksStorage.createThanksEffect.doneData.watch((thank) => {
        openGlobalModal(GlobalModalNames.ProfileAddThanksSuccess, {
          onClose: () => closeGlobalModal(GlobalModalNames.ProfileAddThanksSuccess),
          thank,
        });

        unsubscribe();
      });
    }
  }, [isAddThankOpen]);

  const onSubscribeClick = () => {
    const subscribeHandler = isFollower ? unFollowUserEffect : followUserEffect;

    subscribeHandler({ userId: id }).catch(() => {
      message.error('Произошла ошибка');
    });
  };
  const userWalletStorage = useMemo(() => getGamificationWalletStorage({ userId: id }), [id]);
  const { data: userWallet } = useAbstractStorage(userWalletStorage.storage, {
    autoFetchAndRefetch: isOwner,
    resetStoreOnUnmount: true,
  });
  const coinsBalance = userWallet?.balance ? parseInt(userWallet.balance, 10) : 0;
  const coinsButtonText = `${coinsBalance} ${declension(coinsBalance, coinsDeclension)}`;

  const ownerActionButtons = (
    <>
      <UiButton
        type="secondary"
        onClick={onEditClick}
        label="Редактировать"
        className={styles.profileUser__actionsBtn}
      />
      <UiButton
        type="tertiary"
        label={coinsButtonText}
        onClick={toggleCoinsHistory}
        className={classNames(styles.profileUser__actionsBtn, styles.profileUser__actionsBtn_coins)}
        icon={<UiIcon component={CoinSvg} width={20} height={20} className={styles.profileUser__coinIcon} />}
      />
    </>
  );

  const subscribeBtnLabel = isFollower ? 'Вы подписаны' : 'Подписаться';
  const subscribeBtnIcon = isFollower ? UserCheckedSvg : UserAddSvg;

  const onAvatarUpdate = (avatarSrc: string) => {
    profileFullInfoStorage.updateAvatarEvent(avatarSrc);
  };

  const guestActionButtons = (
    <>
      <UiButton
        type={isFollower ? 'tertiary' : 'primary'}
        onClick={onSubscribeClick}
        label={subscribeBtnLabel}
        loading={isSubscribeButtonLoading}
        className={styles.profileUser__actionsBtn}
        icon={<UiIcon component={subscribeBtnIcon} height={20} width={20} />}
      />

      <UiButton
        type="secondary"
        onClick={toggleAddThankOpen}
        label="Поблагодарить"
        className={styles.profileUser__actionsBtn}
      />
    </>
  );

  return (
    <div className={styles.profileUser}>
      <div className={styles.profileUser__avatarContainer}>
        <ProfileAvatar
          profileMain={profileInfoData?.main}
          loading={profileFullInfoLoading}
          onAvatarUpdate={onAvatarUpdate}
          uploadBtnClassName={props?.avatarUploadBtnClassName}
        />

        {!profileFullInfoLoading && (
          <span className={styles.profileUser__actions}>
            {isOwner ? ownerActionButtons : guestActionButtons}
          </span>
        )}
      </div>
      <div className={styles.profileUser__infoContainer}>
        {!profileFullInfoLoading && profileInfoData ? (
          <ProfileInfo userData={profileInfoData} InfoReferencesProjects={props?.InfoReferencesProjects} />
        ) : (
          PROFILE_INFO_MOCK
        )}
      </div>

      <UiModal type={UiModalTypes.Medium} isOpen={showCoinsHistory} onClose={toggleCoinsHistory}>
        <ProfileTransactionsHistory coinsBalance={coinsBalance} onClose={toggleCoinsHistory} />
      </UiModal>

      <UiModal type={UiModalTypes.Medium} isOpen={isAddThankOpen} onClose={toggleAddThankOpen}>
        <AddThanks onClose={toggleAddThankOpen} userThanksStorage={userThanksStorage} />
      </UiModal>
    </div>
  );
};

export default Profile;
