import { useStore } from 'effector-react';
import React from 'react';

import { UiButton } from 'ant/components/ui/button';
import { UiDrawer } from 'ant/components/ui/drawer';
import { message } from 'ant/components/ui/message';
import { UiSpinner } from 'ant/components/ui/spinner';
import { UiTypography } from 'ant/components/ui/typography';
import authService from 'ant/plugins/authService';
import { getErrorResponseMessage } from 'ant/plugins/get-error-response-message';
import { getThemePickerStorage } from 'ant/theme/picker/store';

import styles from './ThemePicker.scss';
import { ColorPicker } from './color-picker/ColorPicker';

const themePickerStorage = getThemePickerStorage();

const ThemePicker = () => {
  const { isOpen, colors } = useStore(themePickerStorage.themePickerState);
  const onClose = () => {
    themePickerStorage.cancelEditThemePickerEvent();
  };

  const onSave = () => {
    const currentUserId = authService.getCurrentUserId() as string;

    themePickerStorage
      .saveThemePickerUserColorsEffect({ userId: currentUserId, colors })
      .then(() => {
        // onClose();
        message.success('Внешний вид изменен');
      })
      .catch((e) => message.error(getErrorResponseMessage(e, 'Ошибка сохранения темы')));
  };

  const saveThemePickerUserColorsPending = useStore(
    themePickerStorage.saveThemePickerUserColorsEffect.pending,
  );

  const Title = (
    <>
      <UiTypography.Title level={3} style={{ margin: 0 }}>
        Внешний вид
      </UiTypography.Title>

      <div className={styles.themePicker__actions}>
        <UiButton size="large" type="tertiary" label="Отменить" onClick={onClose} />
        <UiButton
          size="large"
          type="primary"
          label="Сохранить"
          onClick={onSave}
          loading={saveThemePickerUserColorsPending}
          disabled={saveThemePickerUserColorsPending}
        />
      </div>
    </>
  );

  return (
    <UiDrawer
      destroyOnClose
      visible={isOpen}
      placement="bottom"
      title={Title}
      headerStyle={{ position: 'fixed', width: '100%' }}
      height="70%"
      onClose={onClose}
      className={styles.themePicker}
    >
      <UiSpinner spinning={saveThemePickerUserColorsPending} containerStyle={{ display: 'block' }}>
        <div className={styles.themePicker__colors}>
          {colors.map((color) => (
            <ColorPicker
              key={color.name}
              color={color}
              onChange={themePickerStorage.setColorThemePickerEvent}
            />
          ))}
        </div>
      </UiSpinner>
    </UiDrawer>
  );
};

export { ThemePicker, themePickerStorage };
