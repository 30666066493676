import React, { KeyboardEventHandler, useRef } from 'react';

import { checkFileMimeType } from 'ant/plugins/utils/check-file-mime-type';
import { readFileToStringPromise } from 'ant/plugins/utils/read-file-to-string-promise';
import { FileToUploadModel } from 'ant/types/models/file';
import { FileUploadAccepts } from 'ant/types/models/file-upload-accepts';

type Props = {
  onAddFiles: (files: FileToUploadModel[]) => void;
  multiple?: boolean;
  className?: string;
  accept?: FileUploadAccepts;
};

// TODO подумать над тем, как объединить с UiUploader
const FilePicker: React.FC<Props> = ({
  accept = FileUploadAccepts.All,
  children,
  onAddFiles,
  multiple = false,
  className,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const onTriggerClick = () => inputRef.current?.click();
  const onTriggerKeyPress: KeyboardEventHandler = ({ key }) => {
    if (key === 'Enter') {
      onTriggerClick();
    }
  };

  const handleAddFiles = (files: FileList | null) => {
    if (files && files.length) {
      Promise.all(
        Array.from(files)
          .filter((file) => checkFileMimeType(accept, file.type))
          .map((file) => readFileToStringPromise(file, true)),
      ).then(onAddFiles);
    }
  };

  return (
    <div className={className}>
      <input
        type="file"
        accept={accept}
        multiple={multiple}
        ref={inputRef}
        style={{ display: 'none' }}
        onChange={(e) => {
          handleAddFiles(e.target.files);
        }}
      />
      <div tabIndex={-1} role="button" onKeyPress={onTriggerKeyPress} onClick={onTriggerClick}>
        {children}
      </div>
    </div>
  );
};

export { FilePicker };
