import { Layout } from '@di/core-components';
import { abstractStorageFactory } from 'ant/helpers/storage/abstract-storage-factory';
import { buildEndpointWithQueryParams } from 'ant/plugins/utils/endpoint-builder';

import { LayoutEndpoints } from './endpoints';

type LayoutsParams = {
  name: string;
};

export const getLayoutStorage = () => {
  const storage = abstractStorageFactory<Layout, Layout, null, LayoutsParams>({
    endpointBuilder: (params) => buildEndpointWithQueryParams(LayoutEndpoints.layouts(), params),
    defaultValue: null,
    cancelPendingRequestOnFetch: true,
  });

  return {
    storage,
  };
};
