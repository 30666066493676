import flatMap from 'lodash/flatMap';

import { getProfileStorage, getProfilePermissionsStorage } from 'ant/store/profile';

const currentProfileStorage = getProfileStorage();
const currentProfileStore = currentProfileStorage.store.map(({ data }) => data);
const currentProfileGroupIdStore = currentProfileStorage.store.map(
  ({ data }) => data?.groupPath?.split('/')?.reverse()?.[0],
);

const currentProfilePermissionsStorage = getProfilePermissionsStorage();
const currentProfilePermissionsStore = currentProfilePermissionsStorage.store.map(({ data }) => data);
const currentProfilePermissionsMapStore = currentProfilePermissionsStorage.store.map(({ data }) =>
  data ? flatMap(data, (permission) => permission.allowedActions) : [],
);

export default {
  currentProfileGroupIdStore,
  currentProfileStorage,
  currentProfileStore,
  currentProfilePermissionsStorage,
  currentProfilePermissionsStore,
  currentProfilePermissionsMapStore,
};
