import { abstractStorageFactory } from 'ant/helpers/storage/abstract-storage-factory';
import { Endpoints } from 'ant/plugins/endpoints';
import { UserProfile, UserPermissions } from 'ant/types/models/user';

type FetchProfileParams = {
  userId: string;
};

export const getProfileStorage = () =>
  abstractStorageFactory<UserProfile, UserProfile, null, FetchProfileParams>({
    endpointBuilder: ({ userId }) => Endpoints.profileMain(userId),
    defaultValue: null,
  });

export const getProfilePermissionsStorage = () =>
  abstractStorageFactory<UserPermissions, UserPermissions, null>({
    endpointBuilder: () => Endpoints.profilePermissions(),
    defaultValue: null,
  });
