import { differenceInCalendarDays, subYears } from 'date-fns';

import { Rule } from 'ant/components/ui/form';
import { retrieveTextFromHtml } from 'ant/plugins/retrieve-text-from-html';

export const MUST_BE_FILLED_MESSAGE = 'Поле должно быть заполнено';

const NO_EMPTY_RULE_MESSAGE = 'Поле не должно быть заполнено пустым тегом';

export const requiredRule: Rule = {
  required: true,
  whitespace: true,
  message: MUST_BE_FILLED_MESSAGE,
};

export const requiredDateOrTimeRule: Rule = {
  type: 'object',
  required: true,
  whitespace: true,
  message: MUST_BE_FILLED_MESSAGE,
};

export const requiredRuleSelect: Rule = {
  required: true,
  message: 'Выберите один из доступных вариантов',
};

export const requiredRuleMultiSelect: Rule = {
  required: true,
  message: 'Выберите доступные варианты',
};

export const requiredRuleWithWhitespace: Rule = {
  required: true,
  message: MUST_BE_FILLED_MESSAGE,
};

export const emailRule: Rule = { type: 'email', message: 'Укажите правильный email' };

export const noEmptyHtmlRule: Rule = {
  validator: (_, value?: string) =>
    value && value.trim().length !== 0 && (retrieveTextFromHtml(value) || '').trim().length === 0
      ? Promise.reject()
      : Promise.resolve(),
  message: NO_EMPTY_RULE_MESSAGE,
};

export const getNoEmptyHtmlRuleMessage = (message = NO_EMPTY_RULE_MESSAGE): Rule => ({
  ...noEmptyHtmlRule,
  message,
});

export const getMaxLengthRule = (maxLength: number): Rule => ({
  max: maxLength,
  message: `Убедитесь, что в этом поле не больше ${maxLength} символов.`,
});

export const MAX_BIRTHDAY_RANGE = 100;
export const MIN_BIRTHDAY_RANGE = 16;

export const dateBirthdayValidator = (date: Date) =>
  differenceInCalendarDays(subYears(new Date(), MAX_BIRTHDAY_RANGE), date) >= 0 ||
  differenceInCalendarDays(subYears(new Date(), MIN_BIRTHDAY_RANGE), date) <= 0;

export const MIN_MEETING_YEAR = 2000;
export const MAX_MEETING_YEAR = 2200;

export const dateMeetingValidator = (date: Date) =>
  date.getFullYear() < MIN_MEETING_YEAR || date.getFullYear() > MAX_MEETING_YEAR;

const URL_PROTOCOL = '^(https?:\\/\\/)';
const URL_PATTERN =
  '((([a-zа-я\\d]([a-zа-я\\d-]*[a-zа-я\\d])*)\\.)+[a-zа-я]{2,}|' + // domain name
  '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
  '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
  '(\\?[;&a-zа-я\\d%_.~+=-]*)?' + // query string
  '(\\#[-a-zа-я\\d_]*)?$';

const URL_WITH_PROTOCOL_PATTERN = new RegExp([URL_PROTOCOL, URL_PATTERN].join(''), 'i');
const URL_WITHOUT_PROTOCOL_PATTERN = new RegExp([URL_PROTOCOL, '?', URL_PATTERN].join(''), 'i');

export const validURLRule: Rule = {
  validator: (_, value?: string) =>
    value && !!URL_WITHOUT_PROTOCOL_PATTERN.test(value) ? Promise.resolve() : Promise.reject(),
  message: 'Поле должно быть заполнено как URL-адрес',
};
export const validURLWithProtocolRule: Rule = {
  validator: (_, value?: string) =>
    value && !!URL_WITH_PROTOCOL_PATTERN.test(value) ? Promise.resolve() : Promise.reject(),
  message: 'Поле должно быть заполнено как URL-адрес',
};

export const MAX_LENGTH_INPUT_50 = 50;
export const MAX_LENGTH_INPUT_150 = 150;
export const MAX_LENGTH_INPUT_200 = 200;
export const MAX_LENGTH_INPUT_255 = 255;
export const MAX_LENGTH_INPUT_300 = 300;
export const MAX_LENGTH_INPUT_1024 = 1024;
export const MAX_LENGTH_INPUT_2000 = 2000;
