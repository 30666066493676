import React, { useEffect } from 'react';
import useClipboard from 'react-use-clipboard';

import { message } from 'ant/components/ui/message';
import { UiTypography } from 'ant/components/ui/typography';
import { sendNativeAppEvent } from 'ant/plugins/native-app-bridge@myteam';

import styles from './ContactItem@myteam.scss';

type Props = {
  value: string;
  formatedValue?: string;
  isEmail?: boolean;
};

const ContactItem: React.FC<Props> = ({ value, formatedValue, isEmail }) => {
  const [isCopied, setCopied] = useClipboard(value, { successDuration: 300 });

  const onLinkClick: React.MouseEventHandler<HTMLElement> = (e) => {
    e.stopPropagation();

    if (isEmail) {
      sendNativeAppEvent('mailClick', { mail: value });
    } else {
      setCopied();
    }
  };

  useEffect(() => {
    if (isCopied) {
      message.success('Скопировано');
    }
  }, [isCopied]);

  return (
    <UiTypography.Link onClick={onLinkClick} className={styles.contactItem}>
      {formatedValue || value}
    </UiTypography.Link>
  );
};

export default ContactItem;
