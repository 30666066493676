import React, { ReactNode } from 'react';

import { UiSkeleton } from 'ant/components/ui/skeleton';
import { UiTypography } from 'ant/components/ui/typography';
import { Gender, UserProfile } from 'ant/types/models/user';

import styles from './SkillsEmpty.scss';

type Props = {
  isOwner: boolean;
  addSkill: () => void;
  userProfile?: UserProfile;
  loading: boolean;
};

const SkillsEmpty: React.FC<Props> = (props) => {
  const { isOwner, userProfile, addSkill, loading } = props;

  const userFirstName = userProfile?.fullName?.firstName;
  const isUserFemale = userProfile?.gender === Gender.Female;

  const visitorDescription = `${userFirstName} ещё не ${
    isUserFemale ? 'добавила' : 'добавил'
  } навыки. Может быть, вы знаете, в чём ${isUserFemale ? 'она сильна?' : 'он силён?'}`;

  const AddLink: React.FC<{ children: ReactNode }> = ({ children }) => (
    <UiTypography.Link strong onClick={addSkill}>
      {children}
    </UiTypography.Link>
  );

  return (
    <div className={styles.skillsEmpty}>
      {isOwner ? (
        <UiTypography.Text type="secondary">
          <AddLink>Добавьте</AddLink>
          &nbsp;навыки, чтобы ваши коллеги знали, что вы умеете
        </UiTypography.Text>
      ) : (
        <UiSkeleton loading={loading} count={3} width="100%">
          <UiTypography.Text type="secondary">
            {visitorDescription}
            <br />
            <AddLink>Добавить навык</AddLink>
          </UiTypography.Text>
        </UiSkeleton>
      )}
    </div>
  );
};

export default SkillsEmpty;
