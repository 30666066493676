// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._-_-_-profile-frontend-src-components-profile-sidebar-info-contact-item--contact-item{margin-top:4px;font-weight:400;font-size:15px;line-height:20px;color:var(--myteam-themeVariables-colorLinkBrand)}\n", ""]);
// Exports
exports.locals = {
	"contact-item": "_-_-_-profile-frontend-src-components-profile-sidebar-info-contact-item--contact-item",
	"contactItem": "_-_-_-profile-frontend-src-components-profile-sidebar-info-contact-item--contact-item"
};
module.exports = exports;
