import React from 'react';

import StatusOnWorkplaceSvg from 'ant/components/svg/status-on-workplace.svg';
import StatusOutOfWorkspaceSvg from 'ant/components/svg/status-out-of-workspace.svg';
import { UiIcon } from 'ant/components/ui/icon/UiIcon';
import { UiTypography } from 'ant/components/ui/typography';

import styles from './WorkplaceStatus.scss';

export enum WorkplaceStatuses {
  OnWorkplace = 'on-workplace',
  OutOfWorkspace = 'out-of-workspace',
}

const WorkplaceStatusIcons: Record<WorkplaceStatuses, SvgrComponent> = {
  [WorkplaceStatuses.OnWorkplace]: StatusOnWorkplaceSvg,
  [WorkplaceStatuses.OutOfWorkspace]: StatusOutOfWorkspaceSvg,
};

const WorkplaceStatusLabel: Record<WorkplaceStatuses, string> = {
  [WorkplaceStatuses.OnWorkplace]: 'В офисе',
  [WorkplaceStatuses.OutOfWorkspace]: 'Не в офисе',
};

type Props = {
  status: WorkplaceStatuses;
};

const WorkplaceStatus: React.FC<Props> = ({ status }) => {
  const Icon = WorkplaceStatusIcons[status];
  const label = WorkplaceStatusLabel[status];

  return (
    <span className={styles.workplaceStatus}>
      <UiIcon component={Icon} width={20} height={20} className={styles.workplaceStatus__icon} />
      <UiTypography.Text strong type="secondary" className={styles.workplaceStatus__status}>
        {label}
      </UiTypography.Text>
    </span>
  );
};

export default WorkplaceStatus;
