import React, { useContext } from 'react';

import { useInitialScrollTop } from 'ant/helpers/hooks/use-initial-scroll-top';
import { ScrollbarContextMyteam } from 'ant/components/ui/scrollbars/UiScrollbars@myteam';

import { DynamicRenderComponent } from '~/components/layouts/component/dynamic-render-component/DynamicRenderComponent';

export const PageStructureMyteam = () => {
  const { current } = useContext(ScrollbarContextMyteam);

  useInitialScrollTop(current);

  return <DynamicRenderComponent modulePath="StructureFrontend@myteam" />;
};
