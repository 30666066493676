const getProfileUrl = (url: string) => `/profile${url}`;

// TODO перенести это все в ../profile
class ProfileEndpoints {
  static counters = (id: string) => getProfileUrl(`/${id}/counters/`);

  static skills = (id: string) => getProfileUrl(`/${id}/skills/`);

  static skillAction = (id: string, skillId: string) => getProfileUrl(`/${id}/skills/${skillId}/`);
}

export { ProfileEndpoints };
