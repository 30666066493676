import { Modal as AntModal } from 'antd';
import classNames from 'classnames';
import React, { useMemo } from 'react';

import { UiSpinner } from '../../spinner';
import { UiModalContent, UiModalContentComponent } from './content';
import { UiModalContext, UiModalContextState } from './context';
import { ModalFooterComponent, UiModalFooter } from './footer';
import { UiModalHeader, UiModalHeaderComponent } from './header';
import { getUiModalBodyStyle } from './helpers';
import styles from './styles.scss';
import { UiModalTypes } from './typings';

const ccn = classNames.bind(styles);

export interface UiModalProps {
  className?: string;
  type: UiModalTypes;
  onClose?: () => void;
  onAfterClose?: () => void;
  isOpen?: boolean;
  isForm?: boolean;
  onFormSubmit?: (event: React.FormEvent<HTMLFormElement>) => void;
  isLoading?: boolean;
  isMaskClosable?: boolean;
}

type ModalComposition = {
  Header: UiModalHeaderComponent;
  Content: UiModalContentComponent;
  Footer: ModalFooterComponent;
};

const UiModal: React.FC<UiModalProps> & ModalComposition = (props) => {
  const {
    type,
    children,
    className,
    isOpen,
    onClose,
    onAfterClose,
    isLoading,
    isForm,
    onFormSubmit,
    isMaskClosable,
  } = props;

  const modalClassName = ccn(styles.uiModal, className, styles[`uiModal_type_${type}`]);

  const contextValue: UiModalContextState = {
    onClose,
  };

  const modalBodyStyle = useMemo(() => getUiModalBodyStyle(type), [type]);

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (onFormSubmit) {
      onFormSubmit(event);
    }
  };

  const renderContent = () => {
    if (isForm) {
      return (
        <form onSubmit={onSubmit} className={styles.uiModal__form}>
          {children}
        </form>
      );
    }

    return children;
  };

  return (
    <UiModalContext.Provider value={contextValue}>
      <AntModal
        className={modalClassName}
        width="auto"
        footer={null}
        onCancel={onClose}
        destroyOnClose
        afterClose={onAfterClose}
        visible={isOpen}
        bodyStyle={modalBodyStyle}
        closable={false}
        maskClosable={isMaskClosable}
      >
        {isLoading ? <UiSpinner /> : renderContent()}
      </AntModal>
    </UiModalContext.Provider>
  );
};

UiModal.Header = UiModalHeader;
UiModal.Content = UiModalContent;
UiModal.Footer = UiModalFooter;

export { UiModal };
