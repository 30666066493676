// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._-_-_-components-frontend-src-components-profile-timeslots-ProfileWeekTimeline--profile-week-timeline{position:absolute;display:block;left:0;bottom:0;top:0;transition:top 0.3s ease;z-index:1}._-_-_-components-frontend-src-components-profile-timeslots-ProfileWeekTimeline--profile-week-timeline__line{position:absolute;display:block;left:0;top:0;bottom:0;width:2px;background-color:var(--myteam-themeVariables-colorBrand)}\n", ""]);
// Exports
exports.locals = {
	"profile-week-timeline": "_-_-_-components-frontend-src-components-profile-timeslots-ProfileWeekTimeline--profile-week-timeline",
	"profileWeekTimeline": "_-_-_-components-frontend-src-components-profile-timeslots-ProfileWeekTimeline--profile-week-timeline",
	"profile-week-timeline__line": "_-_-_-components-frontend-src-components-profile-timeslots-ProfileWeekTimeline--profile-week-timeline__line",
	"profileWeekTimeline__line": "_-_-_-components-frontend-src-components-profile-timeslots-ProfileWeekTimeline--profile-week-timeline__line"
};
module.exports = exports;
