import React from 'react';
import { Link } from 'react-router-dom';

import { checkInnerPath } from 'ant/plugins/routes';

export interface AppLinkProps {
  to: string;
  onClick?: () => void;
  target?: '_blank' | '_self' | '_parent' | '_top';
  className?: string;
}

export const AppLink: React.FC<AppLinkProps> = (props) => {
  const { className, to, onClick, target = '_self', children } = props;
  const isTargetBlank = target === '_blank';
  const isInner = !isTargetBlank && checkInnerPath(to);

  return isInner ? (
    <Link {...props} onClick={onClick} className={className}>
      {children}
    </Link>
  ) : (
    <a href={to} target={target} className={className}>
      {children}
    </a>
  );
};
