import classNames from 'classnames';
import React from 'react';

import { UiAvatar } from 'ant/components/ui/avatar';
import { UiIcon } from 'ant/components/ui/icon';
import { UiTooltip, UiTooltipPlacement } from 'ant/components/ui/tooltip';

import styles from './GamificationItem.scss';

export type GamificationItemProps = {
  name: string;
  image: string;
  icon: SvgrComponent;
  count: string | number | null;
  onGamificationClick: () => void;
  size?: number;
  className?: string;
  tooltipPlacement?: UiTooltipPlacement;
};

const GamificationItem: React.FC<GamificationItemProps> = (props) => {
  const {
    name,
    image,
    count,
    onGamificationClick,
    size = 72,
    tooltipPlacement,
    className,
    icon: Icon,
  } = props;

  return (
    <UiTooltip placement={tooltipPlacement} title={name}>
      <button
        type="button"
        onClick={onGamificationClick}
        className={classNames(styles.gamificationItem, className)}
      >
        <UiAvatar size={size} src={image} icon={<UiIcon component={Icon} height={32} width={32} />} />
        {count && <span className={styles.gamificationItem__count}>{count}</span>}
      </button>
    </UiTooltip>
  );
};

export { GamificationItem };
