// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._-_-_-profile-frontend-src-components-contacts-workplace-status--workplace-status{display:flex}._-_-_-profile-frontend-src-components-contacts-workplace-status--workplace-status__icon{margin-right:8px}._-_-_-profile-frontend-src-components-contacts-workplace-status--workplace-status__status{font-weight:600;font-size:15px;line-height:20px;letter-spacing:0.1px}\n", ""]);
// Exports
exports.locals = {
	"workplace-status": "_-_-_-profile-frontend-src-components-contacts-workplace-status--workplace-status",
	"workplaceStatus": "_-_-_-profile-frontend-src-components-contacts-workplace-status--workplace-status",
	"workplace-status__icon": "_-_-_-profile-frontend-src-components-contacts-workplace-status--workplace-status__icon",
	"workplaceStatus__icon": "_-_-_-profile-frontend-src-components-contacts-workplace-status--workplace-status__icon",
	"workplace-status__status": "_-_-_-profile-frontend-src-components-contacts-workplace-status--workplace-status__status",
	"workplaceStatus__status": "_-_-_-profile-frontend-src-components-contacts-workplace-status--workplace-status__status"
};
module.exports = exports;
